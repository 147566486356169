import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Flex,
  Text,
  Spinner,
  Spacer,
  VStack,
  ButtonGroup,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import Link from 'app/components/Link';
import { Activity, useActivityListBySlug } from 'app/data';
import { DownloadButton } from 'app/components/DownloadButton';
import { BrowserClient, Feedback, getClient } from '@sentry/react';
import { format } from 'date-fns';

interface TemplateTableDisplayProps {
  activities: Array<Activity>;
}

const TemplateTableDisplay = ({ activities }: TemplateTableDisplayProps) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const sentryClient = getClient<BrowserClient>();
  const feedback = sentryClient?.getIntegration(Feedback);

  return (
    <VStack align="start" spacing={4} w="100%" ref={downloadRef}>
      <Flex w="100%" gap="2">
        <Text
          fontSize="4xl"
          fontWeight="bold"
          fontFamily="DM Serif Text, serif"
        >
          {activities[0].content.template.title}
        </Text>
      </Flex>
      <Flex w="100%" gap="2" align="flex-end" direction="row">
        <Text fontSize="xl" fontWeight="semibold">
          Activity Insights
        </Text>
        <Spacer />
        <Flex gap={2} direction={{ base: 'column', sm: 'row' }}>
          <ButtonGroup size="md">
            <DownloadButton
              downloadRef={downloadRef}
              downloadItemSlug={`${activities[0].content.template.slug}-insights`}
            />
          </ButtonGroup>
        </Flex>
      </Flex>
      <Flex w="100%" gap={0} align="flex-start" direction="column">
        <Text fontSize="sm">
          Insights are a work in progress -{' '}
          <Link.WithoutRouter
            variant="link"
            fontSize="sm"
            color="blue.500"
            onClick={() => feedback?.openDialog()}
          >
            share your feedback
          </Link.WithoutRouter>
        </Text>
      </Flex>
      <Divider width="100%" mt={2} mb={6} />
      <Flex direction="column" align="start" justify="center" w="100%">
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Date</Th>
                {Object.keys(activities[0].content.values).map((valueId) => {
                  return (
                    <Th
                      key={valueId}
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {valueId.split('.').join(':').split('-').join(' ')}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {activities.map((activity) => {
                // TODO: handle groups
                return (
                  <Tr key={activity.created_at}>
                    <Tooltip
                      label={format(activity.created_at, 'M/d/yy h:mm a')}
                    >
                      <Td> {format(activity.created_at, 'M/d/yy')} </Td>
                    </Tooltip>
                    {Object.values(activity.content.values).map((v, i) => {
                      return <Td key={`${activity.created_at}-${i}`}> {v} </Td>;
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </VStack>
  );
};

const ActivityInsights = () => {
  const params = useParams<{ templateSlug: string }>();
  const {
    isPending,
    data: activities,
    error,
  } = useActivityListBySlug(params.templateSlug!);

  return (
    <Page
      title={
        isPending || error
          ? 'Insights'
          : activities.length > 0
            ? `Insights - ${activities[0].content.template.title}`
            : 'Insights'
      }
    >
      {isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : error ? (
        <Flex align="center" justify="center" w="100%">
          <Text>There was an error loading data</Text>
        </Flex>
      ) : activities.length < 1 ? (
        <Flex align="center" justify="center" w="100%">
          <Text>You haven{"'"}t completed any activities of this type</Text>
        </Flex>
      ) : activities[0].content.template.type != 'tracker' ? (
        <Flex align="center" justify="center" w="100%">
          <Text>Insights are currently only available for trackers!</Text>
        </Flex>
      ) : (
        <TemplateTableDisplay activities={activities} />
      )}
    </Page>
  );
};

export default ActivityInsights;
