import { Outlet } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  OrderedList,
  ListItem,
  Heading,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import Link from 'app/components/Link';
import { useLocalStorage } from '@uidotdev/usehooks';
import TemplateList from 'app/components/TemplateList';

const HowItWorksInfo = () => {
  const [howItWorksVisible, setHowItWorksVisible] = useLocalStorage(
    'TEMPLATE_LIST_USER_ONBOARDING',
    true
  );

  return (
    <Accordion
      allowToggle
      index={howItWorksVisible ? 0 : -1}
      onChange={(expandedIndex) => setHowItWorksVisible(expandedIndex == 0)}
      p={0}
      w="100%"
    >
      <AccordionItem border="none">
        <AccordionButton fontSize="sm" px={0}>
          <Box as="span" flex="1" textAlign="left" pr={2} fontWeight="bold">
            How activity templates work
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          <Flex direction="column" gap={4}>
            <OrderedList fontSize="sm" pl={4} pt={1}>
              <ListItem>Choose an activity template</ListItem>
              <ListItem>Send it to a client instead of PDF worksheets</ListItem>
              <ListItem>
                Clients can complete the activity and download as PDF or save
                securely online
              </ListItem>
            </OrderedList>
            <Text fontSize="sm" pl={4}>
              <Link to="/#plus" color="teal.600" fontWeight="bold">
                Outside Therapy Plus
              </Link>{' '}
              will have customizable templates, allow clients to share
              responses, and be fully HIPAA compliant
            </Text>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const TemplateListPage = () => {
  return (
    <Page title="Activity Templates">
      <Heading
        fontSize={{ base: '4xl', lg: '5xl' }}
        fontWeight="bold"
        fontFamily="DM Serif Text, serif"
        pt={2}
      >
        Activity Templates
      </Heading>
      <Text fontSize={{ base: 'lg', lg: 'xl' }} mb={0}>
        Evidence-based digital therapy activities and tools
        {useBreakpointValue(
          { base: '', lg: ' - no printing required' },
          { ssr: false }
        )}
      </Text>
      <HowItWorksInfo />
      <TemplateList />
      <Outlet />
    </Page>
  );
};

export default TemplateListPage;
