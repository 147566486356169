import { useEffect } from 'react';
import { create } from 'zustand';
import { useQueryClient } from '@tanstack/react-query';
import {
  Flex,
  Heading,
  Text,
  Button,
  ButtonGroup,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';

import {
  AccountProfile,
  useAccountProfileGet,
  useAccountProfileUpdate,
  QueryKeys,
} from 'app/data';

interface ProfileFormStore {
  firstName: string;
  lastName: string;

  initialize: (profile: AccountProfile) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
}

const useProfileFormStore = create<ProfileFormStore>((set) => ({
  firstName: '',
  lastName: '',

  initialize: (profile: AccountProfile) =>
    set({
      firstName: profile.first_name,
      lastName: profile.last_name,
    }),
  setFirstName: (firstName: string) => set({ firstName }),
  setLastName: (lastName: string) => set({ lastName }),
}));

export const ProfileForm = () => {
  const queryClient = useQueryClient();
  const { data: accountProfile } = useAccountProfileGet();

  const toast = useToast();
  const profileUpdate = useAccountProfileUpdate({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Profile updated',
          status: 'success',
          variant: 'subtle',
          isClosable: true,
          position: 'top',
        });

        queryClient.invalidateQueries({
          queryKey: QueryKeys.AccountProfileGet,
        });
      },
      onError: (error) => {
        // XXX handle errors better
        console.error(error.message, error.response?.data);
        const message = error.response?.data?.message as string;
        toast({
          title: message || 'Error updating profile',
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      },
    },
  });

  const [firstName, setFirstName, lastName, setLastName, initialize] =
    useProfileFormStore((state) => [
      state.firstName,
      state.setFirstName,
      state.lastName,
      state.setLastName,
      state.initialize,
    ]);

  const handleDiscard = () => {
    if (accountProfile) {
      initialize(accountProfile);
    }
  };

  const handleUpdate = () => {
    // XXX camelcase?
    const payload = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
    };

    setFirstName(payload.first_name);
    setLastName(payload.last_name);
    profileUpdate.mutate({ data: payload });
  };

  // XXX add actual validation
  const validated = firstName && lastName;
  const touched =
    accountProfile?.first_name !== firstName ||
    accountProfile?.last_name !== lastName;

  useEffect(() => {
    if (accountProfile) {
      initialize(accountProfile);
    }
  }, [accountProfile, initialize]);

  return (
    <VStack align="left" px={3} spacing={0} w="100%">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdate();
        }}
      >
        <Heading
          fontSize={{ base: 'xl', lg: '2xl' }}
          fontWeight="normal"
          fontFamily="DM Serif Text, serif"
          mb={4}
        >
          Profile
        </Heading>
        <Text mb={2} fontSize="sm">
          First Name
        </Text>
        <Input
          mb={4}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          tabIndex={1}
        />

        <Text mb={2} fontSize="sm">
          Last Name
        </Text>
        <Input
          mb={4}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          tabIndex={1}
        />

        <Flex w="100%" align="flex-end" justify="flex-end">
          <ButtonGroup size="md">
            <Button
              variant="outline"
              isDisabled={!touched}
              onClick={handleDiscard}
            >
              Discard
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              isDisabled={!validated || !touched}
              isLoading={profileUpdate.isPending}
              tabIndex={1}
            >
              Save
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </VStack>
  );
};

export default ProfileForm;
