import { Text, Heading, Box, Flex, VStack, Button } from '@chakra-ui/react';
import Page from 'app/pages/Page';
import Link from 'app/components/Link';

interface TemplateLinkProps {
  to: string;
  children: string;
}

const TemplateLink = ({ to, children }: TemplateLinkProps) => {
  return (
    <Link to={to}>
      <Button size="xs" colorScheme="teal" variant="outline" p={2}>
        <Text fontSize="sm">{children}</Text>
      </Button>
    </Link>
  );
};

const Guide = () => {
  return (
    <Page title="A Guide to Cognitive Behavioral Therapy Activities">
      <Heading
        size="xl"
        fontWeight="bold"
        lineHeight={1.25}
        fontFamily="DM Serif Text, serif"
      >
        A Guide to Cognitive Behavioral Therapy (CBT) Activities with Outside
        Therapy
      </Heading>
      <Flex
        direction="row"
        justify="flex-start"
        align="center"
        w="100%"
        gap={8}
        mb={4}
      >
        <Text fontSize="md">By: Sharang Phadke</Text>
        <Text colorScheme="gray">5 min read</Text>
      </Flex>

      <VStack gap={4} align="start">
        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          What is CBT?
        </Text>

        <Text fontSize="md">
          Cognitive behavioral therapy (CBT) is a type of psychotherapy that
          helps people identify and change unhelpful thought and behavior
          patterns. CBT is based on the idea that our{' '}
          <strong>thoughts, emotions, and behaviors</strong> are interconnected,
          and that changing our thought patterns can lead to positive changes in
          how we feel and act. CBT involves practicing{' '}
          <strong>specific skills and activities</strong> to gradually reshape
          negative thought patterns and develop coping strategies. CBT has been
          extensively researched and shown to be effective for a variety of
          mental health conditions including anxiety, depression, PTSD, and
          more. For more comprehensive information on CBT, check out{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://www.nhs.uk/mental-health/talking-therapies-medicine-treatments/talking-therapies-and-counselling/cognitive-behavioural-therapy-cbt/overview/"
          >
            this resource
          </Link.WithoutRouter>{' '}
          from the UK National Health Service or{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://cogbtherapy.com/free-online-cbt-workbook"
          >
            this extensive guide
          </Link.WithoutRouter>{' '}
          from CBT of LA.
        </Text>

        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          How to use this guide and Outside Therapy
        </Text>

        <Text fontSize="md">
          At its core, CBT is about learning skills you can use when needed, and
          learning skills requires practice. Research shows that{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5481663/"
          >
            completing specific activities
          </Link.WithoutRouter>{' '}
          outside of the therapy session leads to significantly better outcomes
          for clients working through CBT. However, most therapists are only
          able to provide PDFs and printed materials to their clients.{' '}
          <strong>
            Outside Therapy allows clients to complete CBT activities securely
            on their computer or mobile device
          </strong>
          , without printing PDFs or carrying around workbooks. Use this guide
          to get a high level understanding of CBT and see which CBT activities
          in Outside Therapy can help you on your journey. We recommend
          discussing these activities with a therapist trained in CBT, but even
          working through them on your own can be helpful.
        </Text>
      </VStack>

      <VStack pt={4} gap={4} align="start">
        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          Step 1: Set Therapy Goals
        </Text>
        <Text fontSize="md">
          An important first step in CBT is to set clear, achievable goals for
          what you want to change or improve. The{' '}
          <TemplateLink to="/workbook/new/design-a-smart-goal">
            Design a SMART Goal
          </TemplateLink>{' '}
          activity guides you through creating a well-defined goal that is
          specific, measurable, achievable, relevant, and time-bound. In
          addition to this activity, we recommend reading{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://cogbtherapy.com/setting-goals-in-cbt"
          >
            this guide
          </Link.WithoutRouter>{' '}
          on how to set specific goals about the issues you are trying to
          address.
        </Text>

        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          Step 2: Learn about the core ideas of CBT
        </Text>
        <Text fontSize="md">
          {`A central tenet of CBT is that our thoughts, feelings, and behaviors
          are all interrelated. For example, imagine you applied for your dream
          job but didn't get it. You might have the thought "I'm a failure and
          will never get hired." This negative thought could fuel feelings of
          sadness, low self-worth, and hopelessness, leading to the behavior of
          giving up on your job search entirely. In CBT, you learn to identify
          and reframe that unhelpful thought into something more balanced like
          "Getting rejected hurts, but it doesn't define my worth. I'll keep
          trying."`}
        </Text>
        <Text fontSize="md">
          Another useful model is the{' '}
          <TemplateLink to="/workbook/new/abc-model-of-cbt">
            ABC Model of CBT
          </TemplateLink>
          .{' '}
          {`The ABC model breaks things down into: A) the event that activated
          your thoughts, B) your beliefs and automatic thoughts about the event,
          and C) the emotional and behavioral consequences driven by those
          beliefs. For instance, let's say the activating event (A) is having a
          disagreement with your partner about what movie to watch. An unhelpful
          belief (B) might be telling yourself "They never consider my opinion.
          This always happens and our relationship is so bad." This thought
          could fuel feelings of anger, hurt, and hopelessness about the
          relationship. The emotional consequence (C) of that belief might be
          snapping at your partner or escalating the disagreement into a major
          fight. In CBT, you target that belief at Step B, learning to reframe
          it more adaptively as "Maybe my partner is really excited about this
          movie and I’ll enjoy it too”. By becoming aware of that flow from A to
          B to C, you can disrupt unhealthy patterns. Here are a few additional
          resources on the core concepts of CBT:`}
        </Text>
        <Box pl={4}>
          <ul>
            <li>
              <Link.WithoutRouter
                fontWeight="semibold"
                color="teal.600"
                isExternal
                href="https://positivepsychology.com/what-is-cbt-definition-meaning/"
              >
                What is CBT?
              </Link.WithoutRouter>
            </li>
            <li>
              <Link.WithoutRouter
                fontWeight="semibold"
                color="teal.600"
                isExternal
                href="https://www.youtube.com/watch?v=ZdyOwZ4_RnI&t=2s"
              >
                How Does Cognitive Behavioral Therapy Work?
              </Link.WithoutRouter>
            </li>
          </ul>
        </Box>

        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          Step 3: Track your mood and thoughts
        </Text>
        <Text fontSize="md">
          The first skill to develop in CBT is an awareness of your mood,
          thoughts, and emotions.{' '}
          <strong>
            Developing awareness is like turning on a flashlight in a dark room
            - it illuminates what was previously hard to see.
          </strong>{' '}
          It creates a space where we can pause, reflect, and decide how we want
          to respond, rather than just reacting impulsively. To develop this
          awareness, you can spend a few weeks simply tracking your mood and
          thoughts so you can reflect back on patterns and recognize when you
          are in a situation that can trigger negative emotions or behaviors in
          the future. Try using the{' '}
          <TemplateLink to="/workbook/new/thought-record-thoughts-and-emotions">
            Thought Record: Thoughts and Emotions
          </TemplateLink>{' '}
          and{' '}
          <TemplateLink to="/workbook/new/mood-tracker">
            Mood Tracker
          </TemplateLink>{' '}
          activities. In particular, you can try to notice when you have{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://cogbtherapy.com/cbt-and-automatic-thoughts"
          >
            automatic thoughts
          </Link.WithoutRouter>{' '}
          to specific situations.
        </Text>

        <Text fontSize="md">
          Clients working through CBT can also benefit from mindfulness
          strategies to develop better awareness, including{' '}
          <strong>{`"mindful rituals"`}</strong> or trying meditation.
        </Text>

        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          Step 4: Understand cognitive distortions
        </Text>
        <Text fontSize="md">
          Once you have a grasp of the common patterns of emotions and thinking
          you experience, it’s often helpful to learn how{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://cogbtherapy.com/cbt-emotions-and-mood"
          >
            emotions
          </Link.WithoutRouter>{' '}
          and{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://cogbtherapy.com/cbt-for-cognitive-distortions"
          >
            cognitive distortions
          </Link.WithoutRouter>{' '}
          work. Our brains have been shaped by millions of years of evolution to
          think impulsively in order to survive in the wild as hunter-gatherers.
          Today, the way our emotions and thought patterns work can lead to
          unproductive and irrational thinking for people from all walks of life
          and backgrounds. The{' '}
          <TemplateLink to="/workbook/new/common-cognitive-distortions">
            Common Cognitive Distortions
          </TemplateLink>{' '}
          activity describes different types of distorted thoughts that you can
          start to recognize, and the{' '}
          <TemplateLink to="/workbook/new/practice-decatastrophizing">
            Practice Decatastrophizing
          </TemplateLink>{' '}
          exercise guides you through evaluating and challenging catastrophic
          thinking patterns.
        </Text>

        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          Step 5: Change your thinking and behavior
        </Text>
        <Text fontSize="md">
          Finally, it’s time to change your thoughts and behaviors to align with
          your goals.
          <strong>
            This is one of the hardest parts of the CBT process, because it
            involves changing neurological and psychological pathways that have
            been built up over months or years.
          </strong>{' '}
          But making progress here can also be rewarding and empowering, so
          remember to be patient and resilient.
        </Text>
        <Text fontSize="md">
          To try and change your thought patterns, it can be helpful to work
          through the{' '}
          <TemplateLink to="/workbook/new/thought-record-challenging-and-reframing">
            Thought Record: Challenging and Reframing
          </TemplateLink>{' '}
          activity for a few weeks. This activity helps you{' '}
          <Link.WithoutRouter
            fontWeight="semibold"
            color="teal.600"
            isExternal
            href="https://cogbtherapy.com/cognitive-restructuring-in-cbt"
          >
            question unhelpful thoughts
          </Link.WithoutRouter>{' '}
          in various ways and consider a more balanced reaction to a triggering
          thought or event.{' '}
          {`For example, you can ask yourself "what advice would
          I give a friend in this situation" or "what is really the evidence for
          or against my unhelpful thought"?`}
        </Text>

        <Text fontSize="md">
          Similarly, it can be helpful to address behaviors that might be
          triggering unhelpful thoughts or emotions. The{' '}
          <TemplateLink to="/workbook/new/run-a-behavioral-experiment">
            Run a Behavioral Experiment
          </TemplateLink>{' '}
          activity can help you plan out a behavior change that addresses
          something you might be avoiding or worried about.
        </Text>
        <Text fontSize="md">
          Again, changing thoughts and behaviors can take time and effort. It
          can help to work with a therapist, talk with trusted friends or
          family, or just write about your experience and effort in this step.
        </Text>

        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          Step 6: Core beliefs and values
        </Text>
        <Text fontSize="md">
          Sometimes, people end up at an impasse in their CBT journey when they
          just can’t figure out how to change their thoughts or behaviors. At
          this stage, it can be helpful to dig deeper and examine why using the
          concepts of core beliefs and values.{' '}
          <TemplateLink to="/workbook/new/core-beliefs">
            Core Beliefs
          </TemplateLink>{' '}
          are the deeply-held assumptions and life rules that underlie our
          perception of ourselves, others, and the world. These beliefs
          powerfully shape our automatic thoughts and can make it very
          challenging to simply talk ourselves out of unhealthy patterns at a
          surface level. One activity that can help in this stage is{' '}
          <TemplateLink to="/workbook/new/clarifying-your-values">
            Clarifying Your Values
          </TemplateLink>
          .
        </Text>
      </VStack>

      <VStack pt={4} gap={4} align="start">
        <Text fontSize="2xl" pt={3} fontWeight="semibold">
          CBT teaches skills that last a lifetime
        </Text>
        <Text fontSize="md">
          By working through these CBT-based exercises and activities, you can
          gain valuable skills for managing difficult thoughts and emotions more
          productively. The path to change takes practice, but CBT provides
          concrete tools to help reshape unhelpful patterns into more positive
          ones. We hope you find this guide and the tools that Outside Therapy
          provides helpful in your journey!
        </Text>
      </VStack>
    </Page>
  );
};

export default Guide;
