import {
  Flex,
  Heading,
  Text,
  Card,
  CardBody,
  Spinner,
  Wrap,
} from '@chakra-ui/react';
import { BasePage, Section } from 'app/pages/Page';
import Link from 'app/components/Link';
import ActivityList from './ActivityList';
import {
  useTemplateList,
  useAccountProfileGet,
  useActivityList,
  Activity,
} from 'app/data';

interface TemplateLinkProps {
  linkTo: string;
  linkText: string;
}

const TemplateLink = ({ linkTo, linkText }: TemplateLinkProps) => {
  return (
    <Card
      variant="outline"
      _hover={{
        cursor: 'pointer',
        background: 'var(--chakra-colors-gray-100)',
      }}
      shadow="none"
      px={2}
      py={1}
    >
      <Link to={linkTo}>
        <Text>{linkText}</Text>
      </Link>
    </Card>
  );
};

const getInsightsFromActivities = (activities: Activity[] | undefined) => {
  return activities
    ? activities
        .filter((activity) => activity.content.template.type == 'tracker')
        .map((activity) => [
          activity.content.template.slug,
          activity.content.template.title,
        ])
        .reduce(
          (acc: Record<string, string>, activityInstance: Array<string>) => {
            acc[activityInstance[0]] = activityInstance[1];
            return acc;
          },
          {}
        )
    : {};
};

const Workbook = () => {
  const profile = useAccountProfileGet();
  const bookmarkedTemplates = useTemplateList({ bookmarked: true });
  const activities = useActivityList();
  const insights = getInsightsFromActivities(activities.data);

  return (
    <>
      <BasePage title="My Workbook" background="gray.100">
        <Section>
          <Flex direction="column" justify="center" align="center">
            <Flex
              direction="column"
              align="start"
              justify="start"
              w="100%"
              maxW="52em"
            >
              <Heading
                fontSize={{ base: '2xl', lg: '3xl' }}
                fontWeight="bold"
                fontFamily="DM Serif Text, serif"
                pt={2}
                pb={4}
              >
                Welcome back, {profile.data?.first_name}
              </Heading>
              <Text>
                This is your space to reflect and practice outside the therapy
                session.
              </Text>
            </Flex>
          </Flex>
        </Section>

        <Section>
          <Flex direction="column" justify="center" align="center">
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align="start"
              justify="center"
              gap={8}
              maxW="52em"
              w="100%"
            >
              <Card w="100%">
                <CardBody>
                  <Flex direction="column" align="start" justify="center">
                    <Heading fontSize="2xl" mb={4}>
                      Start an activity
                    </Heading>

                    <Flex direction="column" align="start" gap={2} mb={4}>
                      <Heading fontSize="xs">Bookmarked templates</Heading>
                      {bookmarkedTemplates.isPending ? (
                        <Flex align="center" justify="center" w="100%">
                          <Spinner size="lg" />
                        </Flex>
                      ) : bookmarkedTemplates.error ? (
                        <Flex align="center" justify="center" w="100%">
                          <Text>Error loading bookmarked templates </Text>
                        </Flex>
                      ) : bookmarkedTemplates.data?.length === 0 ? (
                        <Flex align="center" justify="center" w="100%">
                          <Text>No bookmarked templates</Text>
                        </Flex>
                      ) : (
                        <Wrap>
                          {bookmarkedTemplates.data.map((template) => (
                            <TemplateLink
                              key={template.id}
                              linkTo={`/workbook/new/${template.slug}`}
                              linkText={template.title}
                            />
                          ))}
                        </Wrap>
                      )}
                    </Flex>
                    <Flex direction="column" align="start" gap={2}>
                      <Heading fontSize="xs">Always available</Heading>
                      <Wrap>
                        <TemplateLink
                          linkTo={`/workbook/new/free-form-journal`}
                          linkText="Free Form Journal"
                        />
                        <TemplateLink
                          linkTo={`/workbook/new/gratitude-journal`}
                          linkText="Gratitude Journal"
                        />
                      </Wrap>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>

              <Flex
                direction="column"
                align="start"
                justify="center"
                w="100%"
                gap={8}
              >
                <Card w="100%">
                  <CardBody>
                    <Flex direction="column" align="start" justify="center">
                      <Heading fontSize="2xl" mb={4}>
                        Goals and learnings
                      </Heading>
                      <Wrap>
                        <TemplateLink
                          linkTo="/workbook/note/goals"
                          linkText="🎯 Goals"
                        />
                        <TemplateLink
                          linkTo="/workbook/note/takeaways"
                          linkText="⚡ Takeaways"
                        />
                      </Wrap>
                    </Flex>
                  </CardBody>
                </Card>
                <Card w="100%">
                  <CardBody>
                    <Flex direction="column" align="start" justify="center">
                      <Heading fontSize="2xl" mb={4}>
                        Insights
                      </Heading>
                      <Wrap>
                        {insights ? (
                          Object.entries(insights).map(([slug, title]) => {
                            return (
                              <TemplateLink
                                key={slug}
                                linkTo={`insights/${slug}`}
                                linkText={title}
                              />
                            );
                          })
                        ) : (
                          <Text fontSize="sm">
                            No insights yet! Keep using Outside Therapy to
                            develop insights about your therapy.
                          </Text>
                        )}
                      </Wrap>
                    </Flex>
                  </CardBody>
                </Card>
              </Flex>
            </Flex>
          </Flex>
        </Section>

        <Section>
          <Flex direction="column" justify="center" align="center">
            <ActivityList />
          </Flex>
        </Section>
      </BasePage>
    </>
  );
};

export default Workbook;
