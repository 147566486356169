import { Text } from '@chakra-ui/react';

import Page from 'app/pages/Page';
import EmailForm from './EmailForm';
import ProfileForm from './ProfileForm';
import PasswordForm from './PasswordForm';

export const Account = () => {
  return (
    <Page title="Account" panelWidth="32em">
      <Text
        fontSize="4xl"
        fontWeight="bold"
        fontFamily="DM Serif Text, serif"
        mb={2}
        px={3}
      >
        Account
      </Text>
      <EmailForm />
      <ProfileForm />
      <PasswordForm />
    </Page>
  );
};

export default Account;
