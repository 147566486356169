import {
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  List,
  ListIcon,
  ListItem,
  VStack,
} from '@chakra-ui/react';

import {
  MdMenuBook,
  MdAutoGraph,
  MdOutlineLock,
  MdOutlineSave,
  MdOutlineSend,
  MdOutlineDiamond,
} from 'react-icons/md';

import ClientSignupForm from './ClientSignupForm';
import ProviderSignupForm from './ProviderSignupForm';
import Link from 'app/components/Link';

interface TabbedSignupProps {
  isProvider: boolean;
  onClickTab: (isProvider: boolean) => void;
  onClickLogin: (isProvider: boolean) => void;
}

const TabbedSignup = ({
  isProvider,
  onClickTab,
  onClickLogin,
}: TabbedSignupProps) => {
  return (
    <>
      <Tabs
        w="100%"
        defaultIndex={isProvider ? 1 : 0}
        onChange={(index) => onClickTab(index === 1)}
      >
        <TabList>
          <Tab fontSize="lg">Clients</Tab>
          <Tab fontSize="lg">Therapists</Tab>
        </TabList>

        <TabPanels>
          <TabPanel pt={6}>
            <VStack mb={8} align="left">
              <List spacing={3} pl={{ base: 0, md: 3 }}>
                <ListItem fontSize="md">
                  <Flex align="center">
                    <ListIcon as={MdMenuBook} fontSize="xl" mr={4} />
                    Evidence-based therapy activities from your therapist
                  </Flex>
                </ListItem>
                <ListItem fontSize="md">
                  <Flex align="center">
                    <ListIcon as={MdAutoGraph} fontSize="xl" mr={4} />
                    Practice and reflect any time
                  </Flex>
                </ListItem>
                <ListItem fontSize="md">
                  <Flex align="center">
                    <ListIcon as={MdOutlineLock} fontSize="xl" mr={4} />
                    Secure, private, and free
                  </Flex>
                </ListItem>
              </List>
            </VStack>
            <ClientSignupForm onClickLogin={() => onClickLogin(false)} />
          </TabPanel>
          <TabPanel pt={6}>
            <VStack mb={8} align="left">
              <List spacing={3} pl={{ base: 0, md: 3 }}>
                <ListItem fontSize="md">
                  <Flex align="center">
                    <ListIcon as={MdOutlineSend} fontSize="xl" mr={4} />
                    Send evidence-based activities to your clients between
                    sessions
                  </Flex>
                </ListItem>
                <ListItem fontSize="md">
                  <Flex align="center">
                    <ListIcon as={MdOutlineSave} fontSize="xl" mr={4} />
                    Clients can download activities as PDFs or save securely on
                    the website
                  </Flex>
                </ListItem>
                <ListItem fontSize="md">
                  <Flex align="center">
                    <ListIcon
                      as={MdOutlineDiamond}
                      color="teal.600"
                      fontSize="xl"
                      mr={4}
                    />
                    <Text>
                      <Link to="/#plus" color="teal.600" fontWeight="bold">
                        Outside Therapy Plus
                      </Link>{' '}
                      has customizable templates, allows clients to share
                      completed activites, and is fully HIPAA compliant
                    </Text>
                  </Flex>
                </ListItem>
              </List>
            </VStack>
            <ProviderSignupForm onClickLogin={() => onClickLogin(true)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default TabbedSignup;
