import { useRouteError } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import Page from 'app/pages/Page';

const ErrorPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();
  console.error(error);

  return (
    <Page title="Error">
      <Text fontSize="2xl" fontWeight="bold">
        Oops!
      </Text>
      <Text>Sorry, an unexpected error has occurred.</Text>
      <Text>
        <i>{error.statusText || error.message}</i>
      </Text>
    </Page>
  );
};

export default ErrorPage;
