import { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Card,
  CardBody,
  HStack,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Spinner,
  Wrap,
  Tooltip,
} from '@chakra-ui/react';
import { MdPerson, MdSearch } from 'react-icons/md';
import { Select, MultiValue } from 'chakra-react-select';
import Link from 'app/components/Link';
import { TemplateCardTag } from 'app/components/TemplateCardTag';
import { useTemplateList, TemplateListParams, Template } from 'app/data';
import { TemplateBookmark } from './TemplateBookmark';

type TemplateCardProps = {
  template: Template;
  isProviderTemplate?: boolean;
};

const TemplateCard = ({ template, isProviderTemplate }: TemplateCardProps) => {
  const { slug, title, description, type, tags } = template;

  return (
    <Box w="100%">
      <Link.Router
        to={`/templates/${slug}`}
        preventScrollReset={true}
        state={{ isProviderTemplate: !!isProviderTemplate }}
      >
        <Card
          mb={2}
          mt={2}
          w="100%"
          variant="outline"
          _hover={{
            cursor: 'pointer',
            background: 'var(--chakra-colors-gray-100)',
          }}
        >
          <CardBody p="12px" w="100%">
            <Flex direction="column">
              <Flex
                justify="space-between"
                align="start"
                gap={2}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text
                  noOfLines={{ base: 2, md: 1 }}
                  fontSize="lg"
                  fontWeight="semibold"
                >
                  {title}
                </Text>
                <HStack spacing="8px">
                  {tags.map((tag: string, idx: number) => (
                    <TemplateCardTag key={idx} name={tag} type="tag" />
                  ))}
                  {<TemplateCardTag name={type} type="type" />}
                  {!template.is_public && (
                    <Tooltip label="Private template">
                      <span>
                        <Icon as={MdPerson} />
                      </span>
                    </Tooltip>
                  )}
                  <TemplateBookmark template={template} size="sm" />
                </HStack>
              </Flex>
              <Flex justify="start" align="center">
                <Text noOfLines={2} fontSize="sm" mb="4px" mt="4px">
                  {description}
                </Text>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Link.Router>
    </Box>
  );
};

interface TemplateResultListProps {
  templates: Array<Template>;
  isProviderList?: boolean;
}

const TemplateResultList = ({
  templates,
  isProviderList,
}: TemplateResultListProps) => {
  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      {templates.map((template) => (
        <TemplateCard
          key={template.slug}
          template={template}
          isProviderTemplate={isProviderList}
        />
      ))}
    </Flex>
  );
};

interface TemplateListProps {
  defaultListParams?: TemplateListParams;
  isProviderList?: boolean;
}

export const TemplateList = ({
  defaultListParams = {},
  isProviderList = false,
}: TemplateListProps) => {
  const {
    isPending,
    data: templates,
    error,
  } = useTemplateList(defaultListParams);

  //Collapse tags into a set, then map into options format
  const tagFilterOptions = [
    ...new Set((templates || []).map((template) => template.tags).flat()),
  ].map((label) => ({
    label,
    value: label.toLowerCase(),
  }));

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  const onSelectedTagChange = (
    selected: MultiValue<{ label: string; value: string }>
  ) => {
    const flattened = selected.map(({ label }) => label);
    setSelectedTags(flattened);
  };

  const hasSearchTerm = searchTerm.length > 0;
  const hasSelectedTags = selectedTags.length > 0;

  const loadedData = templates && templates.length > 0;
  const isSearchEmpty = !hasSearchTerm && !hasSelectedTags;
  const matchesSearchTerm = (template: Template) => {
    const search = searchTerm.toLowerCase();

    return (
      template.title.toLowerCase().includes(search) ||
      template.description.toLowerCase().includes(search) ||
      template.type.includes(search)
    );
  };
  const matchesSelectedTags = (template: Template) => {
    return template.tags.some((tag) => selectedTags.includes(tag));
  };

  let visibleTemplates = !loadedData ? [] : templates;

  if (!isSearchEmpty) {
    visibleTemplates = visibleTemplates.filter((template) => {
      return (
        (!hasSearchTerm || matchesSearchTerm(template)) &&
        (!hasSelectedTags || matchesSelectedTags(template))
      );
    });
  }

  return (
    <>
      <Flex direction="column" p={0} w="100%" mt={4}>
        <InputGroup size="lg" mb={6}>
          <InputLeftElement pointerEvents="none">
            <Icon as={MdSearch} color="grey" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search activity templates"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        <Wrap justify="start" spacingX={8} spacingY={4}>
          <Select
            isMulti
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            options={tagFilterOptions}
            placeholder="Filter by category"
            closeMenuOnSelect={false}
            size="sm"
            onChange={onSelectedTagChange}
          />
        </Wrap>
        {templates?.length ? (
          <Box textAlign="right" mt={4}>
            <Text fontSize="sm">
              {visibleTemplates.length} of {templates.length} templates
            </Text>
          </Box>
        ) : !isPending && !error ? (
          <Box textAlign="center" mt={4}>
            <Text fontSize="sm">No templates found.</Text>
          </Box>
        ) : null}
      </Flex>
      {isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : error ? (
        <Flex align="center" justify="center" w="100%">
          <Text>There was an error loading the templates.</Text>
        </Flex>
      ) : (
        <TemplateResultList
          templates={visibleTemplates}
          isProviderList={isProviderList}
        />
      )}
    </>
  );
};

export default TemplateList;
