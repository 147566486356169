import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { isBefore } from 'date-fns';

const AUTH_STORAGE_KEY = 'ot.auth';

export const AuthRoles = {
  PROVIDER: 'provider',
  CLIENT: 'client',
} as const;

type Role = (typeof AuthRoles)[keyof typeof AuthRoles];

export interface AuthState {
  token: string | null;
  role: Role | null;
  expires: string | null;

  setAuthState: (token: string, role: Role, expires: string) => void;
  clearAuthState: () => void;
  cleanupAuthState: () => void;
}

const initialAuthState = { token: null, role: null, expires: null };

const useAuthStore = create<AuthState, [['zustand/persist', AuthState]]>(
  persist(
    (set) => ({
      ...initialAuthState,

      setAuthState: (token: string, role: Role, expires: string) =>
        set({ token, role, expires }),
      clearAuthState: () => set(initialAuthState),
      cleanupAuthState: () => {
        set((state: AuthState) => {
          if (state.expires && isBefore(state.expires, new Date())) {
            return initialAuthState;
          }

          return state;
        });
      },
    }),
    { name: AUTH_STORAGE_KEY, version: 0 }
  )
);

export default useAuthStore;
