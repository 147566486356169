import {
  Container,
  Image,
  Flex,
  Text,
  VStack,
  Heading,
  Spacer,
  ButtonGroup,
  Button,
  Wrap,
  CardBody,
  Card,
  ListItem,
  List,
  ListIcon,
  UnorderedList,
  Icon,
} from '@chakra-ui/react';
import { BasePage, Section } from 'app/pages/Page';
import {
  MdArrowRightAlt,
  MdAutoGraph,
  MdMobileFriendly,
  MdOutlineLock,
  MdOutlineSave,
  MdOutlineSend,
  MdSearch,
  MdOutlineDiamond,
} from 'react-icons/md';
import Link from 'app/components/Link';
import { onGetStartedClick } from 'app/analytics';
import EmailListForm from './EmailListForm';

const Home = () => {
  return (
    <BasePage title="Digital Therapy Workbook">
      <Section>
        <Flex
          justify="stretch"
          align={{ base: 'center', xl: 'start' }}
          direction={{ base: 'column', xl: 'row' }}
          pt={8}
          pl={4}
          pr={4}
          gap={6}
        >
          <Flex direction="column" maxW={{ base: '48em', xl: '40em' }}>
            <Heading size="2xl" fontWeight="semibold" lineHeight={1.25}>
              A digital workbook designed to enhance therapy
            </Heading>
            <Text pt={8} fontSize="xl">
              Outside Therapy is the first{' '}
              <b>customizable, evidence-based digital therapy workbook</b> that
              helps therapists and clients make more progress together.
            </Text>
            <Flex pt={8} gap={6} flexDirection={{ base: 'column', md: 'row' }}>
              <Flex gap={3} alignItems="start" flexDirection="column">
                <Link to="/templates">
                  <Button
                    rightIcon={<MdArrowRightAlt size="24" />}
                    colorScheme="blue"
                    onClick={onGetStartedClick}
                  >
                    Get started for free
                  </Button>
                </Link>
                <Text fontSize="xs" pl={2}>
                  No login or payment required
                </Text>
              </Flex>
              <Link to="#plus">
                {/* Join the waitlist for Plus | Get 60 days of Plus free */}
                <Button
                  leftIcon={<MdOutlineDiamond size="24" />}
                  color="teal.600"
                >
                  <Text color="gray.800">See what{"'"}s coming in Plus</Text>
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Spacer />
          <Image
            w={{ base: '100%', lg: '75%' }}
            maxW={600}
            src="home_screenshots_1.png"
          ></Image>
        </Flex>
      </Section>

      <Section>
        <Wrap
          w="100%"
          justify="center"
          align="top"
          spacing={8}
          pl={0}
          pr={0}
          m={0}
        >
          <Card>
            <CardBody>
              <Container
                m={0}
                pt={4}
                pb={2}
                w={{ base: '100%', xl: '32em' }}
                maxW="40em"
              >
                <Heading size="lg" fontWeight="semibold" pb={6}>
                  For Therapists
                </Heading>
                <List spacing={3}>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdSearch} fontSize="2xl" mr={4} />
                      Find evidence-based therapy activities and worksheets
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdOutlineSend} fontSize="2xl" mr={4} />
                      Send a link to your clients so they can complete
                      activities digitally
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdOutlineSave} fontSize="2xl" mr={4} />
                      Clients can download activities as PDFs or save securely
                      on the website
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon
                        as={MdOutlineDiamond}
                        color="teal.600"
                        fontSize="2xl"
                        mr={4}
                      />
                      <Text>
                        <Link to="#plus" color="teal.600" fontWeight="bold">
                          Outside Therapy Plus
                        </Link>{' '}
                        has customizable templates, allows clients to share
                        completed activites, and is fully HIPAA compliant
                      </Text>
                    </Flex>
                  </ListItem>
                </List>
                <ButtonGroup pt={10} spacing={3}>
                  <Link to="/templates">
                    <Button>Browse therapy activities</Button>
                  </Link>
                </ButtonGroup>
              </Container>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Container
                pt={4}
                pb={2}
                w={{ base: '100%', xl: '32em' }}
                maxW="40em"
              >
                <Heading size="lg" fontWeight="semibold" pb={6}>
                  For Clients
                </Heading>
                <List spacing={3}>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdMobileFriendly} fontSize="2xl" mr={4} />
                      Access your therapy workbook on the go instead of carrying
                      paper books or PDFs
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdAutoGraph} fontSize="2xl" mr={4} />
                      Get more out of your therapy sessions by making practice
                      and reflection easy
                    </Flex>
                  </ListItem>
                  <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                    <Flex align="center">
                      <ListIcon as={MdOutlineLock} fontSize="2xl" mr={4} />
                      Secure and free
                    </Flex>
                  </ListItem>
                </List>
                <ButtonGroup pt={10} spacing={3}>
                  <Link to="/signup">
                    <Button>Start your workbook for free</Button>
                  </Link>
                </ButtonGroup>
              </Container>
            </CardBody>
          </Card>
        </Wrap>
      </Section>

      <Section>
        <Flex justify="center" align="center" id="plus">
          <Container m={0} pt={8} pb={2} maxW={{ lg: '48em' }}>
            <Heading size="lg" fontWeight="semibold" lineHeight={1.25} pb={4}>
              <Icon as={MdOutlineDiamond} color="teal.600" mr={3} />
              Outside Therapy Plus
            </Heading>
            <Heading size="sm" pb={4} fontWeight="semibold">
              These features and more coming soon!
            </Heading>
            <UnorderedList spacing={3} pl={4} pb={10}>
              <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                Customize evidence-based therapy activities for each client
              </ListItem>
              <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                Import, digitize, and organize your favorite PDF therapy
                worksheets with AI
              </ListItem>
              <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                See client activities as they are completed with full HIPAA
                compliance and security features
              </ListItem>
              <ListItem fontSize={{ base: 'lg', xl: 'xl' }}>
                Equip your clients with a beautiful and calm iOS and Android app
              </ListItem>
            </UnorderedList>
            <Heading size="sm" pb={4} fontWeight="semibold">
              Outside Therapy Plus is affordable at $20 / month. Get a 2 month
              free trial by joining the waitlist.
            </Heading>
            <EmailListForm listSlug="outside-therapy-plus" />
          </Container>
        </Flex>
      </Section>

      <Section>
        <Flex justify="center" align="center">
          <Container m={0} pt={8} pb={2} maxW={{ lg: '48em' }}>
            <Heading size="lg" fontWeight="semibold" lineHeight={1.25} pb={4}>
              How can we help your practice?
            </Heading>
            <VStack align="left">
              <Text fontSize={{ base: 'lg', xl: 'xl' }}>
                Reach us at{' '}
                <Link.WithoutRouter
                  color="teal.600"
                  href="mailto:hello@getoutsidetherapy.com"
                  fontWeight="semibold"
                >
                  hello@getoutsidetherapy.com
                </Link.WithoutRouter>{' '}
                or read more about{' '}
                <Link color="teal.600" to="/about" fontWeight="semibold">
                  our vision
                </Link>
              </Text>
              <Text fontSize={{ base: 'lg', xl: 'xl' }}>
                We are committed to making our product accessible for therapists
                and clients.
              </Text>
            </VStack>
          </Container>
        </Flex>
      </Section>
    </BasePage>
  );
};

export default Home;
