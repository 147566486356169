import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';

import Link from 'app/components/Link';
import Page from 'app/pages/Page';

import {
  useAuthPasswordEmailLink,
  useAuthPasswordVerifyToken,
  useAuthPasswordReset,
} from 'app/data';

enum State {
  EMAIL_INPUT,
  LINK_SENT,
  TOKEN_VERIFY,
  RESET_PASSWORD,
  SUCCESS,
}

interface EmailInputStateProps {
  onSent: () => void;
  onError: () => void;
}

const EmailInputState = ({ onSent, onError }: EmailInputStateProps) => {
  const [email, setEmail] = useState('');
  const validated = !!email;
  const [submitting, setSubmitting] = useState(false);

  const emailLink = useAuthPasswordEmailLink({
    mutation: {
      onSuccess: onSent,
      onError,
      onSettled: () => setSubmitting(false),
    },
  });

  return (
    <>
      <Text mb={4} fontSize="sm">
        Enter your email to receive a password reset link.
      </Text>

      <Box w="100%">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitting(true);
            emailLink.mutate({ data: { email } });
          }}
        >
          <Text mb={2} fontSize="sm">
            Email
          </Text>
          <Input
            placeholder="Email"
            mb={4}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            tabIndex={1}
          />
          <Flex w="100%" align="flex-end" justify="flex-end">
            <Button
              type="submit"
              isDisabled={!validated}
              isLoading={submitting}
              colorScheme="blue"
              tabIndex={1}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
};

interface LinkSentStateProps {
  onResend: () => void;
}

const LinkSentState = ({ onResend }: LinkSentStateProps) => (
  <>
    <Text mb={4} fontSize="sm">
      A password reset link was sent to the provided email if it belongs to an
      account. The link expires in 15 minutes.
    </Text>

    <Text mb={4} fontSize="sm">
      If you don&apos;t see an email, please check your spam folder. Emails may
      take up to a few minutes to arrive.
    </Text>

    <Flex align="center" justify="flex-start" w="100%" mb={2}>
      <Text fontSize="sm">
        Didn&apos;t receive an link?{' '}
        <Link.WithoutRouter onClick={onResend}>Resend Email</Link.WithoutRouter>
      </Text>
    </Flex>
  </>
);

interface TokenVerifyStateProps {
  token: string;
  onValid: () => void;
  onInvalid: () => void;
}

const TokenVerifyState = ({
  token,
  onValid,
  onInvalid,
}: TokenVerifyStateProps) => {
  const { status, data } = useAuthPasswordVerifyToken(token);

  useEffect(() => {
    if (token && status === 'success') {
      onValid();
    } else if (token && status === 'error') {
      onInvalid();
    }
  }, [token, status, data, onValid, onInvalid]);

  return (
    <>
      <Text mb={4} fontSize="sm">
        Verifying password reset link...
      </Text>
      <Flex justify="center" align="center" w="100%" mb={2}>
        <Spinner />
      </Flex>
    </>
  );
};

interface ResetPasswordStateProps {
  token: string;
  onReset: () => void;
  onError: (err: AxiosError) => void;
}

const ResetPasswordState = ({
  token,
  onReset,
  onError,
}: ResetPasswordStateProps) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordsValidated = !!newPassword && newPassword === confirmPassword;
  const [submitting, setSubmitting] = useState(false);

  const resetPassword = useAuthPasswordReset({
    mutation: {
      onSuccess: onReset,
      onError,
      onSettled: () => setSubmitting(false),
    },
  });

  return (
    <>
      <Text mb={4} fontSize="sm">
        Enter your new password.
      </Text>

      <Box w="100%">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitting(true);
            resetPassword.mutate({
              data: {
                token,
                new_password: newPassword,
                confirm_password: confirmPassword,
              },
            });
          }}
        >
          <Text mb={2} fontSize="sm">
            New Password
          </Text>
          <Input
            type="password"
            mb={4}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />

          <Text mb={2} fontSize="sm">
            Confirm Password
          </Text>
          <Input
            type="password"
            mb={4}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Flex w="100%" align="flex-end" justify="flex-end">
            <Button
              type="submit"
              isDisabled={!passwordsValidated}
              isLoading={submitting}
              colorScheme="blue"
              tabIndex={1}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
};

const SuccessState = () => (
  <>
    <Text mb={4} fontSize="sm">
      Password reset successful. You may now log in with your new password.
    </Text>

    <Flex align="center" justify="flex-end" w="100%">
      <Button as={Link} to="/login" colorScheme="blue" tabIndex={1}>
        Log In
      </Button>
    </Flex>
  </>
);

const ForgotPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resetToken = searchParams.get('token') || null;
  const [token] = useState<string | null>(resetToken);
  useEffect(() => {
    if (resetToken) {
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [resetToken, searchParams, setSearchParams]);

  const [formState, setFormState] = useState<State>(
    !token ? State.EMAIL_INPUT : State.TOKEN_VERIFY
  );

  const toast = useToast();
  const onEmailError = () => {
    toast({
      title: 'Error Emailing Link',
      description: 'An error occurred. Please try again.',
      status: 'error',
      variant: 'subtle',
      position: 'top',
      isClosable: true,
    });
  };

  const onTokenInvalid = () => {
    toast({
      title: 'Invalid Link',
      description: 'This link is expired or invalid. Please try again.',
      status: 'error',
      variant: 'subtle',
      position: 'top',
      isClosable: true,
    });

    setFormState(State.EMAIL_INPUT);
  };

  const onResetError = (err: AxiosError) => {
    const data = err.response?.data as { message?: string | string[] };
    const message = Array.isArray(data?.message)
      ? data?.message.join(' ')
      : data?.message || 'An error occurred. Please try again.';

    toast({
      title: 'Error Resetting Password',
      description: message,
      status: 'error',
      variant: 'subtle',
      position: 'top',
      isClosable: true,
    });
  };

  return (
    <Page title="Forgot Password" panelWidth="32em">
      <Text
        fontSize="4xl"
        fontWeight="bold"
        fontFamily="DM Serif Text, serif"
        mb={4}
      >
        Forgot Password
      </Text>
      {formState === State.EMAIL_INPUT ? (
        <EmailInputState
          onSent={() => setFormState(State.LINK_SENT)}
          onError={onEmailError}
        />
      ) : formState === State.LINK_SENT ? (
        <LinkSentState onResend={() => setFormState(State.EMAIL_INPUT)} />
      ) : formState === State.TOKEN_VERIFY ? (
        <TokenVerifyState
          token={token!}
          onValid={() => setFormState(State.RESET_PASSWORD)}
          onInvalid={onTokenInvalid}
        />
      ) : formState === State.RESET_PASSWORD ? (
        <ResetPasswordState
          token={token!}
          onReset={() => setFormState(State.SUCCESS)}
          onError={onResetError}
        />
      ) : formState === State.SUCCESS ? (
        <SuccessState />
      ) : (
        (null as never)
      )}
    </Page>
  );
};

export default ForgotPassword;
