import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  VStack,
  HStack,
  Flex,
  Spacer,
  Text,
  Button,
  ButtonGroup,
  Heading,
  useBreakpointValue,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuDivider,
  MenuItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Wrap,
  Badge,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { BrowserClient, Feedback, getClient } from '@sentry/react';
import Link from 'app/components/Link';
import Role from 'app/components/auth/Role';
import { AccountProfile, useAccountProfileGet, useAuthLogout } from 'app/data';
import useAuthStore from 'app/stores/auth';
import * as analytics from 'app/analytics';
import { MdPerson } from 'react-icons/md';

type ProfileMenuProps = {
  authenticated: boolean;
  accountProfile: AccountProfile | undefined;
  handleLogout?: () => void;
};

const ProfileMenu = ({
  authenticated,
  accountProfile,
  handleLogout,
}: ProfileMenuProps) => {
  const displayLogin = useBreakpointValue({ base: false, md: true });
  const profileInitials =
    accountProfile && accountProfile.first_name && accountProfile.last_name
      ? accountProfile.first_name[0].toUpperCase() +
        accountProfile.last_name[0].toUpperCase()
      : undefined;

  return (
    <>
      {authenticated ? (
        <Menu autoSelect={false}>
          {profileInitials ? (
            <MenuButton as={IconButton} aria-label="Profile menu" isRound>
              {profileInitials}
            </MenuButton>
          ) : (
            <MenuButton
              as={IconButton}
              fontSize="xl"
              color="gray.600"
              icon={<MdPerson />}
              aria-label="Profile menu"
              isRound
            />
          )}
          <MenuList>
            <MenuItem pointerEvents={'none'}>
              <Box>
                <Text fontWeight="bold">
                  {accountProfile?.first_name} {accountProfile?.last_name}
                  <Role.Provider>
                    <Badge ml={2} variant="subtle">
                      Therapist
                    </Badge>
                  </Role.Provider>
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {accountProfile?.email}
                </Text>
              </Box>
            </MenuItem>
            <MenuDivider />
            <Link.Router to="/account">
              <MenuItem>Your account</MenuItem>
            </Link.Router>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <ButtonGroup>
          {displayLogin ? (
            <Link to={'/login'}>
              <Button variant="ghost">Log In</Button>
            </Link>
          ) : (
            ''
          )}
          <Link to={'/signup'}>
            <Button variant="outline">Sign up</Button>
          </Link>
        </ButtonGroup>
      )}
    </>
  );
};

interface HeaderProps {
  authenticated: boolean;
  accountProfile: AccountProfile | undefined;
}

export const Header = ({ authenticated, accountProfile }: HeaderProps) => {
  const queryClient = useQueryClient();

  const [clearAuthState] = useAuthStore((state) => [state.clearAuthState]);

  const logout = useAuthLogout();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout.mutateAsync();
    } catch (error) {
      console.error(
        'Error logging out:',
        error instanceof Error ? error.message : error
      );
    } finally {
      clearAuthState();
      queryClient.clear();
      analytics.reset();
      Sentry.setUser(null);
      navigate('/');
    }
  };

  const logoText = useBreakpointValue(
    {
      base: 'OT',
      md: 'Outside Therapy',
    },
    { ssr: false }
  );

  const workbookMenuName = useBreakpointValue(
    {
      base: 'Workbook',
      md: 'My Workbook',
    },
    { ssr: false }
  );
  const activityLibraryMenuName = useBreakpointValue(
    {
      base: 'Library',
      md: 'Activity Library',
    },
    { ssr: false }
  );

  return (
    <Flex
      w="100%"
      borderBottom="1px solid #eaeaea"
      align="center"
      justify="center"
      position="fixed"
      top="0"
      background="white"
      zIndex="sticky"
    >
      <Flex
        w="100%"
        align="center"
        minW="100px"
        maxW="96em"
        py={{ base: 2, md: 4 }}
        px={{ base: 4, md: 8 }}
      >
        <HStack gap={{ base: 3, md: 6 }}>
          <Link.Router to="/">
            <Heading
              fontSize="2xl"
              fontWeight="semibold"
              fontFamily="DM Serif Text, serif"
              maxW={{ base: '80px', md: '200px' }}
            >
              {logoText}
            </Heading>
          </Link.Router>
          <Spacer />
          <HStack gap={5}>
            <Role.Provider>
              <Link to="/dashboard">
                <Text>Dashboard</Text>
              </Link>
            </Role.Provider>
            <Role.Client>
              <Link to="/workbook">
                <Text>{workbookMenuName}</Text>
              </Link>
            </Role.Client>
            <Link to="/templates">
              <Text>{activityLibraryMenuName}</Text>
            </Link>
            <Link to="/guide">
              <Text>CBT Guide</Text>
            </Link>
          </HStack>
        </HStack>
        <Spacer />
        <ProfileMenu
          authenticated={authenticated}
          accountProfile={accountProfile}
          handleLogout={handleLogout}
        />
      </Flex>
    </Flex>
  );
};

export const Footer = () => {
  const year = new Date().getFullYear();
  const sentryClient = getClient<BrowserClient>();
  const feedback = sentryClient?.getIntegration(Feedback);

  return (
    <Flex
      minH="100%"
      direction={{ base: 'column', md: 'row' }}
      p={8}
      pr={{ base: 8, md: 16 }}
      pl={{ base: 8, md: 16 }}
      align="center"
    >
      <Text
        fontSize="xs"
        color="gray.500"
        mb={{ base: 4, md: 0 }}
        mr={{ base: 0, md: 4 }}
      >
        Copyright © {year} Intuition Technologies, Inc
      </Text>
      <Spacer />
      <Wrap align="center" justify="center" spacing={4}>
        <Link to="/privacy" color="gray.500" fontSize="xs">
          Privacy Policy
        </Link>
        <Link to="/terms" fontSize="xs" color="gray.500">
          Terms of Service
        </Link>
        <Link to="/about" fontSize="xs" color="gray.500">
          About
        </Link>
        <Spacer />
        {!!feedback && (
          <Link.WithoutRouter
            fontSize="xs"
            color="gray.500"
            onClick={() => feedback.openDialog()}
          >
            Send Feedback
          </Link.WithoutRouter>
        )}
      </Wrap>
    </Flex>
  );
};

interface AccountAlertsProps {
  accountProfile: AccountProfile | undefined;
}

const AccountAlerts = ({ accountProfile }: AccountAlertsProps) => {
  const [isEmailVerifyAlertOpen, setEmailVerifyAlertOpen] = useState(false);

  useEffect(() => {
    if (accountProfile) {
      setEmailVerifyAlertOpen(!accountProfile.email_verified);
    }
  }, [accountProfile, setEmailVerifyAlertOpen]);

  if (isEmailVerifyAlertOpen) {
    return (
      <Container p={0} maxW={{ base: '100%', md: '54em' }} my={4}>
        <Alert status="info" w="100%" m={0}>
          <AlertIcon />
          <Box w="100%">
            <AlertTitle fontSize="sm" mr={2}>
              Please verify your email
            </AlertTitle>
            <AlertDescription>
              <Text fontSize="sm">
                We&apos;ve sent a verification link to your email address.
              </Text>
            </AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setEmailVerifyAlertOpen(false)}
          />
        </Alert>
      </Container>
    );
  }

  return null;
};

interface BasePageProps {
  title?: string;
  children: React.ReactNode;
  background?: string;
}

export const BasePage = ({ title, children, background }: BasePageProps) => {
  const [authenticated] = useAuthStore((state) => [!!state.token]);
  const { data: accountProfile } = useAccountProfileGet({
    query: { enabled: authenticated },
  });

  useEffect(() => {
    if (accountProfile) {
      analytics.identify(accountProfile.id);
      Sentry.setUser({ id: accountProfile.id });
    }
  }, [Sentry, accountProfile, analytics.identify]);

  return (
    <>
      {title && (
        <Helmet>
          <title>{title} - Outside Therapy</title>
        </Helmet>
      )}
      <Box maxW="100%" minH="100vh" p="0" background={background}>
        <VStack
          minH="100vh"
          spacing={0}
          align="stretch"
          justify="space-between"
        >
          <VStack spacing={0} align="stretch">
            <Header
              authenticated={authenticated}
              accountProfile={accountProfile}
            />

            <Box p={0} mt={20} mb={4} w="100%">
              <AccountAlerts accountProfile={accountProfile} />
              {children}
            </Box>
          </VStack>

          <Footer />
        </VStack>
      </Box>
    </>
  );
};

// XXX expose section maxWidth and padding as props
export const Section = ({ children }: { children: React.ReactNode }) => (
  <Flex align="center" justify="center">
    <Container m={0} pt={8} pb={8} maxW="80em">
      {children}
    </Container>
  </Flex>
);

interface PageProps extends BasePageProps {
  panelWidth?: string;
}

const Page = ({ title, children, panelWidth = '54em' }: PageProps) => {
  return (
    <BasePage title={title} background="gray.100">
      <Container
        p={{ base: 4, md: 8 }}
        maxW={panelWidth}
        background="white"
        borderRadius={8}
      >
        <VStack align="start" spacing={4} w="100%">
          {children}
        </VStack>
      </Container>
    </BasePage>
  );
};

export default Page;
