import { format } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import {
  Text,
  Flex,
  Heading,
  IconButton,
  Tooltip,
  useToast,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  CardBody,
  Card,
  Button,
  ButtonGroup,
  Icon,
} from '@chakra-ui/react';
import { MdAutoAwesome, MdOutlineLock, MdQuestionMark } from 'react-icons/md';

import { BasePage, Section } from 'app/pages/Page';
import TemplateList from 'app/components/TemplateList';

import FileUpload from './FileUpload';

import { useFileUpload, useFileListPendingByType, QueryKeys } from 'app/data';
import Link from 'app/components/Link';

const UPLOAD_TYPE = 'uploaded_worksheet';

const ProviderHome = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const pendingFiles = useFileListPendingByType(UPLOAD_TYPE);

  const fileUpload = useFileUpload({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'File uploaded',
          description: 'Your file will be processed within 48 hours',
          status: 'success',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });

        queryClient.invalidateQueries({
          queryKey: QueryKeys.FileListPendingByType(UPLOAD_TYPE),
        });
      },
      onError: (error) => {
        console.error(error.message, error.response?.data);
        toast({
          title: 'Error uploading file',
          description: error.response?.data.message || 'An error occurred',
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      },
    },
  });

  const handleFileUpload = (file: File) => {
    fileUpload.mutate({ type: UPLOAD_TYPE, data: { file } });
  };

  return (
    <BasePage title="Dashboard" background="gray.100">
      <Section>
        <Flex direction="column" justify="center" align="center">
          <Flex
            direction="column"
            align="center"
            justify="center"
            gap={8}
            maxW="52em"
            w="100%"
          >
            <Card w="100%">
              <CardBody>
                <Flex
                  align="start"
                  justify="start"
                  w="100%"
                  direction="column"
                  gap={2}
                  pb={4}
                >
                  <Flex
                    align="flex-start"
                    justify="space-between"
                    w="100%"
                    gap={4}
                    pb={4}
                  >
                    <Heading fontSize="2xl" fontWeight="bold" pr={4}>
                      Import worksheets
                    </Heading>
                    <FileUpload onUpload={handleFileUpload} />
                  </Flex>
                  <Text fontSize="md">
                    Automatically turn your favorite worksheets into interactive
                    activities. Imported worksheets are{' '}
                    <span style={{ display: 'inline-block' }}>
                      {' '}
                      <Icon as={MdOutlineLock} /> <b> private </b>
                    </span>{' '}
                    to you and your clients.
                  </Text>
                </Flex>
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  {pendingFiles.isLoading && <Spinner />}
                  {pendingFiles.isSuccess && pendingFiles.data.length > 0 && (
                    <TableContainer>
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>File</Th>
                            <Th>Status</Th>
                            <Th>Uploaded</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pendingFiles.data.map((file, idx) => (
                            <Tr key={idx}>
                              <Td>
                                <Text noOfLines={1}>{file.name}</Text>
                              </Td>
                              <Td>
                                <Badge>Processing</Badge>
                              </Td>
                              <Td>
                                {format(file.uploaded_at, 'M/d/yy h:mm a')}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  )}
                </Flex>
              </CardBody>
            </Card>
            <Card w="100%">
              <CardBody>
                <Flex direction="column" align="start" justify="center" gap={4}>
                  <Flex
                    align="flex-start"
                    justify="space-between"
                    w="100%"
                    gap={4}
                    pb={4}
                  >
                    <Flex w="100%" align="center" gap={2}>
                      <Icon
                        fontSize="xl"
                        color="yellow.500"
                        as={MdAutoAwesome}
                      />
                      <Heading fontSize="2xl">Create a custom activity</Heading>
                    </Flex>
                    <ButtonGroup w="100%" justifyContent="right">
                      <Link to="/create-template">
                        <Button colorScheme="blue">
                          Create activity template
                        </Button>
                      </Link>
                    </ButtonGroup>
                  </Flex>

                  <Text>
                    Create any activity you need to help clients with our simple
                    worksheet builder.
                  </Text>
                </Flex>
              </CardBody>
            </Card>
          </Flex>
        </Flex>
      </Section>
      <Section>
        <Flex direction="column" justify="center" align="center" p={0}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            maxW="52em"
            w="100%"
            py={6}
            px={{ base: 4, md: 8 }}
            borderRadius="md"
            background="white"
          >
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              align="flex-start"
              justify="space-between"
              w="100%"
              pb={4}
              gap={4}
            >
              <Flex align="center" justify="flex-start" w="100%">
                <Heading fontSize="2xl" fontWeight="bold" pr={4}>
                  Your activity templates
                </Heading>
                <Tooltip label="Activity templates that you've uploaded or bookmarked">
                  <IconButton
                    size="xs"
                    isRound
                    icon={<MdQuestionMark />}
                    aria-label={'your activity templates'}
                  ></IconButton>
                </Tooltip>
              </Flex>
            </Flex>
            <TemplateList
              isProviderList
              defaultListParams={{
                bookmarked: true,
                authored: true,
              }}
            />
          </Flex>
        </Flex>
      </Section>
    </BasePage>
  );
};

export default ProviderHome;
