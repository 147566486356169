import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import Pages from 'app/pages';
import Authenticated from 'app/components/auth/Authenticated';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Pages.Root />,
    errorElement: <Pages.Error />,
    children: [
      { path: '/', element: <Pages.Home /> },
      { path: '/pilot', element: <Navigate to="/" /> },
      { path: '/privacy', element: <Pages.PrivacyPolicy /> },
      { path: '/terms', element: <Pages.TermsOfService /> },
      { path: '/about', element: <Pages.About /> },
      { path: '/login', element: <Pages.Login /> },
      { path: '/signup', element: <Pages.SignUp /> },
      { path: '/forgot-password', element: <Pages.ForgotPassword /> },
      {
        path: '/account',
        element: (
          <Authenticated>
            <Pages.Account />
          </Authenticated>
        ),
      },
      {
        path: '/templates',
        element: <Pages.TemplateListPage />,
        children: [{ path: ':slug', element: <Pages.TemplatePreview /> }],
      },
      {
        path: '/guide',
        element: <Pages.Guide />,
      },
      {
        path: '/dashboard',
        element: (
          <Authenticated onlyProvider>
            <Pages.ProviderHome />
          </Authenticated>
        ),
      },
      {
        path: '/workbook',
        element: (
          <Authenticated onlyClient>
            <Pages.Workbook />
          </Authenticated>
        ),
      },
      {
        path: '/workbook/new/:templateSlug',
        element: <Pages.ActivityCreate />,
      },
      {
        path: '/workbook/activity/:id',
        element: (
          <Authenticated onlyClient>
            <Pages.ActivityEdit />
          </Authenticated>
        ),
      },
      {
        path: '/workbook/insights/:templateSlug',
        element: (
          <Authenticated onlyClient>
            <Pages.ActivityInsights />
          </Authenticated>
        ),
      },
      {
        path: '/workbook/note/:type',
        element: (
          <Authenticated onlyClient>
            <Pages.Note />
          </Authenticated>
        ),
      },
      {
        path: '/workbook/share/:shareToken',
        element: <Pages.ActivityShare />,
      },
      { path: '/sandbox', element: <Pages.Sandbox /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
