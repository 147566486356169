import { useEffect } from 'react';
import { create } from 'zustand';
import {
  Text,
  Button,
  Input,
  ButtonGroup,
  Flex,
  Spacer,
  useToast,
  Checkbox,
} from '@chakra-ui/react';

import Link from 'app/components/Link';
import { useAuthLogin, useAuthRegister } from 'app/data';
import useAuthStore, { AuthRoles } from 'app/stores/auth';

interface ClientSignUpFormStore {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  consent: boolean;
  ageConsent: boolean;

  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setConsent: (consent: boolean) => void;
  setAgeConsent: (ageConsent: boolean) => void;

  reset: () => void;
}

const defaultValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  consent: false,
  ageConsent: false,
};

const useSignUpFormStore = create<ClientSignUpFormStore>((set) => ({
  ...defaultValues,

  setEmail: (email: string) => set({ email }),
  setPassword: (password: string) => set({ password }),
  setFirstName: (firstName: string) => set({ firstName }),
  setLastName: (lastName: string) => set({ lastName }),
  setConsent: (consent: boolean) => set({ consent }),
  setAgeConsent: (ageConsent: boolean) => set({ ageConsent }),

  reset: () => set(defaultValues),
}));

interface ClientSignupFormProps {
  onClickLogin: () => void;
}

const ClientSignupForm = ({ onClickLogin }: ClientSignupFormProps) => {
  const setAuthState = useAuthStore((state) => state.setAuthState);

  const toast = useToast();
  const login = useAuthLogin({
    mutation: {
      onSuccess: (data) =>
        setAuthState(
          data.token,
          data.is_provider ? AuthRoles.PROVIDER : AuthRoles.CLIENT,
          data.expires
        ),
      onError: () =>
        toast({
          title: 'Error Logging In',
          description: 'Invalid credentials',
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        }),
    },
  });
  const register = useAuthRegister({
    mutation: {
      onSuccess: () => {
        login.mutate({
          data: { email: email.trim(), password, is_provider: false },
        });
        toast({
          title: 'Welcome to Outside Therapy!',
          description:
            'This is your space for therapeutic practice outside the session.',
          status: 'success',
          variant: 'subtle',
          isClosable: true,
          position: 'top',
        });
      },
      onError: (error) => {
        // XXX handle errors better
        console.error(error.message, error.response?.data);
        const message = error.response?.data?.message as string;
        toast({
          title: message || 'Error creating account',
          status: 'error',
          variant: 'subtle',
          isClosable: true,
          position: 'top',
        });
      },
    },
  });

  const onRegisterSubmit = () => {
    register.mutate({
      data: {
        // XXX camelcase?
        email: email.trim(),
        password,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        consent_to_terms_and_privacy_policy: consent,
        affirm_13_or_older: ageConsent,
      },
    });
  };

  const [
    email,
    setEmail,
    password,
    setPassword,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    consent,
    setConsent,
    ageConsent,
    setAgeConsent,

    reset,
  ] = useSignUpFormStore((state) => [
    state.email,
    state.setEmail,
    state.password,
    state.setPassword,
    state.firstName,
    state.setFirstName,
    state.lastName,
    state.setLastName,
    state.consent,
    state.setConsent,
    state.ageConsent,
    state.setAgeConsent,
    state.reset,
  ]);

  // XXX automatically clear form on mount
  useEffect(() => {
    reset();
  }, []);

  // XXX add actual validation
  const validated =
    email && password && firstName && lastName && consent && ageConsent;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onRegisterSubmit();
      }}
    >
      <Text mb={2} fontSize="sm">
        First Name
      </Text>
      <Input
        mb={4}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        tabIndex={1}
      />

      <Text mb={2} fontSize="sm">
        Last Name
      </Text>
      <Input
        mb={4}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        tabIndex={1}
      />
      <Text mb={2} fontSize="sm">
        Email
      </Text>
      <Input
        mb={4}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        tabIndex={1}
      />
      <Text mb={2} fontSize="sm">
        Password
      </Text>
      <Input
        mb={4}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        tabIndex={1}
      />
      <Flex direction="column">
        <Checkbox
          isChecked={consent}
          onChange={(e) => setConsent(e.target.checked)}
          tabIndex={1}
        >
          I agree to the{' '}
          <Link to="/terms">
            <Text as="span" color="blue.500">
              Terms of Service
            </Text>
          </Link>{' '}
          and{' '}
          <Link to="/privacy">
            <Text as="span" color="blue.500">
              Privacy Policy
            </Text>
            .
          </Link>
        </Checkbox>

        <Checkbox
          isChecked={ageConsent}
          onChange={(e) => setAgeConsent(e.target.checked)}
          tabIndex={1}
        >
          I am 13 years of age or older.
        </Checkbox>
      </Flex>
      <ButtonGroup pt={4} w="100%" gap={3}>
        <Button
          variant="link"
          onClick={onClickLogin}
          whiteSpace="break-spaces"
          size="sm"
        >
          Already a client? Log in
        </Button>
        <Spacer />
        <Button
          type="submit"
          isDisabled={!validated}
          isLoading={register.isPending}
          tabIndex={1}
        >
          Sign Up
        </Button>
      </ButtonGroup>
    </form>
  );
};

export default ClientSignupForm;
