import { useState } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';

import { MdBookmark, MdBookmarkBorder } from 'react-icons/md';

import { useTemplateBookmarkSet, Template, QueryKeys } from 'app/data';
import useAuthStore from 'app/stores/auth';
import { useQueryClient } from '@tanstack/react-query';

interface TemplateBookmarkProps {
  template: Template;
  size?: string;
}

export const TemplateBookmark = ({
  template,
  size = 'lg',
}: TemplateBookmarkProps) => {
  const [authenticated] = useAuthStore((state) => [!!state.token]);
  const [isBookmarked, setIsBookmarked] = useState(template.is_bookmarked);
  const queryClient = useQueryClient();

  const setBookmark = useTemplateBookmarkSet({
    mutation: {
      onSuccess: (data) => {
        setIsBookmarked(data.is_bookmarked);
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === QueryKeys.TemplateList()[0],
        });
      },
    },
  });

  const onBookmarkToggle = () => {
    if (!authenticated) {
      return;
    }

    setBookmark.mutate({
      id: template.id,
      data: { is_bookmarked: !isBookmarked },
    });
  };

  const tooltipLabel = authenticated
    ? isBookmarked
      ? 'Remove bookmark'
      : 'Bookmark this template'
    : 'Sign in to bookmark this template';

  return (
    <Tooltip label={tooltipLabel}>
      <IconButton
        isDisabled={!authenticated}
        size={size}
        variant="link"
        icon={
          isBookmarked ? (
            <MdBookmark size="1.5em" />
          ) : (
            <MdBookmarkBorder size="1.5em" />
          )
        }
        onClick={(e) => {
          e.preventDefault();
          onBookmarkToggle();
        }}
        aria-label={'Bookmark template'}
        colorScheme={isBookmarked ? 'yellow' : 'gray'}
      />
    </Tooltip>
  );
};
