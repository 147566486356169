import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import appConfig from 'app/config';
import useAuthStore from 'app/stores/auth';

export const axios = Axios.create({ baseURL: appConfig.api.baseUrl });

export const useCustomInstance = <T>(): ((
  config: AxiosRequestConfig
) => Promise<T>) => {
  return (config: AxiosRequestConfig) => {
    useAuthStore.getState().cleanupAuthState();
    const token = useAuthStore.getState().token;

    return axios({
      ...config,
      ...(token && {
        headers: {
          Authorization: `Bearer ${token}`,
          ...config.headers,
        },
      }),
    })
      .then(({ data }) => data)
      .catch((error) => {
        if (token && error?.response?.status === 401) {
          console.error(
            'Token invalid, clearing auth state:',
            error?.response?.data
          );
          useAuthStore.getState().clearAuthState();
        }

        throw error;
      });
  };
};

export type ErrorType<Error> = AxiosError<Error>;
