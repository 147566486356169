import { LegacyRef, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  VStack,
  Flex,
  Text,
  Button,
  ButtonGroup,
  Spinner,
  Input,
  Spacer,
  HStack,
  Card,
  CardBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Textarea,
  useToast,
  useDisclosure,
  UnorderedList,
  ListItem,
  IconButton,
  Tooltip,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  MdModeEdit,
  MdLink,
  MdSend,
  MdQuestionMark,
  MdOpenInNew,
  MdPerson,
} from 'react-icons/md';
import * as Analytics from 'app/analytics';
import { useTemplateGetBySlug } from 'app/data';
import Link from 'app/components/Link';
import MarkdownRenderer from 'app/components/MarkdownRenderer';
import { BlockComposer, BlockList } from 'app/components/Block';
import { TemplateCardTag } from 'app/components/TemplateCardTag';

interface PreviewActionButtonProps {
  link: string;
  flexToColumn?: boolean;
}

const SendActivityPopover = ({ link }: PreviewActionButtonProps) => {
  const emailFocusRef = useRef();
  const emailFocusRefAsVoidable = emailFocusRef as unknown as React.RefObject<{
    focus(): void;
  }>;
  const emailFocusRefAsLegacyHTMLRef =
    emailFocusRef as unknown as LegacyRef<HTMLInputElement>;
  const toast = useToast();
  const [displayDetails, setDisplayDetails] = useState('none');

  //TODO: add an ID or email for the inviter as a tracking param
  async function copyToClipboard() {
    await navigator.clipboard.writeText(`${window.location.origin}${link}`);
    toast({
      title: 'Copied link',
      status: 'success',
      variant: 'subtle',
      isClosable: true,
      position: 'top',
    });

    Analytics.onCopyLinkClick();
  }
  const copyLinkText = useBreakpointValue({
    base: 'Copy link',
    md: 'Copy link to send',
  });

  return (
    <Button
      size="md"
      colorScheme="blue"
      rightIcon={<MdLink />}
      onClick={copyToClipboard}
    >
      {copyLinkText}
    </Button>
  );

  //revert to this when we have email infra
  return (
    <Popover arrowShadowColor="black" initialFocusRef={emailFocusRefAsVoidable}>
      <PopoverTrigger>
        <Button size="md" colorScheme="blue" rightIcon={<MdSend />}>
          Send
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w={{ base: '300px', md: '480px' }}
        borderColor="black"
        p={2}
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack w="100%" spacing={3}>
            <HStack justify="left" align="center" w="100%" gap={4}>
              <Text fontWeight="semibold" fontSize="lg">
                Send activity securely
              </Text>
              <Tooltip label="Learn more">
                <IconButton
                  size="xs"
                  isRound
                  icon={<MdQuestionMark />}
                  aria-label={'learn more'}
                  onClick={() => setDisplayDetails('inline')}
                ></IconButton>
              </Tooltip>
            </HStack>
            <UnorderedList
              w="100%"
              fontSize="sm"
              pl={2}
              display={displayDetails}
            >
              <ListItem>Designed to not require HIPAA in version 1</ListItem>
              <ListItem>
                No client login or data storage required to complete activity
              </ListItem>
              <ListItem>Client data is never shared with therapists</ListItem>
              <ListItem>
                See our full privacy and data policy for more info
                <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                  <IconButton
                    size="md"
                    variant="link"
                    color="gray.700"
                    icon={<MdOpenInNew />}
                    aria-label={'Open Privacy Policy'}
                  />
                </Link>
              </ListItem>
            </UnorderedList>
            <Input
              mt={4}
              placeholder="Recipient email"
              ref={emailFocusRefAsLegacyHTMLRef}
            ></Input>
            <Textarea placeholder="Add a message (optional)"></Textarea>
            <ButtonGroup w="100%" justifyContent="flex-end">
              <Button
                colorScheme="blue"
                variant="link"
                leftIcon={<MdLink />}
                onClick={copyToClipboard}
              >
                Copy Link
              </Button>
              <Spacer />
              <Button colorScheme="blue">Send</Button>
            </ButtonGroup>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const PreviewActionButtons = ({
  link,
  flexToColumn = false,
}: PreviewActionButtonProps) => {
  return (
    <ButtonGroup
      spacing={0}
      gap={2}
      flexDirection={{ base: flexToColumn ? 'column' : 'row', sm: 'row' }}
    >
      <Link to={link}>
        <Button size="md" rightIcon={<MdModeEdit />}>
          Start
        </Button>
      </Link>
      <SendActivityPopover link={link} />
    </ButtonGroup>
  );
};

const TemplatePreview = () => {
  const navigate = useNavigate();

  const params = useParams<{ slug: string }>();
  const {
    isPending,
    data: template,
    error,
  } = useTemplateGetBySlug(params.slug!);

  const [technicalDetailsExpanded, setTechnicalDetailsExpanded] =
    useState(false);
  const [numLinesTechnicalDescription, setNumLinesTechnicalDescription] =
    useState(5);
  const [expandButtonName, setButtonName] = useState('read more');

  function expandTechnicalDetails() {
    //TODO: animate and truncate in a more systematic way
    if (technicalDetailsExpanded) {
      setNumLinesTechnicalDescription(5);
      setButtonName('read more');
    } else {
      setNumLinesTechnicalDescription(-1);
      setButtonName('read less');
    }
    setTechnicalDetailsExpanded(!technicalDetailsExpanded);
  }

  const { state: locationState } = useLocation();
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () =>
      navigate(locationState?.isProviderTemplate ? '/dashboard' : '/templates'),
  });
  const activityPath = `/workbook/new/${params.slug!}`;

  return (
    //TODO: preserve scroll when modal opens/closes
    //see discussion in https://github.com/outside-therapy/app/pull/17#discussion_r1440753291
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack
            spacing={8}
            w="100%"
            p={{ base: 0, md: 6 }}
            pt={{ base: 8, md: 8 }}
          >
            {isPending ? (
              <Flex align="center" justify="center" w="100%">
                <Spinner size="lg" />
              </Flex>
            ) : error ? (
              <Flex align="center" justify="center" w="100%">
                <Text>There was an error loading the template.</Text>
              </Flex>
            ) : (
              <>
                <VStack w="100%" spacing={2}>
                  <Flex w="100%" alignItems="center">
                    <Text
                      fontSize="4xl"
                      fontWeight="bold"
                      fontFamily="DM Serif Text, serif"
                    >
                      {template.title}
                    </Text>
                  </Flex>
                  <Flex justify="left" w="100%">
                    <HStack spacing="8px" justify="left" align="center">
                      {template.tags.map((tag: string, idx: number) => (
                        <TemplateCardTag key={idx} name={tag} type="tag" />
                      ))}
                      {<TemplateCardTag name={template.type} type="type" />}
                      {!template.is_public && (
                        <Tooltip label="Private template">
                          <span>
                            <Icon as={MdPerson} />
                          </span>
                        </Tooltip>
                      )}
                    </HStack>
                  </Flex>
                </VStack>
                <VStack w="100%" spacing={4}>
                  <Flex w="100%" alignItems="center" gap="2">
                    <Flex
                      justify="left"
                      w="100%"
                      mt={2}
                      gap={4}
                      align={{ base: 'left', sm: 'center' }}
                    >
                      <Text fontSize="2xl" fontWeight="bold">
                        Activity Preview
                      </Text>
                      <Text
                        fontSize="sm"
                        color="gray.700"
                        display={{ base: 'none', md: 'inline' }}
                      >
                        (Client view)
                      </Text>
                    </Flex>
                    <Spacer />
                    <PreviewActionButtons
                      link={activityPath}
                      flexToColumn={true}
                    />
                  </Flex>

                  <Card p={0} mb={8} w="100%" variant="filled">
                    <Flex
                      h="100%"
                      w="100%"
                      style={{
                        position: 'absolute',
                      }}
                      zIndex="docked"
                      background="black"
                      opacity="0"
                      borderRadius={5}
                      _hover={{
                        opacity: '0.1',
                      }}
                    ></Flex>
                    <CardBody p={{ base: 5, md: 8 }} w="100%">
                      <Flex w="100%" gap="2">
                        <Text
                          fontSize="3xl"
                          fontWeight="bold"
                          fontFamily="DM Serif Text, serif"
                        >
                          {template.title}
                        </Text>
                      </Flex>
                      <Text fontSize="md" mb={4} mt={2}>
                        {template.description}
                      </Text>
                      <BlockComposer blocks={template.blocks} disabled>
                        <BlockList />
                      </BlockComposer>
                    </CardBody>
                  </Card>
                  <VStack w="100%" spacing={4}>
                    <Flex w="100%" alignItems="center" gap="2">
                      <Text fontSize="2xl" fontWeight="bold">
                        Activity Details
                      </Text>
                      <Spacer />
                    </Flex>
                    {template.technical_description ? (
                      <VStack justify="left" w="100%" align="left">
                        <Flex noOfLines={numLinesTechnicalDescription}>
                          <MarkdownRenderer>
                            {template.technical_description || ''}
                          </MarkdownRenderer>
                        </Flex>
                        {/* TODO: display button only if there is an available expansion */}
                        <ButtonGroup w="100%">
                          <Button
                            variant="link"
                            size="sm"
                            colorScheme="blue"
                            onClick={expandTechnicalDetails}
                          >
                            {expandButtonName}
                          </Button>
                        </ButtonGroup>
                      </VStack>
                    ) : (
                      <Text fontSize="md" w="100%">
                        This template has no detailed description.
                      </Text>
                    )}
                  </VStack>
                  <Flex w="100%" justify="right">
                    <PreviewActionButtons
                      link={activityPath}
                      flexToColumn={false}
                    />
                  </Flex>
                </VStack>
              </>
            )}
            ;
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TemplatePreview;
