import { useNavigate } from 'react-router-dom';
import {
  VStack,
  Flex,
  Button,
  ButtonGroup,
  Divider,
  Spacer,
  HStack,
  Card,
  CardBody,
  useToast,
  Input,
  Textarea,
  Box,
  Alert,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import {
  TemplateType,
  useTemplateCreate,
  useTemplateGetCategories,
} from 'app/data';

import { useState } from 'react';
import Editor from 'app/components/Editor';
import {
  MultiValue,
  Select,
  SingleValue,
  CreatableSelect,
} from 'chakra-react-select';
import { ReadingBlock } from 'app/data/api.schemas';

interface EditableMarkdownBlockParams {
  heading: string;
  setHeading: (heading: string) => void;
  content: string;
  setContent: (content: string) => void;
}

const EditableMarkdownBlock = ({
  heading,
  setHeading,
  content,
  setContent,
}: EditableMarkdownBlockParams) => {
  return (
    <>
      <Input
        placeholder="Heading"
        size="md"
        width="100%"
        fontWeight="semibold"
        value={heading}
        maxLength={64}
        onChange={(e) => setHeading(e.target.value)}
        mb={2}
      />
      <Box width="100%">
        <Editor
          initialValue={content || ''}
          placeholder="Type or paste content here. Rich text formatting is available!"
          onChangeEnd={(editorContent) => setContent(editorContent)}
        />
      </Box>
    </>
  );
};

const ActivityTemplateBuilder = () => {
  const { data: categories } = useTemplateGetCategories();
  const typeOptions = categories?.types.map((label) => ({
    label,
    value: label.toLowerCase(),
  }));
  const tagOptions = categories?.tags.map((label) => ({
    label,
    value: label.toLowerCase(),
  }));

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [tags, setTags] = useState<Array<string>>([]);
  const [heading, setHeading] = useState('');
  const [content, setContent] = useState('');

  const enableSave = title && description && type && tags && heading && content;

  const toast = useToast();
  const navigate = useNavigate();
  const create = useTemplateCreate({
    mutation: {
      onSuccess: (data) => {
        toast({
          title: 'Activity Template Saved',
          description:
            'Your template is ready to send. Find it on your dashboard or copy this link from your browser',
          status: 'success',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });

        navigate(`/workbook/new/${data.slug}`);
      },
      onError: (error) => {
        toast({
          title: 'Error Saving Template',
          description: error.response?.data.message || 'An error occurred',
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      },
    },
  });

  const onSelectedTypeChange = (
    selected: SingleValue<{ label: string; value: string }>
  ) => {
    selected && selected.label in TemplateType && setType(selected.label);
  };

  const onSelectedTagChange = (
    selected: MultiValue<{ label: string; value: string }>
  ) => {
    const flattened = selected.map(({ label }) => label);
    setTags(flattened);
  };

  const onSave = () => {
    const readingBlock = {
      content: content,
      heading: heading,
      id: 'reading-block',
      type: 'reading',
    };

    create.mutate({
      data: {
        blocks: [readingBlock as ReadingBlock],
        description: description,
        tags: tags,
        title: title,
        type: type as TemplateType,
      },
    });
  };

  return (
    <>
      <VStack align="start" spacing={4} w="100%">
        <Alert status="info">
          Worksheet builder is a work in progress - more advanced
          configurability coming soon!
        </Alert>
        <Flex w="100%" gap="2">
          <Input
            placeholder="Title"
            size="lg"
            maxW="100%"
            fontSize="4xl"
            noOfLines={1}
            fontWeight="bold"
            fontFamily="DM Serif Text, serif"
            value={title}
            maxLength={64}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>
        <Flex w="100%" gap="1" align="center">
          <VStack justify="left" spacing={3}>
            <HStack spacing={2} justify="left" w="100%">
              <Select
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                options={typeOptions}
                placeholder="Select type"
                closeMenuOnSelect={false}
                size="sm"
                onChange={onSelectedTypeChange}
              />
              <CreatableSelect
                isMulti
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                options={tagOptions}
                placeholder="Select tags or type in a new tag"
                closeMenuOnSelect={false}
                size="sm"
                onChange={onSelectedTagChange}
              />
            </HStack>
          </VStack>
          <Spacer />
          <ButtonGroup>
            <Button
              size="md"
              colorScheme="blue"
              onClick={onSave}
              isDisabled={!enableSave}
            >
              Save Template
            </Button>
          </ButtonGroup>
        </Flex>
        <Divider width="100%" mt={2} mb={6} />
        <Card variant="filled" w="100%">
          <CardBody>
            <Textarea
              borderColor="gray.400"
              fontSize="md"
              placeholder="1-2 sentence description of the activity for your client"
              resize="vertical"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </CardBody>
        </Card>
        <Flex direction="column" align="start" justify="center" w="100%" mt={2}>
          {/* We probably need a new block list type that renders editable blocks */}
          <EditableMarkdownBlock
            heading={heading}
            setHeading={setHeading}
            content={content}
            setContent={setContent}
          />
        </Flex>
      </VStack>
    </>
  );
};

const TemplateCreate = () => {
  return (
    <Page title={'New activity template'}>
      <ActivityTemplateBuilder />
    </Page>
  );
};

export default TemplateCreate;
