import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import appConfig from 'app/config';
import * as Sentry from '@sentry/react';
import * as analytics from 'app/analytics';

analytics.initialize();

if (appConfig.sentry.enabled) {
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', appConfig.api.baseUrl],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Feedback({
        autoInject: false,

        colorScheme: 'light',
        showBranding: false,

        showName: false,
        showEmail: true,
        isEmailRequired: false,

        formTitle: 'Send Feedback',
        submitButtonLabel: 'Send Feedback',
        emailLabel: 'Email (optional)',
        messagePlaceholder: 'Please describe your issue or feedback in detail',
        successMessageText: 'Thank you for your feedback!',
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

import theme from 'app/theme';
import App from 'app/App';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>Outside Therapy</title>
      </Helmet>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ChakraProvider>
    </HelmetProvider>
  </StrictMode>
);
