import { useState } from 'react';
import { Text, Button, Flex, useDisclosure } from '@chakra-ui/react';

import AuthModal from './AuthModal';
import TabbedLogin from './TabbedLogin';
import TabbedSignup from './TabbedSignup';

export interface InplaceLoginSignupButtonProps {
  children?: string;
}

const InplaceLoginSignupButton = ({
  children,
}: InplaceLoginSignupButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showSignup, setShowSignup] = useState(true);
  const toggleModal = () => setShowSignup(!showSignup);
  const [isProvider, setIsProvider] = useState(false);

  return (
    <Flex onClick={onOpen}>
      <AuthModal isOpen={isOpen} onClose={onClose}>
        {showSignup ? (
          <>
            <Text
              fontSize="4xl"
              fontWeight="bold"
              fontFamily="DM Serif Text, serif"
              my={4}
            >
              Sign Up
            </Text>
            <TabbedSignup
              isProvider={isProvider}
              onClickTab={setIsProvider}
              onClickLogin={toggleModal}
            />
          </>
        ) : (
          <>
            <Text
              fontSize="4xl"
              fontWeight="bold"
              fontFamily="DM Serif Text, serif"
              my={4}
            >
              Login
            </Text>
            <TabbedLogin
              isProvider={isProvider}
              onClickTab={setIsProvider}
              onClickSignup={toggleModal}
            />
          </>
        )}
      </AuthModal>
      <Button size="md" colorScheme="blue">
        {children}
      </Button>
    </Flex>
  );
};

export default InplaceLoginSignupButton;
