import {
  Container,
  Flex,
  Text,
  Heading,
  VStack,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import Link from 'app/components/Link';
import { BasePage } from 'app/pages/Page';

const About = () => {
  return (
    <BasePage title="About Us">
      <Flex w="100%" justify="center" align="center">
        <Container m={4} maxW="56em">
          <VStack pt={4} gap={8} align="start">
            <VStack py={6} gap={4} align="start">
              <Heading
                size="xl"
                fontWeight="normal"
                lineHeight={1.25}
                fontFamily="DM Serif Text, serif"
              >
                Our mission is to help therapists and clients make more progress
                in therapy.
              </Heading>
            </VStack>
            <VStack pt={4} gap={4} align="start">
              <Heading size="md" fontWeight="semibold">
                Digital tools haven’t focused on the therapist-client
                relationship enough.
              </Heading>
              <Text>
                Over the last 5 years, billions of dollars of investment have
                poured into mental health companies focused on building
                therapist networks, managing insurance, and automating billing,
                but few companies have innovated on one of the most important
                factors that predicts client outcomes:{' '}
                <Link.WithoutRouter
                  fontWeight="semibold"
                  color="teal.600"
                  isExternal
                  href="https://onlinelibrary.wiley.com/doi/10.1002/wps.20238"
                >
                  the therapist-client relationship
                </Link.WithoutRouter>
                . We belive the technology should build on therapists{"'"} skill
                and empathy by acting as an extension of this relationship. For
                instance:
              </Text>
              <OrderedList spacing={4}>
                <ListItem>
                  <b>
                    The therapeutic process should seamlessly continue between
                    sessions, without extra work from therapists.
                  </b>{' '}
                  Dozens of studies show that psychoeducation and therapeutic
                  exercises{' '}
                  <Link.WithoutRouter
                    fontWeight="semibold"
                    color="teal.600"
                    isExternal
                    href="https://psycnet.apa.org/doiLanding?doi=10.1111%2Fj.1468-2850.2010.01204.x"
                  >
                    improve client outcomes
                  </Link.WithoutRouter>{' '}
                  and{' '}
                  <Link.WithoutRouter
                    fontWeight="semibold"
                    color="teal.600"
                    isExternal
                    href="https://www.sciencedirect.com/science/article/abs/pii/S0005791615300069"
                  >
                    reduce client dropout
                  </Link.WithoutRouter>
                  . However, the current standard of emailing PDFs and printing
                  worksheets makes it challenging to execute on exercises
                  between sessions, with up to{' '}
                  <Link.WithoutRouter
                    fontWeight="semibold"
                    color="teal.600"
                    isExternal
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5481663/#:~:text=Surveys%20of%20practitioners%20have%20suggested%20rates%20of%20non%2Dadherence%20in%20adult%20clients%20of%20approximately%2020%25%20to%2050%25"
                  >
                    50% of clients not completing activities.
                  </Link.WithoutRouter>
                </ListItem>
                <ListItem>
                  <b>
                    Technology should help clients stay engaged and accountable
                    for their journey
                  </b>{' '}
                  with simple nudges and a calm and beautiful experience. We’ve
                  spoken to countless clients who say that they show up to
                  therapy sessions having forgotten what happened in their last
                  session. Others clients want to refer back to what they
                  discussed with their therapist, only to realize they didn’t
                  take notes or that their notes lacked detail. Digital tools
                  can help clients track their therapy goals, progress, and
                  lessons learned so they can pick up where they left off each
                  session.
                </ListItem>
              </OrderedList>
            </VStack>
            <VStack pt={4} gap={4} align="start">
              <Heading size="md" fontWeight="semibold" lineHeight={1.25}>
                We are building a simple, beautiful, and affordable digital tool
                for therapeutic practice, client note taking, and
                between-session engagement.
              </Heading>
            </VStack>
            <VStack pt={4} gap={4} align="start">
              <Heading size="md" fontWeight="semibold">
                About Us
              </Heading>

              <Text>
                We are technologists with a decade of experience building
                software products in healthcare. We were inspired to build
                Outside Therapy based on our own personal experiences with
                therapy. Outside Therapy is a bootstrapped company driven by
                impact rather than investor pressure to grow the bottom line.
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Flex>
    </BasePage>
  );
};

export default About;
