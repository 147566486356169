import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Home } from './Home';

import Login from './Login';
import SignUp from './SignUp';
import Account from './Account';
import ForgotPassword from './ForgotPassword';

import { TermsOfService, PrivacyPolicy } from './LegalDocument';
import { About } from './About';

import TemplateListPage from './TemplateList';
import TemplatePreview from './TemplatePreview';
import TemplateCreate from './TemplateCreate';
import Guide from './Guide';
import ProviderHome from './Provider';
import Workbook from './Workbook';
import ActivityCreate from './ActivityCreate';
import ActivityEdit from './ActivityEdit';
import ActivityShare from './ActivityShare';
import ActivityInsights from './ActivityInsights';
import Note from './Note';
import Sandbox from './Sandbox';

import ErrorPage from './Error';

const Root = () => (
  <>
    <Outlet />
    <ScrollRestoration />
  </>
);

export default {
  Root,
  Home,
  TermsOfService,
  PrivacyPolicy,
  About,
  Login,
  SignUp,
  Account,
  ForgotPassword,
  TemplateListPage,
  TemplatePreview,
  TemplateCreate,
  Guide,
  ProviderHome,
  Workbook,
  ActivityCreate,
  ActivityEdit,
  ActivityShare,
  ActivityInsights,
  Note,
  Sandbox,
  Error: ErrorPage,
};
