import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import {
  format,
  roundToNearestHours,
  interval,
  intervalToDuration,
  formatDuration,
} from 'date-fns';
import {
  Flex,
  Text,
  Spinner,
  Spacer,
  VStack,
  ButtonGroup,
  Button,
  HStack,
  Divider,
  Card,
  CardBody,
  IconButton,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import {
  BlockComposer,
  BlockList,
  useBlockContext,
} from 'app/components/Block';
import {
  Activity,
  useActivityGet,
  useActivityGetShareLink,
  useActivityUpdate,
  QueryKeys,
} from 'app/data';
import Link from 'app/components/Link';
import { TemplateCardTag } from 'app/components/TemplateCardTag';
import { MdDelete, MdModeEdit, MdShare } from 'react-icons/md';
import { DownloadButton } from 'app/components/DownloadButton';

interface ActivityEditorProps {
  activity: Activity;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  onDiscard: () => void;
}

const ActivityEditor = ({
  activity,
  editing,
  setEditing,
  onDiscard,
}: ActivityEditorProps) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const [initialize, values] = useBlockContext((state) => [
    state.initialize,
    state.values,
  ]);

  // NOTE: we can't rely on the template type to determine if there are inputs
  const isEditable = !isEmpty(activity.content.values);

  useEffect(() => {
    initialize(activity.content.template.blocks, activity.content.values);
  }, []);

  const toast = useToast();
  const handleDiscard = () => {
    // XXX implement a better editor reset funtion
    onDiscard();
    setEditing(false);
    toast({
      title: 'Discarded changes',
      variant: 'subtle',
      position: 'top',
      isClosable: true,
    });
  };

  const queryClient = useQueryClient();
  const update = useActivityUpdate({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Activity Saved',
          status: 'success',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });

        queryClient.invalidateQueries({
          queryKey: QueryKeys.ActivityGet(activity.id),
        });
      },
    },
  });

  const share = useActivityGetShareLink();
  const handleShare = async () => {
    if (!activity) return;

    const { link, expires_at } = await share.mutateAsync({ id: activity.id });
    const validDuration = intervalToDuration(
      interval(
        roundToNearestHours(new Date()),
        roundToNearestHours(new Date(expires_at))
      )
    );
    const validUntil = formatDuration(validDuration, {
      format: ['days', 'hours'],
    });

    navigator.clipboard.writeText(link);
    toast({
      title: `Copied temporary link. It will be active for ${validUntil}.`,
      variant: 'subtle',
      position: 'top',
      isClosable: true,
    });
  };

  const handleSave = () => {
    update.mutate({
      id: activity.id,
      data: { content: { template: activity.content.template, values } },
    });

    setEditing(false);
  };

  return (
    <VStack align="start" spacing={4} w="100%" ref={downloadRef}>
      <Flex w="100%" gap="2">
        <Text
          fontSize="4xl"
          fontWeight="bold"
          fontFamily="DM Serif Text, serif"
        >
          {activity.content.template.title}
        </Text>
      </Flex>
      <Flex w="100%" gap="2" align="flex-end" direction="row">
        <VStack alignItems="left" justify="left" spacing={3}>
          <HStack spacing={2} align="left" justify="left" w="100%">
            {activity.content.template.tags.map((tag: string, idx: number) => (
              <TemplateCardTag key={idx} name={tag} type="tag" />
            ))}
            {
              <TemplateCardTag
                name={activity.content.template.type}
                type="type"
              />
            }
          </HStack>
          <Text fontSize="sm">
            Created: {format(activity.created_at, 'M/d/yy h:mm a')}
          </Text>
          <Text fontSize="sm">
            Last update: {format(activity.updated_at, 'M/d/yy h:mm a')}
          </Text>
        </VStack>
        <Spacer />
        <Flex gap={2} direction={{ base: 'column', sm: 'row' }}>
          <ButtonGroup size="md" justifyContent="end">
            {editing ? (
              <Tooltip label="Discard updates">
                <IconButton
                  variant="outline"
                  fontSize="xl"
                  onClick={handleDiscard}
                  icon={<MdDelete />}
                  aria-label={'Discard updates'}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip label="Share via temporary link">
                  <IconButton
                    variant="outline"
                    fontSize="xl"
                    aria-label={'Share'}
                    icon={<MdShare />}
                    onClick={handleShare}
                  />
                </Tooltip>
                <DownloadButton
                  downloadRef={downloadRef}
                  downloadItemSlug={activity.content.template.slug}
                />
                {isEditable && (
                  <Tooltip label="Edit">
                    <IconButton
                      variant="outline"
                      fontSize="xl"
                      aria-label={'Edit'}
                      icon={<MdModeEdit />}
                      onClick={() => setEditing(true)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </ButtonGroup>
          <ButtonGroup size="md">
            {editing ? (
              <Button colorScheme="blue" onClick={handleSave}>
                Save
              </Button>
            ) : (
              isEditable && (
                <Tooltip label="Repeat this activity">
                  <span>
                    <Link
                      to={`/workbook/new/${activity.content.template.slug}`}
                    >
                      <Button colorScheme="blue">Repeat</Button>
                    </Link>
                  </span>
                </Tooltip>
              )
            )}
          </ButtonGroup>
        </Flex>
      </Flex>
      <Divider width="100%" mt={2} mb={6} />
      <Card variant="filled" w="100%">
        <CardBody>
          <Text fontSize="md">{activity.content.template.description}</Text>
        </CardBody>
      </Card>
      <Flex direction="column" align="start" justify="center" w="100%">
        <BlockList />
      </Flex>
    </VStack>
  );
};

const ActivityEdit = () => {
  const params = useParams<{ id: string }>();
  const { isPending, data: activity, error } = useActivityGet(params.id!);
  const [loaded, setLoaded] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (activity && !loaded) {
      setLoaded(true);
    }
  }, [activity, loaded]);

  return (
    <Page
      title={
        isPending || error ? 'Edit activity' : activity.content.template.title
      }
    >
      {isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : error ? (
        <Flex align="center" justify="center" w="100%">
          <Text>There was an error loading the activity.</Text>
        </Flex>
      ) : (
        loaded && (
          <BlockComposer disabled={!editing}>
            <ActivityEditor
              activity={activity}
              editing={editing}
              setEditing={setEditing}
              onDiscard={() => setLoaded(false)}
            />
          </BlockComposer>
        )
      )}
    </Page>
  );
};

export default ActivityEdit;
