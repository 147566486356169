import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Flex,
  Text,
  Spinner,
  VStack,
  HStack,
  Divider,
  Card,
  CardBody,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import { BlockComposer, BlockList } from 'app/components/Block';
import { useActivityViewSharedLink } from 'app/data';
import { TemplateCardTag } from 'app/components/TemplateCardTag';

const ActivityShare = () => {
  const params = useParams<{ shareToken: string }>();
  const {
    isPending,
    data: activity,
    error,
  } = useActivityViewSharedLink(params.shareToken!);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (activity && !loaded) {
      setLoaded(true);
    }
  }, [activity, loaded]);

  return (
    <Page
      title={
        isPending || error ? 'Edit activity' : activity.content.template.title
      }
    >
      {isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : error ? (
        <Flex align="center" justify="center" w="100%">
          <Text>
            There was an error loading the activity. This temporary link may no
            longer be valid.
          </Text>
        </Flex>
      ) : (
        loaded && (
          <BlockComposer
            disabled
            blocks={activity.content.template.blocks}
            values={activity.content.values}
          >
            <VStack align="start" spacing={4} w="100%">
              <Alert status="info" w="100%" m={0}>
                <AlertIcon />
                <Box w="100%">
                  <AlertTitle fontSize="sm" mr={2}>
                    This is a view-only activity link shared with you by the
                    author.
                  </AlertTitle>
                  <AlertDescription>
                    <Text fontSize="sm">
                      Please be mindful that this activity may contain sensitive
                      information. Do not share this link with others. This link
                      will expire 7 days from the time it was created.
                    </Text>
                  </AlertDescription>
                </Box>
              </Alert>
              <Flex w="100%" gap="2">
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  fontFamily="DM Serif Text, serif"
                >
                  {activity.content.template.title}
                </Text>
              </Flex>
              <Flex w="100%" gap="2" align="flex-end" direction="row">
                <VStack alignItems="left" justify="left" spacing={3}>
                  <HStack spacing={2} align="left" justify="left" w="100%">
                    {activity.content.template.tags.map(
                      (tag: string, idx: number) => (
                        <TemplateCardTag key={idx} name={tag} type="tag" />
                      )
                    )}
                    {
                      <TemplateCardTag
                        name={activity.content.template.type}
                        type="type"
                      />
                    }
                  </HStack>
                  <Text fontSize="sm">
                    Created: {format(activity.created_at, 'M/d/yy h:mm a')}
                  </Text>
                  <Text fontSize="sm">
                    Last update: {format(activity.updated_at, 'M/d/yy h:mm a')}
                  </Text>
                </VStack>
              </Flex>
              <Divider width="100%" mt={2} mb={6} />
              <Card variant="filled" w="100%">
                <CardBody>
                  <Text fontSize="md">
                    {activity.content.template.description}
                  </Text>
                </CardBody>
              </Card>
              <Flex direction="column" align="start" justify="center" w="100%">
                <BlockList />
              </Flex>
            </VStack>
          </BlockComposer>
        )
      )}
    </Page>
  );
};

export default ActivityShare;
