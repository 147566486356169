import useAuthStore, { AuthRoles } from 'app/stores/auth';

interface BaseRoleProps {
  children: React.ReactNode;
}

interface RoleProps extends BaseRoleProps {
  allowedRoles: Array<(typeof AuthRoles)[keyof typeof AuthRoles] | null>;
}

const Role = ({ allowedRoles, children }: RoleProps) => {
  const [authenticated, role] = useAuthStore((state) => [
    !!state.token,
    state.role,
  ]);

  if (!authenticated || !allowedRoles.includes(role)) {
    return null;
  }

  return <>{children}</>;
};

const ProviderRole = ({ children }: BaseRoleProps) => (
  <Role allowedRoles={[AuthRoles.PROVIDER]}>{children}</Role>
);

const DisallowProviderRole = ({ children }: BaseRoleProps) => (
  <Role allowedRoles={[AuthRoles.CLIENT, null]}>{children}</Role>
);

const ClientRole = ({ children }: BaseRoleProps) => (
  <Role allowedRoles={[AuthRoles.CLIENT]}>{children}</Role>
);

const DisallowClientRole = ({ children }: BaseRoleProps) => (
  <Role allowedRoles={[AuthRoles.PROVIDER, null]}>{children}</Role>
);

Role.Provider = ProviderRole;
Role.DisallowProvider = DisallowProviderRole;
Role.Client = ClientRole;
Role.DisallowClient = DisallowClientRole;

export default Role;
