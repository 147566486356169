import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, Text, useToast, VStack, Badge, Spinner } from '@chakra-ui/react';

import {
  useAccountProfileGet,
  useAccountEmailVerifyToken,
  QueryKeys,
} from 'app/data';

export const EmailForm = () => {
  const queryClient = useQueryClient();
  const { data: accountProfile } = useAccountProfileGet();
  const emailVerified = accountProfile?.email_verified;

  const [searchParams, setSearchParams] = useSearchParams();
  const emailToken = searchParams.get('email_token') || null;

  const toast = useToast();
  const navigate = useNavigate();

  const verifyToken = useAccountEmailVerifyToken({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Email verified',
          description: 'Your email has been verified.',
          status: 'success',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });

        searchParams.delete('email_token');
        setSearchParams(searchParams);

        queryClient.invalidateQueries({
          queryKey: QueryKeys.AccountProfileGet,
        });

        navigate('/workbook');
      },
      onError: (error) => {
        toast({
          title: 'Error verifying email',
          description: error.response?.data?.message || undefined,
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      },
    },
  });

  const verifying = verifyToken.isPending;

  useEffect(() => {
    if (emailToken && accountProfile && !emailVerified) {
      verifyToken.mutate({ data: { token: emailToken } });
    }
  }, [emailToken, accountProfile, emailVerified]);

  return (
    <VStack align="left" px={3} spacing={0} w="100%">
      <Text mb={4} fontSize="xl" fontFamily="DM Serif Text, serif">
        Email
      </Text>

      {!emailVerified && (
        <Text mb={2} fontSize="sm">
          Please check your email for a verification link.
        </Text>
      )}

      <Flex
        w="100%"
        mb={4}
        align="center"
        justify="flex-start"
        wrap="wrap"
        gap={2}
      >
        <Text>{accountProfile?.email || ''}</Text>
        {!verifying && accountProfile ? (
          <Badge colorScheme={emailVerified ? 'green' : 'orange'}>
            {emailVerified ? 'Verified' : 'Unverified'}
          </Badge>
        ) : (
          <Spinner size="xs" />
        )}
      </Flex>
    </VStack>
  );
};

export default EmailForm;
