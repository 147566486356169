import {
  LegalDocument,
  LegalDocumentType,
  AccountProfile,
  Template,
  TemplateCreate,
  TemplateType,
  TemplateListParams,
  Activity,
  Block,
  ReadingBlock,
  ImageBlock,
  VideoBlock,
  TextInputBlock,
  ShortTextInputBlock,
  ScaleBlock,
  SelectBlock,
  NumberBlock,
  TimeBlock,
  DateTimeBlock,
  GroupBlock,
  MultiEntryBlock,
  Note,
  NoteType,
} from 'app/data/api.schemas';
import {
  useLegalDocumentGetByType,
  useAuthRegister,
  useAuthRegisterProvider,
  useAuthLogin,
  useAuthPasswordUpdate,
  useAuthPasswordEmailLink,
  useAuthPasswordVerifyToken,
  useAuthPasswordReset,
  useAccountProfileGet,
  getAccountProfileGetQueryKey,
  useAccountProfileUpdate,
  useAccountEmailVerifyToken,
  useAuthLogout,
  useTemplateList,
  getTemplateListQueryKey,
  useTemplateGet,
  useTemplateGetBySlug,
  useTemplateCreate,
  useTemplateGetCategories,
  useTemplateBookmarkSet,
  useActivityList,
  useActivityListBySlug,
  useActivityGet,
  useActivityGetShareLink,
  useActivityViewSharedLink,
  getActivityGetQueryKey,
  useActivityCreate,
  useActivityUpdate,
  useNoteGetByType,
  getNoteGetByTypeQueryKey,
  useNoteUpdateByType,
  useFileUpload,
  useFileListPendingByType,
  getFileListPendingByTypeQueryKey,
  useListEmailSubmit,
} from 'app/data/api';

const QueryKeys = {
  AccountProfileGet: getAccountProfileGetQueryKey(),
  NoteGetByType: getNoteGetByTypeQueryKey,
  ActivityGet: getActivityGetQueryKey,
  TemplateList: getTemplateListQueryKey,
  FileListPendingByType: getFileListPendingByTypeQueryKey,
} as const;

export type {
  LegalDocument,
  Template,
  TemplateCreate,
  TemplateListParams,
  AccountProfile,
  Activity,
  Block,
  ReadingBlock,
  ImageBlock,
  VideoBlock,
  TextInputBlock,
  ShortTextInputBlock,
  ScaleBlock,
  SelectBlock,
  NumberBlock,
  TimeBlock,
  DateTimeBlock,
  GroupBlock,
  MultiEntryBlock,
  Note,
};
export {
  LegalDocumentType,
  useLegalDocumentGetByType,
  useAuthRegister,
  useAuthRegisterProvider,
  useAuthLogin,
  useAuthPasswordUpdate,
  useAuthPasswordEmailLink,
  useAuthPasswordVerifyToken,
  useAuthPasswordReset,
  useAccountProfileGet,
  useAccountProfileUpdate,
  useAccountEmailVerifyToken,
  useAuthLogout,
  TemplateType,
  useTemplateList,
  useTemplateGet,
  useTemplateGetBySlug,
  useTemplateCreate,
  useTemplateGetCategories,
  useTemplateBookmarkSet,
  useActivityList,
  useActivityListBySlug,
  useActivityGet,
  useActivityGetShareLink,
  useActivityViewSharedLink,
  useActivityCreate,
  useActivityUpdate,
  NoteType,
  useNoteGetByType,
  useNoteUpdateByType,
  useFileUpload,
  useFileListPendingByType,
  useListEmailSubmit,
  QueryKeys,
};
