import { useState, useRef, ChangeEvent } from 'react';
import {
  Flex,
  Text,
  Button,
  ButtonGroup,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ALLOWED_FILE_TYPES_STRING = '.pdf, .docx, .jpg, or .png';

interface FileUploadProps {
  onUpload: (file: File) => void;
}

const FileUpload = ({ onUpload }: FileUploadProps) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fileSelected, setFileSelected] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (!selectedFile) {
      setFile(null);
      setFileSelected(false);
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      toast({
        title: 'Invalid file type',
        description: `Please upload a valid ${ALLOWED_FILE_TYPES_STRING}`,
        status: 'error',
        variant: 'subtle',
        position: 'top',
        isClosable: true,
      });
      return;
    }

    if (selectedFile.size > MAX_FILE_SIZE) {
      toast({
        title: 'File too large',
        description: 'Please upload a file that is 5 Mb or smaller',
        status: 'error',
        variant: 'subtle',
        position: 'top',
        isClosable: true,
      });
      return;
    }

    setFile(selectedFile);
    setFileSelected(true);
  };

  const handleFileUpload = () => {
    if (file) {
      onUpload(file);
      setFileSelected(false);
      setFile(null);
    }
    onClose();
  };

  const handleFileClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.files = null;
    }
    setFileSelected(false);
    setFile(null);
  };

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        Upload a file
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import a worksheet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!fileSelected ? (
              <Flex direction="column">
                <Text>Select a {ALLOWED_FILE_TYPES_STRING} file.</Text>
                <Input
                  ref={fileInputRef}
                  name="file-upload"
                  type="file"
                  accept={ALLOWED_FILE_TYPES.join(',')}
                  display="none"
                  value={file?.name}
                  onChange={handleFileChange}
                />
              </Flex>
            ) : (
              <Flex
                direction="column"
                align="flex-start"
                justify="center"
                w="100%"
              >
                <Text fontWeight="bold">Selected file:</Text>
                <Text noOfLines={2} mb={4}>
                  {file!.name}
                </Text>
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              {!fileSelected ? (
                <Button
                  colorScheme="blue"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Select a file
                </Button>
              ) : (
                <>
                  <Button
                    colorScheme="gray"
                    variant="outline"
                    onClick={handleFileClear}
                  >
                    Discard
                  </Button>
                  <Button colorScheme="blue" onClick={handleFileUpload}>
                    Upload
                  </Button>
                </>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileUpload;
