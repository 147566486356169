/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * homework
 * OpenAPI spec version: 0.1.0
 */
export type FileUploadBody = {
  file: Blob;
};

export type ActivityListParams = {
  search?: string;
};

export type TemplateListParams = {
  search?: string;
  bookmarked?: boolean | null;
  authored?: boolean | null;
};

export type LegalDocumentType =
  (typeof LegalDocumentType)[keyof typeof LegalDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalDocumentType = {
  terms_of_service: 'terms_of_service',
  privacy_policy: 'privacy_policy',
} as const;

export interface LegalDocument {
  content: string;
  created_at: string;
  id: string;
  type: LegalDocumentType;
  updated_at: string;
}

export interface ListSubmit {
  email: string;
}

export type FileUploadResponseMimeType = string | null;

export interface FileUploadResponse {
  mime_type: FileUploadResponseMimeType;
  name: string;
  uploaded_at: string;
}

export type FileType = string;

export interface NoteUpdate {
  content: string;
}

export interface Note {
  content: string;
  created_at: string;
  type: string;
  updated_at: string;
}

export type NoteType = (typeof NoteType)[keyof typeof NoteType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoteType = {
  goals: 'goals',
  takeaways: 'takeaways',
} as const;

export interface ActivityShare {
  expires_at: string;
  link: string;
}

export interface ActivityUpdate {
  content: ActivityContent;
}

export interface ActivityId {
  id: string;
}

export type ActivityContentCreateValues = {
  [key: string]: string | number | null;
};

export interface ActivityContentCreate {
  values: ActivityContentCreateValues;
}

export interface ActivityCreate {
  content: ActivityContentCreate;
  template_id: string;
}

export type SnapshottedTemplateTechnicalDescription = string | null;

export interface SnapshottedTemplate {
  blocks: Blocks;
  description: string;
  id: string;
  slug: string;
  tags: Tags;
  technical_description: SnapshottedTemplateTechnicalDescription;
  title: string;
  type: TemplateType;
}

export type ActivityContentValues = { [key: string]: string | number | null };

export interface ActivityContent {
  template: SnapshottedTemplate;
  values: ActivityContentValues;
}

export interface Activity {
  content: ActivityContent;
  created_at: string;
  id: string;
  updated_at: string;
}

export interface BookmarkTemplate {
  is_bookmarked: boolean;
}

export interface TemplateId {
  id: string;
}

export interface TemplateCategories {
  tags: Tags;
  types: TemplateType[];
}

export type TemplateCreateTechnicalDescription = string | null;

export type TemplateCreateTags = Tags | null;

export interface TemplateCreate {
  blocks: Blocks;
  description: string;
  tags?: TemplateCreateTags;
  technical_description?: TemplateCreateTechnicalDescription;
  title: string;
  type: TemplateType;
}

export type VideoBlockType =
  (typeof VideoBlockType)[keyof typeof VideoBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoBlockType = {
  video: 'video',
} as const;

export interface VideoBlock {
  heading: string;
  id: string;
  type: VideoBlockType;
  url: string;
}

export type TimeBlockType = (typeof TimeBlockType)[keyof typeof TimeBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeBlockType = {
  time: 'time',
} as const;

export interface TimeBlock {
  heading: string;
  id: string;
  type: TimeBlockType;
}

export type TextInputBlockType =
  (typeof TextInputBlockType)[keyof typeof TextInputBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextInputBlockType = {
  text_input: 'text_input',
} as const;

export interface TextInputBlock {
  heading: string;
  id: string;
  subheading: string;
  type: TextInputBlockType;
}

export type TemplateType = (typeof TemplateType)[keyof typeof TemplateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TemplateType = {
  exercise: 'exercise',
  tracker: 'tracker',
  education: 'education',
} as const;

export type TemplateTechnicalDescription = string | null;

export type TemplateIsPublic = boolean | null;

export type TemplateIsBookmarked = boolean | null;

export type TemplateAuthorId = string | null;

export type Tags = string[];

export interface Template {
  author_id: TemplateAuthorId;
  blocks: Blocks;
  created_at: string;
  description: string;
  id: string;
  is_bookmarked: TemplateIsBookmarked;
  is_public: TemplateIsPublic;
  slug: string;
  tags: Tags;
  technical_description: TemplateTechnicalDescription;
  title: string;
  type: TemplateType;
  updated_at: string;
}

export type ShortTextInputBlockType =
  (typeof ShortTextInputBlockType)[keyof typeof ShortTextInputBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShortTextInputBlockType = {
  short_text_input: 'short_text_input',
} as const;

export interface ShortTextInputBlock {
  heading: string;
  id: string;
  subheading: string;
  type: ShortTextInputBlockType;
}

export type SelectBlockType =
  (typeof SelectBlockType)[keyof typeof SelectBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectBlockType = {
  select: 'select',
} as const;

export type SelectBlockOptionsAnyOf = { [key: string]: string[] };

export type SelectBlockOptions = string[] | SelectBlockOptionsAnyOf;

export interface SelectBlock {
  heading: string;
  id: string;
  options: SelectBlockOptions;
  type: SelectBlockType;
}

export type ScaleBlockType =
  (typeof ScaleBlockType)[keyof typeof ScaleBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScaleBlockType = {
  scale: 'scale',
} as const;

export type ScaleBlockMinLabel = string | null;

export type ScaleBlockMidLabel = string | null;

export type ScaleBlockMaxLabel = string | null;

export interface ScaleBlock {
  heading: string;
  id: string;
  max: number;
  max_label?: ScaleBlockMaxLabel;
  mid_label?: ScaleBlockMidLabel;
  min: number;
  min_label?: ScaleBlockMinLabel;
  type: ScaleBlockType;
}

export type ReadingBlockType =
  (typeof ReadingBlockType)[keyof typeof ReadingBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadingBlockType = {
  reading: 'reading',
} as const;

export interface ReadingBlock {
  content: string;
  heading: string;
  id: string;
  type: ReadingBlockType;
}

export type NumberBlockType =
  (typeof NumberBlockType)[keyof typeof NumberBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberBlockType = {
  number: 'number',
} as const;

export interface NumberBlock {
  heading: string;
  id: string;
  max: number;
  min: number;
  type: NumberBlockType;
}

export type MultiEntryBlockType =
  (typeof MultiEntryBlockType)[keyof typeof MultiEntryBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultiEntryBlockType = {
  multi_entry: 'multi_entry',
} as const;

export interface MultiEntryBlock {
  blocks: Block[][];
  heading: string;
  id: string;
  type: MultiEntryBlockType;
}

export type ImageBlockType =
  (typeof ImageBlockType)[keyof typeof ImageBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageBlockType = {
  image: 'image',
} as const;

export interface ImageBlock {
  heading: string;
  id: string;
  type: ImageBlockType;
  url: string;
}

export type GroupBlockType =
  (typeof GroupBlockType)[keyof typeof GroupBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupBlockType = {
  group: 'group',
} as const;

export interface GroupBlock {
  blocks: Block[];
  heading: string;
  id: string;
  type: GroupBlockType;
}

export type DateTimeBlockType =
  (typeof DateTimeBlockType)[keyof typeof DateTimeBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateTimeBlockType = {
  datetime: 'datetime',
} as const;

export interface DateTimeBlock {
  heading: string;
  id: string;
  type: DateTimeBlockType;
}

export type Block =
  | ReadingBlock
  | ImageBlock
  | VideoBlock
  | TextInputBlock
  | ShortTextInputBlock
  | ScaleBlock
  | SelectBlock
  | NumberBlock
  | TimeBlock
  | DateTimeBlock
  | GroupBlock
  | MultiEntryBlock;

export type Blocks = Block[];

export interface VerifyEmailToken {
  token: string;
}

export type UpdateAccountProfileLastName = string | null;

export type UpdateAccountProfileFirstName = string | null;

export interface UpdateAccountProfile {
  first_name?: UpdateAccountProfileFirstName;
  last_name?: UpdateAccountProfileLastName;
}

export interface AccountProfile {
  email: string;
  email_verified: boolean;
  first_name: string;
  id: string;
  is_provider: boolean;
  last_name: string;
}

export interface PasswordReset {
  confirm_password: string;
  new_password: string;
  token: string;
}

export interface PasswordEmailLink {
  email: string;
}

export interface PasswordUpdate {
  confirm_password: string;
  new_password: string;
  password: string;
}

export interface ProviderRegistrationData {
  affirm_provider: boolean;
  consent_to_terms_and_privacy_policy: boolean;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

export interface RegistrationData {
  affirm_13_or_older: boolean;
  consent_to_terms_and_privacy_policy: boolean;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

export interface LoginCredentials {
  email: string;
  is_provider: boolean;
  password: string;
}

export type ErrorMessage = string | string[];

export interface Error {
  message: ErrorMessage;
}

export interface LoginSuccess {
  expires: string;
  is_provider: boolean;
  token: string;
}

export interface Pong {
  authenticated: boolean;
  ping: unknown;
}
