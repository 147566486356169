import { Container, Flex, VStack, Spinner, Text } from '@chakra-ui/react';
import { BasePage } from 'app/pages/Page';
import { useLegalDocumentGetByType, LegalDocumentType } from 'app/data';
import MarkdownRenderer from 'app/components/MarkdownRenderer';

interface LegalDocumentProps {
  title: string;
  document_type: LegalDocumentType;
}

const LegalDocument = ({ title, document_type }: LegalDocumentProps) => {
  const doc = useLegalDocumentGetByType(document_type);

  return (
    <BasePage title={title}>
      <Flex w="100%" justify="center" align="center">
        <Container m={4} maxW="56em">
          {doc.isPending ? (
            <Flex align="center" justify="center" w="100%">
              <Spinner size="lg" />
            </Flex>
          ) : doc.error ? (
            <Flex align="center" justify="center" w="100%">
              <Text>There was an error loading the {title}.</Text>
            </Flex>
          ) : (
            <VStack align="left" w="100%" spacing={4}>
              <MarkdownRenderer>{doc.data.content}</MarkdownRenderer>
            </VStack>
          )}
        </Container>
      </Flex>
    </BasePage>
  );
};

export default LegalDocument;
