/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * homework
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AccountProfile,
  Activity,
  ActivityCreate,
  ActivityListParams,
  ActivityShare,
  ActivityUpdate,
  BookmarkTemplate,
  Error,
  FileUploadBody,
  FileUploadResponse,
  LegalDocument,
  ListSubmit,
  LoginCredentials,
  LoginSuccess,
  Note,
  NoteUpdate,
  PasswordEmailLink,
  PasswordReset,
  PasswordUpdate,
  Pong,
  ProviderRegistrationData,
  RegistrationData,
  Template,
  TemplateCategories,
  TemplateCreate,
  TemplateListParams,
  UpdateAccountProfile,
  VerifyEmailToken,
} from './api.schemas';
import { useCustomInstance } from './axios';
import type { ErrorType } from './axios';

/**
 * @summary Ping
 */
export const usePingHook = () => {
  const Ping = useCustomInstance<Pong>();

  return (signal?: AbortSignal) => {
    return Ping({ url: `/api/v1/ping`, method: 'GET', signal });
  };
};

export const getPingQueryKey = () => {
  return [`/api/v1/ping`] as const;
};

export const usePingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof usePingHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof usePingHook>>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPingQueryKey();

  const Ping = usePingHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof usePingHook>>>
  > = ({ signal }) => Ping(signal);

  return {
    queryKey,
    queryFn,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof usePingHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePingHook>>>
>;
export type PingQueryError = ErrorType<unknown>;

/**
 * @summary Ping
 */
export const usePing = <
  TData = Awaited<ReturnType<ReturnType<typeof usePingHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof usePingHook>>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = usePingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Auth Login
 */
export const useAuthLoginHook = () => {
  const AuthLogin = useCustomInstance<LoginSuccess>();

  return (loginCredentials: LoginCredentials) => {
    return AuthLogin({
      url: `/api/v1/auth/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginCredentials,
    });
  };
};

export const useAuthLoginMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthLoginHook>>>,
    TError,
    { data: LoginCredentials },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthLoginHook>>>,
  TError,
  { data: LoginCredentials },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthLogin = useAuthLoginHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthLoginHook>>>,
    { data: LoginCredentials }
  > = (props) => {
    const { data } = props ?? {};

    return AuthLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLoginMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthLoginHook>>>
>;
export type AuthLoginMutationBody = LoginCredentials;
export type AuthLoginMutationError = ErrorType<Error>;

/**
 * @summary Auth Login
 */
export const useAuthLogin = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthLoginHook>>>,
    TError,
    { data: LoginCredentials },
    TContext
  >;
}) => {
  const mutationOptions = useAuthLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Auth Logout
 */
export const useAuthLogoutHook = () => {
  const AuthLogout = useCustomInstance<void>();

  return () => {
    return AuthLogout({ url: `/api/v1/auth/logout`, method: 'POST' });
  };
};

export const useAuthLogoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthLogoutHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthLogoutHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthLogout = useAuthLogoutHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthLogoutHook>>>,
    void
  > = () => {
    return AuthLogout();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLogoutMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthLogoutHook>>>
>;

export type AuthLogoutMutationError = ErrorType<unknown>;

/**
 * @summary Auth Logout
 */
export const useAuthLogout = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthLogoutHook>>>,
    TError,
    void,
    TContext
  >;
}) => {
  const mutationOptions = useAuthLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Auth Register
 */
export const useAuthRegisterHook = () => {
  const AuthRegister = useCustomInstance<void>();

  return (registrationData: RegistrationData) => {
    return AuthRegister({
      url: `/api/v1/auth/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationData,
    });
  };
};

export const useAuthRegisterMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthRegisterHook>>>,
    TError,
    { data: RegistrationData },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthRegisterHook>>>,
  TError,
  { data: RegistrationData },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthRegister = useAuthRegisterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthRegisterHook>>>,
    { data: RegistrationData }
  > = (props) => {
    const { data } = props ?? {};

    return AuthRegister(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthRegisterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthRegisterHook>>>
>;
export type AuthRegisterMutationBody = RegistrationData;
export type AuthRegisterMutationError = ErrorType<Error>;

/**
 * @summary Auth Register
 */
export const useAuthRegister = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthRegisterHook>>>,
    TError,
    { data: RegistrationData },
    TContext
  >;
}) => {
  const mutationOptions = useAuthRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Auth Register Provider
 */
export const useAuthRegisterProviderHook = () => {
  const AuthRegisterProvider = useCustomInstance<void>();

  return (providerRegistrationData: ProviderRegistrationData) => {
    return AuthRegisterProvider({
      url: `/api/v1/auth/register/provider`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: providerRegistrationData,
    });
  };
};

export const useAuthRegisterProviderMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthRegisterProviderHook>>>,
    TError,
    { data: ProviderRegistrationData },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthRegisterProviderHook>>>,
  TError,
  { data: ProviderRegistrationData },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthRegisterProvider = useAuthRegisterProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthRegisterProviderHook>>>,
    { data: ProviderRegistrationData }
  > = (props) => {
    const { data } = props ?? {};

    return AuthRegisterProvider(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthRegisterProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthRegisterProviderHook>>>
>;
export type AuthRegisterProviderMutationBody = ProviderRegistrationData;
export type AuthRegisterProviderMutationError = ErrorType<Error>;

/**
 * @summary Auth Register Provider
 */
export const useAuthRegisterProvider = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthRegisterProviderHook>>>,
    TError,
    { data: ProviderRegistrationData },
    TContext
  >;
}) => {
  const mutationOptions = useAuthRegisterProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Auth Password Update
 */
export const useAuthPasswordUpdateHook = () => {
  const AuthPasswordUpdate = useCustomInstance<void>();

  return (passwordUpdate: PasswordUpdate) => {
    return AuthPasswordUpdate({
      url: `/api/v1/auth/password`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: passwordUpdate,
    });
  };
};

export const useAuthPasswordUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordUpdateHook>>>,
    TError,
    { data: PasswordUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordUpdateHook>>>,
  TError,
  { data: PasswordUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthPasswordUpdate = useAuthPasswordUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordUpdateHook>>>,
    { data: PasswordUpdate }
  > = (props) => {
    const { data } = props ?? {};

    return AuthPasswordUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthPasswordUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordUpdateHook>>>
>;
export type AuthPasswordUpdateMutationBody = PasswordUpdate;
export type AuthPasswordUpdateMutationError = ErrorType<Error>;

/**
 * @summary Auth Password Update
 */
export const useAuthPasswordUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordUpdateHook>>>,
    TError,
    { data: PasswordUpdate },
    TContext
  >;
}) => {
  const mutationOptions = useAuthPasswordUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Auth Password Email Link
 */
export const useAuthPasswordEmailLinkHook = () => {
  const AuthPasswordEmailLink = useCustomInstance<void>();

  return (passwordEmailLink: PasswordEmailLink) => {
    return AuthPasswordEmailLink({
      url: `/api/v1/auth/password/email_link`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: passwordEmailLink,
    });
  };
};

export const useAuthPasswordEmailLinkMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordEmailLinkHook>>>,
    TError,
    { data: PasswordEmailLink },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordEmailLinkHook>>>,
  TError,
  { data: PasswordEmailLink },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthPasswordEmailLink = useAuthPasswordEmailLinkHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordEmailLinkHook>>>,
    { data: PasswordEmailLink }
  > = (props) => {
    const { data } = props ?? {};

    return AuthPasswordEmailLink(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthPasswordEmailLinkMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordEmailLinkHook>>>
>;
export type AuthPasswordEmailLinkMutationBody = PasswordEmailLink;
export type AuthPasswordEmailLinkMutationError = ErrorType<unknown>;

/**
 * @summary Auth Password Email Link
 */
export const useAuthPasswordEmailLink = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordEmailLinkHook>>>,
    TError,
    { data: PasswordEmailLink },
    TContext
  >;
}) => {
  const mutationOptions = useAuthPasswordEmailLinkMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Auth Password Verify Token
 */
export const useAuthPasswordVerifyTokenHook = () => {
  const AuthPasswordVerifyToken = useCustomInstance<void>();

  return (token: string, signal?: AbortSignal) => {
    return AuthPasswordVerifyToken({
      url: `/api/v1/auth/password/verify_token/${token}`,
      method: 'GET',
      signal,
    });
  };
};

export const getAuthPasswordVerifyTokenQueryKey = (token: string) => {
  return [`/api/v1/auth/password/verify_token/${token}`] as const;
};

export const useAuthPasswordVerifyTokenQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>
  >,
  TError = ErrorType<Error>,
>(
  token: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthPasswordVerifyTokenQueryKey(token);

  const AuthPasswordVerifyToken = useAuthPasswordVerifyTokenHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>>
  > = ({ signal }) => AuthPasswordVerifyToken(token, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!token,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthPasswordVerifyTokenQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>>
>;
export type AuthPasswordVerifyTokenQueryError = ErrorType<Error>;

/**
 * @summary Auth Password Verify Token
 */
export const useAuthPasswordVerifyToken = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>
  >,
  TError = ErrorType<Error>,
>(
  token: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useAuthPasswordVerifyTokenHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthPasswordVerifyTokenQueryOptions(token, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Auth Password Reset
 */
export const useAuthPasswordResetHook = () => {
  const AuthPasswordReset = useCustomInstance<void>();

  return (passwordReset: PasswordReset) => {
    return AuthPasswordReset({
      url: `/api/v1/auth/password/reset`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: passwordReset,
    });
  };
};

export const useAuthPasswordResetMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordResetHook>>>,
    TError,
    { data: PasswordReset },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordResetHook>>>,
  TError,
  { data: PasswordReset },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AuthPasswordReset = useAuthPasswordResetHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordResetHook>>>,
    { data: PasswordReset }
  > = (props) => {
    const { data } = props ?? {};

    return AuthPasswordReset(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthPasswordResetMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthPasswordResetHook>>>
>;
export type AuthPasswordResetMutationBody = PasswordReset;
export type AuthPasswordResetMutationError = ErrorType<Error>;

/**
 * @summary Auth Password Reset
 */
export const useAuthPasswordReset = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthPasswordResetHook>>>,
    TError,
    { data: PasswordReset },
    TContext
  >;
}) => {
  const mutationOptions = useAuthPasswordResetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Account Profile Get
 */
export const useAccountProfileGetHook = () => {
  const AccountProfileGet = useCustomInstance<AccountProfile>();

  return (signal?: AbortSignal) => {
    return AccountProfileGet({
      url: `/api/v1/account/profile`,
      method: 'GET',
      signal,
    });
  };
};

export const getAccountProfileGetQueryKey = () => {
  return [`/api/v1/account/profile`] as const;
};

export const useAccountProfileGetQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAccountProfileGetQueryKey();

  const AccountProfileGet = useAccountProfileGetHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>
  > = ({ signal }) => AccountProfileGet(signal);

  return {
    queryKey,
    queryFn,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AccountProfileGetQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>
>;
export type AccountProfileGetQueryError = ErrorType<unknown>;

/**
 * @summary Account Profile Get
 */
export const useAccountProfileGet = <
  TData = Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAccountProfileGetHook>>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAccountProfileGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Account Profile Update
 */
export const useAccountProfileUpdateHook = () => {
  const AccountProfileUpdate = useCustomInstance<AccountProfile>();

  return (updateAccountProfile: UpdateAccountProfile) => {
    return AccountProfileUpdate({
      url: `/api/v1/account/profile`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateAccountProfile,
    });
  };
};

export const useAccountProfileUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountProfileUpdateHook>>>,
    TError,
    { data: UpdateAccountProfile },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAccountProfileUpdateHook>>>,
  TError,
  { data: UpdateAccountProfile },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AccountProfileUpdate = useAccountProfileUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAccountProfileUpdateHook>>>,
    { data: UpdateAccountProfile }
  > = (props) => {
    const { data } = props ?? {};

    return AccountProfileUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountProfileUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAccountProfileUpdateHook>>>
>;
export type AccountProfileUpdateMutationBody = UpdateAccountProfile;
export type AccountProfileUpdateMutationError = ErrorType<Error>;

/**
 * @summary Account Profile Update
 */
export const useAccountProfileUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountProfileUpdateHook>>>,
    TError,
    { data: UpdateAccountProfile },
    TContext
  >;
}) => {
  const mutationOptions = useAccountProfileUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Account Email Verify Token
 */
export const useAccountEmailVerifyTokenHook = () => {
  const AccountEmailVerifyToken = useCustomInstance<void>();

  return (verifyEmailToken: VerifyEmailToken) => {
    return AccountEmailVerifyToken({
      url: `/api/v1/account/email/verify_token`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: verifyEmailToken,
    });
  };
};

export const useAccountEmailVerifyTokenMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountEmailVerifyTokenHook>>>,
    TError,
    { data: VerifyEmailToken },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAccountEmailVerifyTokenHook>>>,
  TError,
  { data: VerifyEmailToken },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const AccountEmailVerifyToken = useAccountEmailVerifyTokenHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAccountEmailVerifyTokenHook>>>,
    { data: VerifyEmailToken }
  > = (props) => {
    const { data } = props ?? {};

    return AccountEmailVerifyToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountEmailVerifyTokenMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAccountEmailVerifyTokenHook>>>
>;
export type AccountEmailVerifyTokenMutationBody = VerifyEmailToken;
export type AccountEmailVerifyTokenMutationError = ErrorType<Error>;

/**
 * @summary Account Email Verify Token
 */
export const useAccountEmailVerifyToken = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountEmailVerifyTokenHook>>>,
    TError,
    { data: VerifyEmailToken },
    TContext
  >;
}) => {
  const mutationOptions = useAccountEmailVerifyTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Template List
 */
export const useTemplateListHook = () => {
  const TemplateList = useCustomInstance<Template[]>();

  return (params?: TemplateListParams, signal?: AbortSignal) => {
    return TemplateList({
      url: `/api/v1/templates/`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getTemplateListQueryKey = (params?: TemplateListParams) => {
  return [`/api/v1/templates/`, ...(params ? [params] : [])] as const;
};

export const useTemplateListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: TemplateListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTemplateListQueryKey(params);

  const TemplateList = useTemplateListHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>
  > = ({ signal }) => TemplateList(params, signal);

  return {
    queryKey,
    queryFn,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TemplateListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>
>;
export type TemplateListQueryError = ErrorType<unknown>;

/**
 * @summary Template List
 */
export const useTemplateList = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: TemplateListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useTemplateListHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTemplateListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Template Create
 */
export const useTemplateCreateHook = () => {
  const TemplateCreate = useCustomInstance<Template>();

  return (templateCreate: TemplateCreate) => {
    return TemplateCreate({
      url: `/api/v1/templates/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: templateCreate,
    });
  };
};

export const useTemplateCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateCreateHook>>>,
    TError,
    { data: TemplateCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTemplateCreateHook>>>,
  TError,
  { data: TemplateCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const TemplateCreate = useTemplateCreateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTemplateCreateHook>>>,
    { data: TemplateCreate }
  > = (props) => {
    const { data } = props ?? {};

    return TemplateCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TemplateCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTemplateCreateHook>>>
>;
export type TemplateCreateMutationBody = TemplateCreate;
export type TemplateCreateMutationError = ErrorType<Error>;

/**
 * @summary Template Create
 */
export const useTemplateCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateCreateHook>>>,
    TError,
    { data: TemplateCreate },
    TContext
  >;
}) => {
  const mutationOptions = useTemplateCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Template Get Categories
 */
export const useTemplateGetCategoriesHook = () => {
  const TemplateGetCategories = useCustomInstance<TemplateCategories>();

  return (signal?: AbortSignal) => {
    return TemplateGetCategories({
      url: `/api/v1/templates/categories`,
      method: 'GET',
      signal,
    });
  };
};

export const getTemplateGetCategoriesQueryKey = () => {
  return [`/api/v1/templates/categories`] as const;
};

export const useTemplateGetCategoriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTemplateGetCategoriesQueryKey();

  const TemplateGetCategories = useTemplateGetCategoriesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>
  > = ({ signal }) => TemplateGetCategories(signal);

  return {
    queryKey,
    queryFn,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TemplateGetCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>
>;
export type TemplateGetCategoriesQueryError = ErrorType<unknown>;

/**
 * @summary Template Get Categories
 */
export const useTemplateGetCategories = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTemplateGetCategoriesHook>>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTemplateGetCategoriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Template Get
 */
export const useTemplateGetHook = () => {
  const TemplateGet = useCustomInstance<Template>();

  return (id: string, signal?: AbortSignal) => {
    return TemplateGet({
      url: `/api/v1/templates/${id}`,
      method: 'GET',
      signal,
    });
  };
};

export const getTemplateGetQueryKey = (id: string) => {
  return [`/api/v1/templates/${id}`] as const;
};

export const useTemplateGetQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTemplateGetQueryKey(id);

  const TemplateGet = useTemplateGetHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>
  > = ({ signal }) => TemplateGet(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TemplateGetQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>
>;
export type TemplateGetQueryError = ErrorType<unknown>;

/**
 * @summary Template Get
 */
export const useTemplateGet = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useTemplateGetHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTemplateGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Template Get By Slug
 */
export const useTemplateGetBySlugHook = () => {
  const TemplateGetBySlug = useCustomInstance<Template>();

  return (slug: string, signal?: AbortSignal) => {
    return TemplateGetBySlug({
      url: `/api/v1/templates/by_slug/${slug}`,
      method: 'GET',
      signal,
    });
  };
};

export const getTemplateGetBySlugQueryKey = (slug: string) => {
  return [`/api/v1/templates/by_slug/${slug}`] as const;
};

export const useTemplateGetBySlugQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>,
  TError = ErrorType<unknown>,
>(
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTemplateGetBySlugQueryKey(slug);

  const TemplateGetBySlug = useTemplateGetBySlugHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>
  > = ({ signal }) => TemplateGetBySlug(slug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TemplateGetBySlugQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>
>;
export type TemplateGetBySlugQueryError = ErrorType<unknown>;

/**
 * @summary Template Get By Slug
 */
export const useTemplateGetBySlug = <
  TData = Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>,
  TError = ErrorType<unknown>,
>(
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useTemplateGetBySlugHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTemplateGetBySlugQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Template Bookmark Set
 */
export const useTemplateBookmarkSetHook = () => {
  const TemplateBookmarkSet = useCustomInstance<BookmarkTemplate>();

  return (id: string, bookmarkTemplate: BookmarkTemplate) => {
    return TemplateBookmarkSet({
      url: `/api/v1/templates/${id}/bookmark`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bookmarkTemplate,
    });
  };
};

export const useTemplateBookmarkSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateBookmarkSetHook>>>,
    TError,
    { id: string; data: BookmarkTemplate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTemplateBookmarkSetHook>>>,
  TError,
  { id: string; data: BookmarkTemplate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const TemplateBookmarkSet = useTemplateBookmarkSetHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTemplateBookmarkSetHook>>>,
    { id: string; data: BookmarkTemplate }
  > = (props) => {
    const { id, data } = props ?? {};

    return TemplateBookmarkSet(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TemplateBookmarkSetMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTemplateBookmarkSetHook>>>
>;
export type TemplateBookmarkSetMutationBody = BookmarkTemplate;
export type TemplateBookmarkSetMutationError = ErrorType<unknown>;

/**
 * @summary Template Bookmark Set
 */
export const useTemplateBookmarkSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTemplateBookmarkSetHook>>>,
    TError,
    { id: string; data: BookmarkTemplate },
    TContext
  >;
}) => {
  const mutationOptions = useTemplateBookmarkSetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Activity List
 */
export const useActivityListHook = () => {
  const ActivityList = useCustomInstance<Activity[]>();

  return (params?: ActivityListParams, signal?: AbortSignal) => {
    return ActivityList({
      url: `/api/v1/activities/`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getActivityListQueryKey = (params?: ActivityListParams) => {
  return [`/api/v1/activities/`, ...(params ? [params] : [])] as const;
};

export const useActivityListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: ActivityListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityListQueryKey(params);

  const ActivityList = useActivityListHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>
  > = ({ signal }) => ActivityList(params, signal);

  return {
    queryKey,
    queryFn,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ActivityListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>
>;
export type ActivityListQueryError = ErrorType<unknown>;

/**
 * @summary Activity List
 */
export const useActivityList = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: ActivityListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityListHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useActivityListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Activity Create
 */
export const useActivityCreateHook = () => {
  const ActivityCreate = useCustomInstance<Activity>();

  return (activityCreate: ActivityCreate) => {
    return ActivityCreate({
      url: `/api/v1/activities/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: activityCreate,
    });
  };
};

export const useActivityCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityCreateHook>>>,
    TError,
    { data: ActivityCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useActivityCreateHook>>>,
  TError,
  { data: ActivityCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const ActivityCreate = useActivityCreateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityCreateHook>>>,
    { data: ActivityCreate }
  > = (props) => {
    const { data } = props ?? {};

    return ActivityCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivityCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityCreateHook>>>
>;
export type ActivityCreateMutationBody = ActivityCreate;
export type ActivityCreateMutationError = ErrorType<Error>;

/**
 * @summary Activity Create
 */
export const useActivityCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityCreateHook>>>,
    TError,
    { data: ActivityCreate },
    TContext
  >;
}) => {
  const mutationOptions = useActivityCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Activity List By Slug
 */
export const useActivityListBySlugHook = () => {
  const ActivityListBySlug = useCustomInstance<Activity[]>();

  return (slug: string, signal?: AbortSignal) => {
    return ActivityListBySlug({
      url: `/api/v1/activities/by_slug/${slug}`,
      method: 'GET',
      signal,
    });
  };
};

export const getActivityListBySlugQueryKey = (slug: string) => {
  return [`/api/v1/activities/by_slug/${slug}`] as const;
};

export const useActivityListBySlugQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>,
  TError = ErrorType<unknown>,
>(
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getActivityListBySlugQueryKey(slug);

  const ActivityListBySlug = useActivityListBySlugHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>
  > = ({ signal }) => ActivityListBySlug(slug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!slug,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ActivityListBySlugQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>
>;
export type ActivityListBySlugQueryError = ErrorType<unknown>;

/**
 * @summary Activity List By Slug
 */
export const useActivityListBySlug = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>,
  TError = ErrorType<unknown>,
>(
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityListBySlugHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useActivityListBySlugQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Activity Get
 */
export const useActivityGetHook = () => {
  const ActivityGet = useCustomInstance<Activity>();

  return (id: string, signal?: AbortSignal) => {
    return ActivityGet({
      url: `/api/v1/activities/${id}`,
      method: 'GET',
      signal,
    });
  };
};

export const getActivityGetQueryKey = (id: string) => {
  return [`/api/v1/activities/${id}`] as const;
};

export const useActivityGetQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityGetQueryKey(id);

  const ActivityGet = useActivityGetHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>
  > = ({ signal }) => ActivityGet(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ActivityGetQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>
>;
export type ActivityGetQueryError = ErrorType<unknown>;

/**
 * @summary Activity Get
 */
export const useActivityGet = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityGetHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useActivityGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Activity Update
 */
export const useActivityUpdateHook = () => {
  const ActivityUpdate = useCustomInstance<void>();

  return (id: string, activityUpdate: ActivityUpdate) => {
    return ActivityUpdate({
      url: `/api/v1/activities/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: activityUpdate,
    });
  };
};

export const useActivityUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityUpdateHook>>>,
    TError,
    { id: string; data: ActivityUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useActivityUpdateHook>>>,
  TError,
  { id: string; data: ActivityUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const ActivityUpdate = useActivityUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityUpdateHook>>>,
    { id: string; data: ActivityUpdate }
  > = (props) => {
    const { id, data } = props ?? {};

    return ActivityUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivityUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityUpdateHook>>>
>;
export type ActivityUpdateMutationBody = ActivityUpdate;
export type ActivityUpdateMutationError = ErrorType<Error>;

/**
 * @summary Activity Update
 */
export const useActivityUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityUpdateHook>>>,
    TError,
    { id: string; data: ActivityUpdate },
    TContext
  >;
}) => {
  const mutationOptions = useActivityUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Activity Get Share Link
 */
export const useActivityGetShareLinkHook = () => {
  const ActivityGetShareLink = useCustomInstance<ActivityShare>();

  return (id: string) => {
    return ActivityGetShareLink({
      url: `/api/v1/activities/${id}/share`,
      method: 'PUT',
    });
  };
};

export const useActivityGetShareLinkMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityGetShareLinkHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useActivityGetShareLinkHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const ActivityGetShareLink = useActivityGetShareLinkHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityGetShareLinkHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return ActivityGetShareLink(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivityGetShareLinkMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityGetShareLinkHook>>>
>;

export type ActivityGetShareLinkMutationError = ErrorType<unknown>;

/**
 * @summary Activity Get Share Link
 */
export const useActivityGetShareLink = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityGetShareLinkHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useActivityGetShareLinkMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Activity View Shared Link
 */
export const useActivityViewSharedLinkHook = () => {
  const ActivityViewSharedLink = useCustomInstance<Activity>();

  return (token: string, signal?: AbortSignal) => {
    return ActivityViewSharedLink({
      url: `/api/v1/activities/shared/${token}`,
      method: 'GET',
      signal,
    });
  };
};

export const getActivityViewSharedLinkQueryKey = (token: string) => {
  return [`/api/v1/activities/shared/${token}`] as const;
};

export const useActivityViewSharedLinkQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>,
  TError = ErrorType<unknown>,
>(
  token: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getActivityViewSharedLinkQueryKey(token);

  const ActivityViewSharedLink = useActivityViewSharedLinkHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>
  > = ({ signal }) => ActivityViewSharedLink(token, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!token,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ActivityViewSharedLinkQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>
>;
export type ActivityViewSharedLinkQueryError = ErrorType<unknown>;

/**
 * @summary Activity View Shared Link
 */
export const useActivityViewSharedLink = <
  TData = Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>,
  TError = ErrorType<unknown>,
>(
  token: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useActivityViewSharedLinkHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useActivityViewSharedLinkQueryOptions(token, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Note Get By Type
 */
export const useNoteGetByTypeHook = () => {
  const NoteGetByType = useCustomInstance<Note>();

  return (type: 'goals' | 'takeaways', signal?: AbortSignal) => {
    return NoteGetByType({
      url: `/api/v1/notes/${type}`,
      method: 'GET',
      signal,
    });
  };
};

export const getNoteGetByTypeQueryKey = (type: 'goals' | 'takeaways') => {
  return [`/api/v1/notes/${type}`] as const;
};

export const useNoteGetByTypeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>,
  TError = ErrorType<unknown>,
>(
  type: 'goals' | 'takeaways',
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoteGetByTypeQueryKey(type);

  const NoteGetByType = useNoteGetByTypeHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>
  > = ({ signal }) => NoteGetByType(type, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!type,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NoteGetByTypeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>
>;
export type NoteGetByTypeQueryError = ErrorType<unknown>;

/**
 * @summary Note Get By Type
 */
export const useNoteGetByType = <
  TData = Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>,
  TError = ErrorType<unknown>,
>(
  type: 'goals' | 'takeaways',
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useNoteGetByTypeHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useNoteGetByTypeQueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Note Update By Type
 */
export const useNoteUpdateByTypeHook = () => {
  const NoteUpdateByType = useCustomInstance<Note>();

  return (type: 'goals' | 'takeaways', noteUpdate: NoteUpdate) => {
    return NoteUpdateByType({
      url: `/api/v1/notes/${type}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: noteUpdate,
    });
  };
};

export const useNoteUpdateByTypeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNoteUpdateByTypeHook>>>,
    TError,
    { type: 'goals' | 'takeaways'; data: NoteUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useNoteUpdateByTypeHook>>>,
  TError,
  { type: 'goals' | 'takeaways'; data: NoteUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const NoteUpdateByType = useNoteUpdateByTypeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useNoteUpdateByTypeHook>>>,
    { type: 'goals' | 'takeaways'; data: NoteUpdate }
  > = (props) => {
    const { type, data } = props ?? {};

    return NoteUpdateByType(type, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NoteUpdateByTypeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNoteUpdateByTypeHook>>>
>;
export type NoteUpdateByTypeMutationBody = NoteUpdate;
export type NoteUpdateByTypeMutationError = ErrorType<unknown>;

/**
 * @summary Note Update By Type
 */
export const useNoteUpdateByType = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNoteUpdateByTypeHook>>>,
    TError,
    { type: 'goals' | 'takeaways'; data: NoteUpdate },
    TContext
  >;
}) => {
  const mutationOptions = useNoteUpdateByTypeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary File Upload
 */
export const useFileUploadHook = () => {
  const FileUpload = useCustomInstance<void>();

  return (type: string, fileUploadBody: FileUploadBody) => {
    const formData = new FormData();
    formData.append('file', fileUploadBody.file);

    return FileUpload({
      url: `/api/v1/files/${type}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };
};

export const useFileUploadMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFileUploadHook>>>,
    TError,
    { type: string; data: FileUploadBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFileUploadHook>>>,
  TError,
  { type: string; data: FileUploadBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const FileUpload = useFileUploadHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFileUploadHook>>>,
    { type: string; data: FileUploadBody }
  > = (props) => {
    const { type, data } = props ?? {};

    return FileUpload(type, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type FileUploadMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFileUploadHook>>>
>;
export type FileUploadMutationBody = FileUploadBody;
export type FileUploadMutationError = ErrorType<Error>;

/**
 * @summary File Upload
 */
export const useFileUpload = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFileUploadHook>>>,
    TError,
    { type: string; data: FileUploadBody },
    TContext
  >;
}) => {
  const mutationOptions = useFileUploadMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary File List Pending By Type
 */
export const useFileListPendingByTypeHook = () => {
  const FileListPendingByType = useCustomInstance<FileUploadResponse[]>();

  return (type: string, signal?: AbortSignal) => {
    return FileListPendingByType({
      url: `/api/v1/files/${type}/pending`,
      method: 'GET',
      signal,
    });
  };
};

export const getFileListPendingByTypeQueryKey = (type: string) => {
  return [`/api/v1/files/${type}/pending`] as const;
};

export const useFileListPendingByTypeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>,
  TError = ErrorType<unknown>,
>(
  type: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFileListPendingByTypeQueryKey(type);

  const FileListPendingByType = useFileListPendingByTypeHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>
  > = ({ signal }) => FileListPendingByType(type, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!type,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FileListPendingByTypeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>
>;
export type FileListPendingByTypeQueryError = ErrorType<unknown>;

/**
 * @summary File List Pending By Type
 */
export const useFileListPendingByType = <
  TData = Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>,
  TError = ErrorType<unknown>,
>(
  type: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useFileListPendingByTypeHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFileListPendingByTypeQueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List Email Submit
 */
export const useListEmailSubmitHook = () => {
  const ListEmailSubmit = useCustomInstance<void>();

  return (slug: string, listSubmit: ListSubmit) => {
    return ListEmailSubmit({
      url: `/api/v1/list/${slug}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: listSubmit,
    });
  };
};

export const useListEmailSubmitMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useListEmailSubmitHook>>>,
    TError,
    { slug: string; data: ListSubmit },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useListEmailSubmitHook>>>,
  TError,
  { slug: string; data: ListSubmit },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const ListEmailSubmit = useListEmailSubmitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useListEmailSubmitHook>>>,
    { slug: string; data: ListSubmit }
  > = (props) => {
    const { slug, data } = props ?? {};

    return ListEmailSubmit(slug, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ListEmailSubmitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListEmailSubmitHook>>>
>;
export type ListEmailSubmitMutationBody = ListSubmit;
export type ListEmailSubmitMutationError = ErrorType<Error>;

/**
 * @summary List Email Submit
 */
export const useListEmailSubmit = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useListEmailSubmitHook>>>,
    TError,
    { slug: string; data: ListSubmit },
    TContext
  >;
}) => {
  const mutationOptions = useListEmailSubmitMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Legal Document Get By Type
 */
export const useLegalDocumentGetByTypeHook = () => {
  const LegalDocumentGetByType = useCustomInstance<LegalDocument>();

  return (
    type: 'terms_of_service' | 'privacy_policy',
    signal?: AbortSignal
  ) => {
    return LegalDocumentGetByType({
      url: `/api/v1/legal/${type}`,
      method: 'GET',
      signal,
    });
  };
};

export const getLegalDocumentGetByTypeQueryKey = (
  type: 'terms_of_service' | 'privacy_policy'
) => {
  return [`/api/v1/legal/${type}`] as const;
};

export const useLegalDocumentGetByTypeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>,
  TError = ErrorType<unknown>,
>(
  type: 'terms_of_service' | 'privacy_policy',
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLegalDocumentGetByTypeQueryKey(type);

  const LegalDocumentGetByType = useLegalDocumentGetByTypeHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>
  > = ({ signal }) => LegalDocumentGetByType(type, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!type,
    retry: false,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LegalDocumentGetByTypeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>
>;
export type LegalDocumentGetByTypeQueryError = ErrorType<unknown>;

/**
 * @summary Legal Document Get By Type
 */
export const useLegalDocumentGetByType = <
  TData = Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>,
  TError = ErrorType<unknown>,
>(
  type: 'terms_of_service' | 'privacy_policy',
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useLegalDocumentGetByTypeHook>>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useLegalDocumentGetByTypeQueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
