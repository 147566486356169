import { useState } from 'react';
import { format } from 'date-fns';
import {
  Flex,
  Box,
  Heading,
  Text,
  Card,
  CardBody,
  HStack,
  Spinner,
  InputGroup,
  Input,
  InputLeftElement,
  Icon,
} from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import Link from 'app/components/Link';
import { Activity, useActivityList, Block } from 'app/data';
import { TemplateCardTag } from 'app/components/TemplateCardTag';

interface ActivityCardProps {
  activity: Activity;
}

const ActivityCard = ({ activity }: ActivityCardProps) => {
  const { template, values } = activity.content;

  const firstTextBlock = template.blocks.find(
    (block: Block) => block.type === 'text_input'
  );
  const textPreview = firstTextBlock ? values[firstTextBlock.id] : '';

  return (
    <Box w="100%">
      <Link.Router
        to={`/workbook/activity/${activity.id}`}
        preventScrollReset={true}
      >
        <Card
          mb={2}
          mt={2}
          w="100%"
          variant="outline"
          _hover={{
            cursor: 'pointer',
            background: 'var(--chakra-colors-gray-100)',
          }}
        >
          <CardBody p="12px" w="100%">
            <Flex direction="column">
              <Flex
                justify="space-between"
                align="start"
                gap={2}
                direction={{ base: 'column', md: 'row' }}
              >
                <Flex
                  gap={2}
                  direction={{ base: 'column', md: 'row' }}
                  align={{ base: 'start', md: 'center' }}
                >
                  <Text noOfLines={1} fontSize="lg" fontWeight="semibold">
                    {template.title}
                  </Text>
                  <Text fontSize="sm">
                    {format(activity.updated_at, 'M/d/yyyy h:mm a')}
                  </Text>
                </Flex>
                <HStack spacing={2}>
                  {template.tags.map((tag: string, idx: number) => (
                    <TemplateCardTag key={idx} name={tag} type="tag" />
                  ))}
                  {<TemplateCardTag name={template.type} type="type" />}
                </HStack>
              </Flex>
              <Flex justify="start" align="center">
                <Text noOfLines={2} fontSize="sm" mb={1} mt={1}>
                  {textPreview}
                </Text>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Link.Router>
    </Box>
  );
};

interface ActivityResultListProps {
  activities: Activity[];
}

const ActivityResultList = ({ activities }: ActivityResultListProps) => {
  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      {activities.map((activity: Activity) => (
        <ActivityCard key={activity.id} activity={activity} />
      ))}
    </Flex>
  );
};

const ActivityList = () => {
  const activities = useActivityList();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredActivities = activities.data
    ? activities.data.filter(
        (activity) =>
          searchTerm === '' ||
          activity.content.template.title.toLowerCase().includes(searchTerm) ||
          activity.content.template.description
            .toLowerCase()
            .includes(searchTerm) ||
          activity.content.template.type.includes(searchTerm) ||
          Object.values(activity.content.values).some(
            (value) =>
              value &&
              typeof value === 'string' &&
              value.toLowerCase().includes(searchTerm)
          )
      )
    : [];

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      maxW="52em"
      w="100%"
      p={{ base: 4, md: 8 }}
      borderRadius="md"
      background="white"
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        verticalAlign="center"
        justify="space-between"
        gap={4}
        w="100%"
        pb={4}
      >
        <Heading fontSize="2xl" fontWeight="bold">
          Past activities
        </Heading>

        <InputGroup size="md" maxW={{ md: '20em' }}>
          <InputLeftElement pointerEvents="none">
            <Icon as={MdSearch} color="grey" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search past activities"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          />
        </InputGroup>
      </Flex>
      {activities.isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : activities.error ? (
        <Flex align="center" justify="center" w="100%">
          <Text>There was an error loading your activities.</Text>
        </Flex>
      ) : filteredActivities.length === 0 ? (
        <Flex align="center" justify="center" w="100%">
          <Text>No activities.</Text>
        </Flex>
      ) : (
        <ActivityResultList activities={filteredActivities} />
      )}
    </Flex>
  );
};

export default ActivityList;
