import { extendTheme, ChakraTheme } from '@chakra-ui/react';
import { lexicalStyles } from 'app/components/Editor/LexicalBlockEditor';

const styles: ChakraTheme['styles'] = {
  global: () => ({
    ...lexicalStyles,
    ...{
      '.print-content': {
        margin: '40px',
      },
      '@page': {
        marginY: '40px',
        marginX: '20px',
      },
      '.print-avoid-breaks': {
        breakInside: 'avoid',
      },
    },
  }),
};

export default extendTheme({ styles });
