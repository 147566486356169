import { Navigate } from 'react-router-dom';
import useAuthStore, { AuthRoles } from 'app/stores/auth';

interface AuthenticatedProps {
  onlyProvider?: boolean;
  onlyClient?: boolean;
  children: React.ReactNode;
}

const Authenticated = ({
  onlyProvider = false,
  onlyClient = false,
  children,
}: AuthenticatedProps) => {
  if (onlyProvider && onlyClient) {
    throw new Error(
      'Authenticated component cannot specify onlyProvider and onlyClient'
    );
  }

  const [authenticated, role] = useAuthStore((state) => [
    !!state.token,
    state.role,
  ]);

  if (
    !authenticated ||
    (onlyProvider && role !== AuthRoles.PROVIDER) ||
    (onlyClient && role !== AuthRoles.CLIENT)
  ) {
    const redirect = !authenticated
      ? '/login'
      : role === AuthRoles.PROVIDER
        ? '/dashboard'
        : '/workbook';
    return <Navigate to={redirect} />;
  }

  return <>{children}</>;
};

export default Authenticated;
