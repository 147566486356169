import { Tag } from '@chakra-ui/react';

interface TemplateCardTagProps {
  name: string;
  type: 'tag' | 'type';
}
export const TemplateCardTag = ({ name, type }: TemplateCardTagProps) => {
  if (type == 'tag') {
    return <Tag size="sm">{name}</Tag>;
  } else if (type == 'type') {
    const colorScheme = {
      exercise: 'yellow',
      tracker: 'purple',
      education: 'green',
    }[name];
    return (
      <Tag size="sm" colorScheme={colorScheme}>
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </Tag>
    );
  }
};
