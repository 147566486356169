import { useState, useCallback, useEffect } from 'react';
import {
  SystemStyleObject,
  HStack,
  ButtonGroup,
  IconButton,
  Icon,
  useToast,
} from '@chakra-ui/react';
import {
  MdUndo,
  MdRedo,
  MdFormatBold,
  MdFormatItalic,
  MdCopyAll,
} from 'react-icons/md';

import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { TreeView } from '@lexical/react/LexicalTreeView';
import {
  $convertFromMarkdownString,
  TRANSFORMERS,
  MarkdownShortcutPlugin,
  OnChangeMarkdown,
} from './LexicalMarkdown';

import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import { mergeRegister } from '@lexical/utils';

// TODO: should we allow links in the editor?

export const lexicalStyles: Record<string, SystemStyleObject> = {
  '.editor-inner': {
    fontSize: 'var(--chakra-fontSizes-md)',
    paddingInlineStart: 'var(--chakra-space-4)',
    paddingInlineEnd: 'var(--chakra-space-4)',
    borderRadius: 'var(--chakra-radii-md)',
    outline: '2px solid transparent',
    outlineOffset: '2px',

    transitionProperty: 'var(--chakra-transition-property-common)',
    transitionDuration: 'var(--chakra-transition-duration-normal)',

    paddingTop: 'var(--chakra-space-2)',
    paddingBottom: 'var(--chakra-space-2)',
    minHeight: 'var(--chakra-sizes-24)',
    lineHeight: 'var(--chakra-lineHeights-short)',

    border: '1px solid var(--chakra-colors-gray-400)',
    '&:focus': {
      outline: 'none',
      boxShadow: 'var(--chakra-shadows-outline)',
    },
  },
  '.editor-disabled': {
    backgroundColor: 'var(--chakra-colors-gray-100)',
  },
  '.editor-large': {
    minHeight: 'var(--chakra-sizes-44)',
  },
  '.editor-heading-h1': {
    fontSize: 'var(--chakra-fontSizes-xl)',
    fontWeight: 'var(--chakra-fontWeights-semibold)',
    marginBottom: 'var(--chakra-space-2)',
  },
  '.editor-heading-h2': {
    fontSize: 'var(--chakra-fontSizes-lg)',
    fontWeight: 'var(--chakra-fontWeights-semibold)',
    marginBottom: 'var(--chakra-space-2)',
  },
  '.editor-heading-h3': {
    fontSize: 'var(--chakra-fontSizes-md)',
    fontWeight: 'var(--chakra-fontWeights-semibold)',
    marginBottom: 'var(--chakra-space-2)',
  },
  '.editor-list-ol': {
    listStyleType: 'decimal',
    marginInlineStart: '1em',
    lineHeight: 'var(--chakra-lineHeights-base)',
  },
  '.editor-list-ul': {
    listStyleType: 'initial',
    marginInlineStart: '1em',
    lineHeight: 'var(--chakra-lineHeights-base)',
  },
  '.editor-placeholder': {
    color: 'var(--chakra-colors-chakra-placeholder-color)',
    overflow: 'hidden',
    position: 'relative',
    userSelect: 'none',
    top: '-88px',
    left: '16px',
    pointerEvents: 'none',
  },
};

function onError(error: Error | string) {
  console.error(error);
}

const LowPriority = 1;

interface ToolbarPluginProps {
  markdownEditorState: string;
}

function ToolbarPlugin({ markdownEditorState }: ToolbarPluginProps) {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const toast = useToast();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  async function copyToClipboard(textToCopy: string) {
    await navigator.clipboard.writeText(textToCopy);
    toast({
      title: 'Copied text as markdown',
      status: 'success',
      variant: 'subtle',
      isClosable: true,
      position: 'top',
    });
  }

  return (
    <HStack mb={2}>
      <ButtonGroup size="sm" isAttached>
        <IconButton
          aria-label="undo"
          icon={<Icon as={MdUndo} />}
          variant="outline"
          isDisabled={!canUndo}
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
        />
        <IconButton
          aria-label="redo"
          icon={<Icon as={MdRedo} />}
          variant="outline"
          isDisabled={!canRedo}
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
        />
      </ButtonGroup>

      <ButtonGroup size="sm" isAttached>
        <IconButton
          aria-label="bold"
          icon={<Icon as={MdFormatBold} />}
          variant={isBold ? 'solid' : 'outline'}
          colorScheme={isBold ? 'blue' : undefined}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
        />
        <IconButton
          aria-label="italic"
          icon={<Icon as={MdFormatItalic} />}
          variant={isItalic ? 'solid' : 'outline'}
          colorScheme={isItalic ? 'blue' : undefined}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
        />
      </ButtonGroup>
      <ButtonGroup size="sm">
        <IconButton
          aria-label="copy as markdown"
          icon={<Icon as={MdCopyAll} />}
          variant="outline"
          onClick={() => copyToClipboard(markdownEditorState)}
        />
      </ButtonGroup>
    </HStack>
  );
}

interface DisabledPluginProps {
  disabled: boolean;
}

const DisabledPlugin = ({ disabled }: DisabledPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!disabled);
  }, [disabled, editor]);

  return null;
};

const TreeViewPlugin = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <TreeView
      viewClassName="tree-view-output"
      treeTypeButtonClassName="debug-treetype-button"
      timeTravelPanelClassName="debug-timetravel-panel"
      timeTravelButtonClassName="debug-timetravel-button"
      timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
      timeTravelPanelButtonClassName="debug-timetravel-panel-button"
      editor={editor}
    />
  );
};

interface LexicalBlockEditorProps {
  initialValue: string;
  placeholder?: string;
  disabled?: boolean;
  largeEditor?: boolean;
  onChangeEnd?: (value: string) => void;
  debug?: boolean;
  enableToolbar?: boolean;
}

function LexicalBlockEditor({
  initialValue,
  placeholder,
  disabled,
  largeEditor,
  onChangeEnd,
  debug,
  enableToolbar,
}: LexicalBlockEditorProps) {
  const setInitialState = () =>
    $convertFromMarkdownString(initialValue, TRANSFORMERS);
  const initialConfig = {
    namespace: 'BlockEditor',
    editorState: setInitialState,
    theme: {
      heading: {
        h1: 'editor-heading-h1',
        h2: 'editor-heading-h2',
        h3: 'editor-heading-h3',
      },
      list: {
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
        listitem: 'editor-list-item',
      },
    },
    nodes: [HeadingNode, ListNode, ListItemNode],
    onError,
    editable: !disabled,
  };

  // set isEditable when disabled changes
  useEffect(() => {
    initialConfig.editable = !disabled;
  }, [disabled]);

  const [editorState, setEditorState] = useState<string>(initialValue);

  const onChange = (value: string) => {
    onChangeEnd?.(value);
    setEditorState(value);
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <>
        {enableToolbar && <ToolbarPlugin markdownEditorState={editorState} />}
      </>
      <ListPlugin />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      <OnChangeMarkdown onChange={onChange} transformers={TRANSFORMERS} />
      <HistoryPlugin />
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className={`editor-inner ${disabled ? 'editor-disabled' : ''} ${
              largeEditor ? 'editor-large' : ''
            } `}
          />
        }
        placeholder={
          placeholder ? (
            <div className="editor-placeholder">{placeholder}</div>
          ) : null
        }
        ErrorBoundary={LexicalErrorBoundary}
      />

      <DisabledPlugin disabled={!!disabled} />
      <>{debug && <TreeViewPlugin />}</>
    </LexicalComposer>
  );
}

export default LexicalBlockEditor;
