import { create } from 'zustand';
import {
  Flex,
  Heading,
  Text,
  Button,
  ButtonGroup,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { useAuthPasswordUpdate } from 'app/data';

interface PasswordFormStore {
  password: string;
  newPassword: string;
  confirmPassword: string;

  setPassword: (password: string) => void;
  setNewPassword: (newPassword: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;

  clearPasswords: () => void;
}

const usePasswordFormStore = create<PasswordFormStore>((set) => ({
  password: '',
  newPassword: '',
  confirmPassword: '',

  setPassword: (password: string) => set({ password }),
  setNewPassword: (newPassword: string) => set({ newPassword }),
  setConfirmPassword: (confirmPassword: string) => set({ confirmPassword }),

  clearPasswords: () =>
    set({ password: '', newPassword: '', confirmPassword: '' }),
}));

export const PasswordForm = () => {
  const toast = useToast();
  const passwordUpdate = useAuthPasswordUpdate({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Password updated',
          status: 'success',
          variant: 'subtle',
          isClosable: true,
          position: 'top',
        });

        handleDiscard();
      },
      onError: (error) => {
        // XXX handle errors better
        console.error(error.message, error.response?.data);
        const data = error.response?.data;
        const message = Array.isArray(data?.message)
          ? data?.message.join(' ')
          : data?.message;
        toast({
          title: message || 'Error updating password',
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      },
    },
  });

  const handleDiscard = () => clearPasswords();

  const handleUpdate = () => {
    passwordUpdate.mutate({
      data: {
        // XXX camelcase?
        password: password,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
    });
  };

  const [
    password,
    setPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    clearPasswords,
  ] = usePasswordFormStore((state) => [
    state.password,
    state.setPassword,
    state.newPassword,
    state.setNewPassword,
    state.confirmPassword,
    state.setConfirmPassword,
    state.clearPasswords,
  ]);

  // XXX add actual validation
  const touched = password && newPassword && confirmPassword;
  const validated =
    password &&
    newPassword &&
    confirmPassword &&
    newPassword === confirmPassword &&
    newPassword !== password;

  return (
    <VStack align="left" px={3} spacing={0} w="100%">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdate();
        }}
      >
        <Heading
          fontSize={{ base: 'xl', lg: '2xl' }}
          fontWeight="normal"
          fontFamily="DM Serif Text, serif"
          mb={4}
        >
          Security
        </Heading>
        <Text mb={2} fontSize="sm">
          Current Password
        </Text>
        <Input
          type="password"
          mb={4}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          tabIndex={1}
        />

        <Text mb={2} fontSize="sm">
          New Password
        </Text>
        <Input
          type="password"
          mb={4}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          tabIndex={1}
        />

        <Text mb={2} fontSize="sm">
          Confirm Password
        </Text>
        <Input
          type="password"
          mb={4}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          tabIndex={1}
        />

        <Flex w="100%" align="flex-end" justify="flex-end">
          <ButtonGroup size="md">
            <Button
              variant="outline"
              isDisabled={!touched}
              onClick={handleDiscard}
            >
              Discard
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              isDisabled={!validated || !touched}
              isLoading={passwordUpdate.isPending}
              tabIndex={1}
            >
              Update Password
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </VStack>
  );
};

export default PasswordForm;
