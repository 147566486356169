import { useSearchParams, Navigate, useNavigate } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import Page from 'app/pages/Page';
import TabbedLogin from 'app/components/auth/TabbedLogin';
import useAuthStore from 'app/stores/auth';

const Login = () => {
  const authenticated = useAuthStore((state) => !!state.token);

  const navigate = useNavigate();
  const [search] = useSearchParams();
  const provider = search.has('provider');
  const redirect =
    search.get('redirect') || provider ? '/dashboard' : '/workbook';

  return (
    <Page title="Login" panelWidth="32em">
      {authenticated && <Navigate to={redirect || '/workbook'} />}
      <Text
        fontSize="4xl"
        fontWeight="bold"
        fontFamily="DM Serif Text, serif"
        mb={4}
      >
        Login
      </Text>
      <TabbedLogin
        isProvider={provider}
        onClickTab={(isProvider: boolean) =>
          navigate({
            pathname: '/login',
            search: isProvider ? '?provider' : '',
          })
        }
        onClickSignup={(isProvider: boolean) =>
          navigate({
            pathname: '/signup',
            search: isProvider ? '?provider' : '',
          })
        }
      />
    </Page>
  );
};

export default Login;
