import { useState } from 'react';
import {
  Box,
  Text,
  Checkbox,
  Code,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import {
  BlockComposer,
  BlockList,
  useBlockContext,
  TextInputBlock,
  ReadingBlock,
} from 'app/components/Block';
import { Block } from 'app/data';
import Editor from 'app/components/Editor';

const initialBlocks: Block[] = [
  {
    id: 'text_input',
    type: 'text_input',
    heading: 'Text Input Block',
    subheading: 'This is a subheading of the text input block',
  },
  {
    id: 'short_text_input',
    type: 'short_text_input',
    heading: 'Short Text Input Block',
    subheading: 'This is a subheading of the short text input block',
  },
  {
    id: 'scale',
    type: 'scale',
    heading: 'Scale Block',
    min: 1,
    max: 10,
  },
  {
    id: 'select-flat',
    type: 'select',
    heading: 'Select Block Flat',
    options: ['first option', 'another', 'a very long third option'],
  },
  {
    id: 'select-nested',
    type: 'select',
    heading: 'Select Block Nested',
    options: {
      l1: ['first option', 'another', 'a very long third option'],
      l2: ['fourth', 'fifth'],
    },
  },
  { id: 'number', type: 'number', heading: 'Number Block', min: 0, max: 10 },
  { id: 'time', type: 'time', heading: 'Time Block' },
  { id: 'datetime', type: 'datetime', heading: 'Datetime Block' },
  {
    id: 'group',
    type: 'group',
    heading: 'Group Block - any other blocks can be grouped together',
    blocks: [
      {
        id: 'group_text_input',
        type: 'text_input',
        heading: 'Text Input Block',
        subheading: 'This is a subheading of the text input block',
      },
      {
        id: 'group_short_text_input',
        type: 'short_text_input',
        heading: 'Short Text Input Block',
        subheading: 'This is a subheading of the short text input block',
      },
      {
        id: 'group_scale',
        type: 'scale',
        heading: 'Scale Block',
        min: 1,
        max: 10,
        min_label: 'Low',
        max_label: 'High',
      },
      {
        id: 'group_select',
        type: 'select',
        heading: 'Select Block',
        options: ['first option', 'another', 'a very long third option'],
      },
      {
        id: 'group_number',
        type: 'number',
        heading: 'Number Block',
        min: 0,
        max: 10,
      },
      {
        id: 'group_time',
        type: 'time',
        heading: 'Time Block',
      },
      { id: 'group_datetime', type: 'datetime', heading: 'Datetime Block' },
      {
        id: 'group_reading',
        type: 'reading',
        heading: 'Reading Block',
        content: `Here's another reading block with **bold**, _italics_, and [links](https://getoutsidetherapy.com)`,
      },
      {
        id: 'group_image',
        type: 'image',
        heading: 'Image Block',
        url: 'https://via.placeholder.com/100?text=nested',
      },
      {
        id: 'group_video',
        type: 'video',
        heading: 'Breathing exercise',
        url: 'https://www.youtube.com/embed/inpok4MKVLM?si=jEc07woURYGgelRx',
      },
    ],
  },
  {
    id: 'multi',
    type: 'multi_entry',
    heading: 'Multi Entry Block',
    blocks: [
      [
        {
          id: 'multi_select',
          type: 'select',
          heading: 'Select Block Multi Entry',
          options: ['one', 'two', 'three'],
        },
      ],
      [
        {
          id: 'multi_select',
          type: 'select',
          heading: 'Select Block Multi Entry',
          options: ['one', 'two', 'three'],
        },
      ],
    ],
  },
  {
    id: 'reading',
    type: 'reading',
    heading: 'Reading Block',
    content: `Subheading of a reading block

# heading 1
## heading 2
### heading 3

**bold** and _italic_ text work and so do [links](https://getoutsidetherapy.com) as well as bare links: https://getoutsidetherapy.com

* bullet
* lists

1. numbered
1. lists

We can add
line breaks

and also add space between text

and even multiple lines


like this`,
  },
  {
    id: 'image',
    type: 'image',
    heading: 'Image Block',
    url: 'https://via.placeholder.com/150?text=sample',
  },
];

const initialValues = {
  text_input: 'asdf',
  short_text_input: 'qwer',
  scale: 3,
  'select-flat': 'another',
  'select-nested': 'fifth',
  number: 4,
  time: '12:34',
  'group.group_text_input': 'foo',
  'group.group_short_text_input': 'bar',
  'group.group_scale': 5,
  'group.group_select': 'a very long third option',
  'group.group_number': 6,
  'group.group_time': '23:45',
  'multi.0.multi_select': 'two',
};

const SandboxBlockPlugin = () => {
  const [values, disabled, setDisabled] = useBlockContext((state) => [
    state.values,
    state.disabled,
    state.setDisabled,
  ]);

  return (
    <>
      <Checkbox isChecked={disabled} onChange={() => setDisabled(!disabled)}>
        Disable editing
      </Checkbox>
      <Text fontWeight="bold">Block state</Text>
      <Code>
        <pre>
          {Object.entries(values)
            .map((e) => `${e[0]}: ${JSON.stringify(e[1])}`)
            .join('\n')}
        </pre>
      </Code>
    </>
  );
};

const Sandbox = () => {
  const [text, setText] = useState(
    `hello\nlinebreak\n**bold**\n*italic*\n\nspace\n\n\nnewlines
# heading 1
## heading 2
### heading 3
    `
  );

  return (
    <Page title="Sandbox">
      <Text
        fontSize="5xl"
        fontWeight="bold"
        fontFamily="DM Serif Text, serif"
        mb={4}
      >
        Sandbox
      </Text>

      <Tabs w="100%">
        <TabList>
          <Tab>Block List</Tab>
          <Tab>Readonly Block List</Tab>
          <Tab>Markdown Parity</Tab>
          <Tab>Markdown Editor</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <BlockComposer blocks={initialBlocks} values={initialValues}>
              <SandboxBlockPlugin />
              <BlockList />
            </BlockComposer>
          </TabPanel>
          <TabPanel>
            <BlockComposer
              blocks={initialBlocks}
              values={initialValues}
              disabled
            >
              <BlockList />
            </BlockComposer>
          </TabPanel>
          <TabPanel>
            <TextInputBlock
              block={{
                id: 'text_input',
                type: 'text_input',
                heading: 'Type here',
                subheading:
                  'Make sure it also renders in the block below the same',
              }}
              value={text}
              onInputChange={(value) => setText(value)}
            />

            <Text>Render the state as markdown</Text>
            <Box m={0} p={0} w="100%" border="1px solid black">
              <ReadingBlock
                block={{
                  id: 'reading',
                  type: 'reading',
                  heading: 'Rendered text',
                  content: text,
                }}
              />
            </Box>

            <Text>Use the state to initialize editor</Text>
            <Editor initialValue={text} />
          </TabPanel>
          <TabPanel>
            <Box w="100%">
              <Text mb={4}>
                Use this editor to write in markdown and copy the text to your
                clipboard with the button
              </Text>
              <Editor initialValue={''} enableToolbar={true} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

export default Sandbox;
