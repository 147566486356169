import Markdown from 'react-markdown';

import {
  gfmAutolinkLiteralFromMarkdown,
  gfmAutolinkLiteralToMarkdown,
} from 'mdast-util-gfm-autolink-literal';
import { combineExtensions } from 'micromark-util-combine-extensions';
import { gfmAutolinkLiteral } from 'micromark-extension-gfm-autolink-literal';
import remarkBreaks from 'remark-breaks';

import {
  Text,
  OrderedList,
  UnorderedList,
  ListItem,
  Link,
  Card,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
} from '@chakra-ui/react';

function linkRemarkPlugin() {
  // @ts-expect-error: TS is wrong about `this`.
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = /** @type {Processor} */ this;
  const data = self.data();

  const micromarkExtensions =
    data.micromarkExtensions || (data.micromarkExtensions = []);
  const fromMarkdownExtensions =
    data.fromMarkdownExtensions || (data.fromMarkdownExtensions = []);
  const toMarkdownExtensions =
    data.toMarkdownExtensions || (data.toMarkdownExtensions = []);

  micromarkExtensions.push(combineExtensions([gfmAutolinkLiteral()]));
  fromMarkdownExtensions.push([gfmAutolinkLiteralFromMarkdown()]);
  toMarkdownExtensions.push({
    extensions: [gfmAutolinkLiteralToMarkdown()],
  });
}

interface MarkdownRendererProps {
  children: string;
}
const MarkdownRenderer = ({ children }: MarkdownRendererProps) => {
  //TODO: better newline support than &nbsp; within tables

  // NOTE: preprocess line breaks and newlines before markdown parsing
  const processed = children
    .replace(/(?<=\n\n)(?![*-])\n/g, '&nbsp;\n ')
    .replace(/(\n\n)/gm, '\n&nbsp;\n');

  return (
    <Markdown
      allowedElements={[
        'h1',
        'h2',
        'h3',
        'p',
        'ul',
        'ol',
        'li',
        'a',
        'strong',
        'em',
        'table',
        'thead',
        'tr',
        'th',
        'tbody',
        'td',
        'hr',
        'br',
      ]}
      skipHtml
      remarkPlugins={[linkRemarkPlugin, remarkBreaks]}
      components={{
        h1: ({ node: _node, ...props }) => (
          <Text fontSize="xl" fontWeight="semibold" {...props} />
        ),
        h2: ({ node: _node, ...props }) => (
          <Text fontSize="lg" fontWeight="semibold" {...props} />
        ),
        h3: ({ node: _node, ...props }) => (
          <Text fontSize="md" fontWeight="semibold" {...props} />
        ),
        ul: ({ node: _node, ...props }) => <UnorderedList {...props} />,
        ol: ({ node: _node, ...props }) => <OrderedList {...props} />,
        li: ({ node: _node, ...props }) => <ListItem {...props} />,
        a: ({ node: _node, ...props }) => <Link color="blue.500" {...props} />,
        strong: ({ node: _node, ...props }) => <Text as="b" {...props} />,
        em: ({ node: _node, ...props }) => <Text as="em" {...props} />,
        table: ({ node: _node, children, ...props }) => (
          <Card maxW="100%" p={2}>
            <TableContainer>
              <Table size="sm" {...props}>
                {children}
              </Table>
            </TableContainer>
          </Card>
        ),
        thead: ({ node: _node, ...props }) => <Thead {...props} />,
        tr: ({ node: _node, ...props }) => <Tr {...props} />,
        th: ({ node: _node, ...props }) => <Th {...props} />,
        tbody: ({ node: _node, ...props }) => <Tbody {...props} />,
        td: ({ node: _node, ...props }) => <Td {...props} />,
        hr: () => <Divider my={2} />,
      }}
    >
      {processed}
    </Markdown>
  );
};

export default MarkdownRenderer;
