import { useFormik } from 'formik';
import { Stack, Input, Button, FormControl, useToast } from '@chakra-ui/react';
import { useListEmailSubmit } from 'app/data';
import { onWaitlistSubmit } from 'app/analytics';

interface EmailFormProps {
  listSlug: string;
}

const EmailForm = ({ listSlug }: EmailFormProps) => {
  const toast = useToast();
  const emailSubmit = useListEmailSubmit();

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      await emailSubmit.mutateAsync(
        {
          slug: listSlug,
          data: { email: values.email },
        },
        {
          onSuccess: () => {
            toast({
              title: 'Email submitted!',
              status: 'success',
              variant: 'subtle',
              isClosable: true,
              position: 'top',
            });

            onWaitlistSubmit();
            resetForm();
          },
          onError: () => {
            toast({
              title: 'Error submitting email',
              status: 'error',
              variant: 'subtle',
              isClosable: true,
            });
          },
        }
      );

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        spacing={4}
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'start', md: 'center' }}
      >
        <FormControl isRequired w="100%" maxW={80}>
          <Input
            id="email"
            bg="white"
            type="email"
            placeholder="Your email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </FormControl>

        <Button type="submit" colorScheme="blue">
          Join the waitlist
        </Button>
      </Stack>
    </form>
  );
};

export default EmailForm;
