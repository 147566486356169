import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import {
  Box,
  Flex,
  Text,
  Spinner,
  Spacer,
  VStack,
  Divider,
  ButtonGroup,
  Button,
  useToast,
} from '@chakra-ui/react';
import Page from 'app/pages/Page';
import {
  useNoteGetByType,
  NoteType,
  useNoteUpdateByType,
  QueryKeys,
} from 'app/data';
import Editor from 'app/components/Editor';

//TODO: pull this from the database
//TODO: some notes should allow multiple entries, like session notes
const NOTE_METADATA = {
  [NoteType.goals]: {
    title: 'Goals',
    description:
      "Productive therapy starts with clear goals about what we're trying to achieve",
  },
  [NoteType.takeaways]: {
    title: 'Takeaways',
    description:
      'As therapy progresses, use this as a quick reference for the most important learnings from your journey',
  },
};

const Note = () => {
  const params = useParams<{ type: NoteType }>();
  const title = NOTE_METADATA[params.type!]['title'];
  const description = NOTE_METADATA[params.type!]['description'];
  const { isPending, data: note, error } = useNoteGetByType(params.type!);
  const errorStatus = error?.response?.status;

  const toast = useToast();
  const queryClient = useQueryClient();
  const noteUpdate = useNoteUpdateByType({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Note Saved',
          status: 'success',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });

        queryClient.invalidateQueries({
          queryKey: QueryKeys.NoteGetByType(params.type!),
        });
      },
    },
  });

  const [loaded, setLoaded] = useState(false);
  const [noteContent, setNoteContent] = useState('');

  const handleDiscard = () => {
    // XXX implement a better editor reset funtion
    if (note) {
      setLoaded(false);
    }
  };

  const handleSave = () => {
    noteUpdate.mutate({ type: params.type!, data: { content: noteContent } });
  };

  useEffect(() => {
    if (note && !loaded) {
      setLoaded(true);
      setNoteContent(note.content);
    } else if (errorStatus === 404 && !loaded) {
      setLoaded(true);
    }
  }, [note, errorStatus, loaded]);

  return (
    <Page title={title}>
      {isPending ? (
        <Flex align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : errorStatus && errorStatus !== 404 ? (
        <Flex align="center" justify="center" w="100%">
          <Text>There was an error loading the note.</Text>
        </Flex>
      ) : (
        <VStack align="start" spacing={4} w="100%">
          <Flex w="100%" direction="column" gap={2} pb={2}>
            <Text
              fontSize="4xl"
              fontWeight="bold"
              fontFamily="DM Serif Text, serif"
            >
              {title}
            </Text>
            <Text fontSize="md">{description}</Text>
          </Flex>
          <Flex w="100%" gap="2" align="flex-end" direction="row">
            <VStack alignItems="left" justify="left" spacing={3}>
              {note ? (
                <>
                  <Text fontSize="sm">
                    Created: {format(note.created_at, 'M/d/yy h:mm a')}
                  </Text>
                  <Text fontSize="sm">
                    Last update: {format(note.updated_at, 'M/d/yy h:mm a')}
                  </Text>
                </>
              ) : (
                <Text fontSize="sm">
                  Start writing your {title.toLowerCase()} below
                </Text>
              )}
            </VStack>
            <Spacer />
          </Flex>
          <Divider width="100%" mt={2} mb={6} />
          <Flex direction="column" align="start" justify="center" w="100%">
            <Box w="100%">
              {loaded && (
                <Editor
                  largeEditor
                  initialValue={noteContent}
                  onChangeEnd={(content) => setNoteContent(content)}
                />
              )}
            </Box>
          </Flex>
          <Flex w="100%" align="flex-end" justify="flex-end">
            <ButtonGroup size="md">
              <Button
                variant="outline"
                isDisabled={note?.content === noteContent}
                onClick={handleDiscard}
              >
                Discard changes
              </Button>
              <Button colorScheme="blue" onClick={handleSave}>
                Save
              </Button>
            </ButtonGroup>
          </Flex>
        </VStack>
      )}
    </Page>
  );
};

export default Note;
