import { pickBy } from 'lodash';
import { createStore } from 'zustand';
import { Block } from 'app/data';

/**
 * Returns a flattened map of k:v over the provided blocks where
 *   k is the path to a block
 *   v is the value returned by fn(block)
 *
 *   path is defined as:
 *    * block.id for a non-nested block
 *    * group_id.block_id for a block inside a group block
 *    * multi_id.0.block_id for a block inside a multi_entry block
 */
function blockMap<V>(
  blocks: Block[],
  fn: (b: Block) => V
): Array<Record<string, V>> {
  return blocks.flatMap((block: Block) => {
    if (block.type === 'group') {
      const children = blockMap(block.blocks, fn);
      return children.map((child) => {
        const [key, value] = Object.entries(child)[0];
        return { [`${block.id}.${key}`]: value };
      });
    } else if (block.type === 'multi_entry') {
      return block.blocks.flatMap((groupChildSet, childSetIndex) => {
        const children = blockMap(groupChildSet, fn);
        return children.map((child) => {
          const [key, value] = Object.entries(child)[0];
          return { [`${block.id}.${childSetIndex}.${key}`]: value };
        });
      });
    }

    return { [block.id]: fn(block) };
  });
}

const blockDefaultValue = (block: Block) => {
  switch (block.type) {
    case 'text_input':
    case 'short_text_input':
      return '';
    case 'time':
    case 'datetime':
    case 'number':
    case 'scale':
    case 'select':
      return null;
    case 'reading':
    case 'image':
    case 'video':
    case 'group':
    case 'multi_entry':
      return undefined;
    default: {
      const _exhaustiveCheck: never = block;
      return _exhaustiveCheck;
    }
  }
};

type ValuesType = Record<string, string | number | null>;
export interface BlockStoreState {
  initialize: (blocks: Block[], values?: ValuesType) => void;

  blocks: Block[];
  values: ValuesType;
  updateValue: (id: string, value: string | number | null) => void;

  getMultiEntryCount: (id: string) => number;
  getMultiEntryKeys: (id: string) => Array<string>;

  addMultiEntry: (id: string) => void;
  removeMultiEntry: (id: string, index: number) => void;

  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
}

const initializeValues = (initialBlocks: Block[]) =>
  Object.fromEntries(
    blockMap(initialBlocks, blockDefaultValue)
      .map((v) => {
        const [key, value] = Object.entries(v)[0];
        return [key, value];
      })
      .filter(([_key, value]) => {
        return value !== undefined;
      })
  );

export type BlockStore = ReturnType<typeof createBlockStore>;

export const createBlockStore = (
  blocks?: Block[],

  values?: ValuesType,
  disabled?: boolean
) => {
  return createStore<BlockStoreState>()((set, get) => ({
    initialize: (blocks, values) => {
      set({ blocks, values: { ...initializeValues(blocks), ...values } });
    },

    blocks: blocks || [],
    values: { ...initializeValues(blocks || []), ...values },

    updateValue: (id, value) =>
      set((state) => ({ values: { ...state.values, [id]: value } })),

    getMultiEntryCount: (id) => {
      const state = get();
      const multiBlock = state.blocks.find((block) => block.id === id);
      if (!multiBlock || multiBlock.type !== 'multi_entry') {
        throw Error(`No multi block with id: ${id}`);
      }

      const entries = new Set(
        Object.keys(state.values)
          .filter((key) => key.startsWith(id))
          .map((key) => key.split('.')[1])
      );
      return entries.size;
    },

    getMultiEntryKeys: (id) => {
      const state = get();
      const multiBlock = state.blocks.find((block) => block.id === id);
      if (!multiBlock || multiBlock.type !== 'multi_entry') {
        throw Error(`No multi block with id: ${id}`);
      }

      return Object.keys(state.values).filter((key) => key.startsWith(id));
    },
    addMultiEntry: (id) => {
      set((state) => {
        const newBlocks = state.blocks.map((block: Block) => {
          if (block.id != id) {
            return block;
          } else if (block.type === 'multi_entry') {
            const newEntry = block.blocks[0].map((entryBlock) => ({
              ...entryBlock,
            }));

            return { ...block, blocks: [...block.blocks, newEntry] };
          } else {
            throw Error(
              'Attempting to add an entry to a non-multi_entry block'
            );
          }
        });

        const newValues = {
          ...initializeValues(newBlocks),
          ...state.values,
        };

        return { blocks: newBlocks, values: newValues };
      });
    },

    removeMultiEntry: (id, index) => {
      set((state) => {
        let removedEntry = false;

        const newBlocks = state.blocks.map((block: Block) => {
          if (block.id != id) {
            return block;
          } else if (block.type === 'multi_entry') {
            const entries = new Set(
              Object.keys(state.values)
                .filter((key) => key.startsWith(id))
                .map((key) => key.split('.')[1])
            );

            if (index > entries.size || index < 0) {
              throw Error(
                `Attempting to remove an entry that does not exist: ${id}.${index}`
              );
            }

            removedEntry = true;
            return {
              ...block,
              blocks: block.blocks.filter((_v, i) => i !== index),
            };
          } else {
            throw Error(
              'Attempting to remove an entry from a non-multi_entry block'
            );
          }
        });

        const newValues = pickBy(state.values, (_value, key) => {
          return !removedEntry || !key.startsWith(`${id}.${index}.`);
        });

        return { blocks: newBlocks, values: newValues };
      });
    },

    disabled: !!disabled,
    setDisabled: (disabled) => set({ disabled }),
  }));
};
