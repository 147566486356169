import { useState } from 'react';
import {
  Text,
  Button,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ButtonGroup,
  Flex,
  Spacer,
  useToast,
} from '@chakra-ui/react';

import Link from 'app/components/Link';
import { useAuthLogin } from 'app/data';
import useAuthStore, { AuthRoles } from 'app/stores/auth';

interface LoginFormProps {
  isProvider: boolean;
  onClickSignup: (isProvider: boolean) => void;
}

const LoginForm = ({ isProvider, onClickSignup }: LoginFormProps) => {
  const setAuthState = useAuthStore((state) => state.setAuthState);

  const toast = useToast();
  const login = useAuthLogin({
    mutation: {
      onSuccess: (data) =>
        setAuthState(
          data.token,
          data.is_provider ? AuthRoles.PROVIDER : AuthRoles.CLIENT,
          data.expires
        ),
      onError: () =>
        toast({
          title: 'Error Logging In',
          description: 'Invalid credentials',
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        }),
    },
  });

  const onLoginSubmit = () => {
    login.mutate({ data: { email, password, is_provider: isProvider } });
  };

  // XXX use react-hook-form or formik or something
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const validated = email && password;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onLoginSubmit();
      }}
    >
      <Text mb={2} fontSize="sm">
        Email
      </Text>
      <Input
        mb={4}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        tabIndex={1}
      />
      <Flex align="center" w="100%" mb={2}>
        <Text fontSize="sm">Password</Text>
        <Spacer />
        <Link to="/forgot-password">
          <Button variant="link" size="sm" colorScheme="blue">
            Forgot Password?
          </Button>
        </Link>
      </Flex>
      <Input
        mb={4}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        tabIndex={1}
      />
      <ButtonGroup pt={4} w="100%">
        <Button
          variant="link"
          size="md"
          onClick={() => onClickSignup(isProvider)}
        >
          {isProvider ? 'Register as a therapist' : 'Create account'}
        </Button>
        <Spacer />
        <Button
          type="submit"
          isDisabled={!validated}
          isLoading={login.isPending}
          colorScheme="blue"
          tabIndex={1}
        >
          Login
        </Button>
      </ButtonGroup>
    </form>
  );
};

interface TabbedLoginProps {
  isProvider: boolean;
  onClickTab: (isProvider: boolean) => void;
  onClickSignup: (isProvider: boolean) => void;
}

const TabbedLogin = ({
  isProvider,
  onClickTab,
  onClickSignup,
}: TabbedLoginProps) => {
  return (
    <>
      <Tabs
        w="100%"
        defaultIndex={isProvider ? 1 : 0}
        onChange={(index) => onClickTab(index === 1)}
      >
        <TabList>
          <Tab fontSize="lg">Clients</Tab>
          <Tab fontSize="lg">Therapists</Tab>
        </TabList>

        <TabPanels>
          <TabPanel pt={6}>
            <LoginForm onClickSignup={onClickSignup} isProvider={false} />
          </TabPanel>
          <TabPanel pt={4}>
            <Text mb={4}>
              Therapists can{' '}
              <Link to="/templates" color="blue.500">
                browse therapy activities
              </Link>{' '}
              and send them directly to clients without logging in.
            </Text>

            <Text mb={6}>
              Login to create private, custom activity templates from any
              document
            </Text>

            <LoginForm onClickSignup={onClickSignup} isProvider={true} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default TabbedLogin;
