import { useMemo } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import debounce from 'lodash.debounce';

import {
  ElementNode,
  EditorState,
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isTextNode,
  LexicalNode,
  $isDecoratorNode,
  $isElementNode,
  $isLineBreakNode,
} from 'lexical';
import {
  HEADING,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  UNORDERED_LIST,
  ORDERED_LIST,
} from '@lexical/markdown';
import type {
  Transformer,
  ElementTransformer,
  TextFormatTransformer,
  TextMatchTransformer,
} from '@lexical/markdown';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

/* begin copypasta */
// XXX refactor to @lexical/markdown package structure so this is easier to maintain
import type { TextNode, TextFormatType } from 'lexical';

import { $isListItemNode, $isListNode, ListItemNode } from '@lexical/list';
import { $isQuoteNode, $isHeadingNode } from '@lexical/rich-text';
import { $findMatchingParent } from '@lexical/utils';

declare global {
  interface Document {
    documentMode?: unknown;
  }

  interface Window {
    MSStream?: unknown;
  }
}

import type { ListNode } from '@lexical/list';

import { $isCodeNode } from '@lexical/code';

type MarkdownFormatKind =
  | 'noTransformation'
  | 'paragraphH1'
  | 'paragraphH2'
  | 'paragraphH3'
  | 'paragraphH4'
  | 'paragraphH5'
  | 'paragraphH6'
  | 'paragraphBlockQuote'
  | 'paragraphUnorderedList'
  | 'paragraphOrderedList'
  | 'paragraphCodeBlock'
  | 'horizontalRule'
  | 'bold'
  | 'code'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'italic_bold'
  | 'strikethrough_italic'
  | 'strikethrough_bold'
  | 'strikethrough_italic_bold'
  | 'link';

type MarkdownCriteria = Readonly<{
  export?: (
    node: LexicalNode,
    traverseChildren: (elementNode: ElementNode) => string
  ) => string | null;
  exportFormat?: TextFormatType;
  exportTag?: string;
  exportTagClose?: string;
  markdownFormatKind: MarkdownFormatKind | null | undefined;
  regEx: RegExp;
  regExForAutoFormatting: RegExp;
  requiresParagraphStart: boolean | null | undefined;
}>;

type MarkdownCriteriaArray = Array<MarkdownCriteria>;

const autoFormatBase: MarkdownCriteria = {
  markdownFormatKind: null,
  regEx: /(?:)/,
  regExForAutoFormatting: /(?:)/,
  requiresParagraphStart: false,
};

const paragraphStartBase: MarkdownCriteria = {
  ...autoFormatBase,
  requiresParagraphStart: true,
};

const markdownHeader1: MarkdownCriteria = {
  ...paragraphStartBase,
  export: createHeadingExport(1),
  markdownFormatKind: 'paragraphH1',
  regEx: /^(?:# )/,
  regExForAutoFormatting: /^(?:# )/,
};

const markdownHeader2: MarkdownCriteria = {
  ...paragraphStartBase,
  export: createHeadingExport(2),
  markdownFormatKind: 'paragraphH2',
  regEx: /^(?:## )/,
  regExForAutoFormatting: /^(?:## )/,
};

const markdownHeader3: MarkdownCriteria = {
  ...paragraphStartBase,
  export: createHeadingExport(3),
  markdownFormatKind: 'paragraphH3',
  regEx: /^(?:### )/,
  regExForAutoFormatting: /^(?:### )/,
};

const markdownHeader4: MarkdownCriteria = {
  ...paragraphStartBase,
  export: createHeadingExport(4),
  markdownFormatKind: 'paragraphH4',
  regEx: /^(?:#### )/,
  regExForAutoFormatting: /^(?:#### )/,
};

const markdownHeader5: MarkdownCriteria = {
  ...paragraphStartBase,
  export: createHeadingExport(5),
  markdownFormatKind: 'paragraphH5',
  regEx: /^(?:##### )/,
  regExForAutoFormatting: /^(?:##### )/,
};

const markdownHeader6: MarkdownCriteria = {
  ...paragraphStartBase,
  export: createHeadingExport(6),
  markdownFormatKind: 'paragraphH6',
  regEx: /^(?:###### )/,
  regExForAutoFormatting: /^(?:###### )/,
};

const markdownBlockQuote: MarkdownCriteria = {
  ...paragraphStartBase,
  export: blockQuoteExport,
  markdownFormatKind: 'paragraphBlockQuote',
  regEx: /^(?:> )/,
  regExForAutoFormatting: /^(?:> )/,
};

const markdownUnorderedListDash: MarkdownCriteria = {
  ...paragraphStartBase,
  export: listExport,
  markdownFormatKind: 'paragraphUnorderedList',
  regEx: /^(\s{0,10})(?:- )/,
  regExForAutoFormatting: /^(\s{0,10})(?:- )/,
};

const markdownUnorderedListAsterisk: MarkdownCriteria = {
  ...paragraphStartBase,
  export: listExport,
  markdownFormatKind: 'paragraphUnorderedList',
  regEx: /^(\s{0,10})(?:\* )/,
  regExForAutoFormatting: /^(\s{0,10})(?:\* )/,
};

const markdownCodeBlock: MarkdownCriteria = {
  ...paragraphStartBase,
  export: codeBlockExport,
  markdownFormatKind: 'paragraphCodeBlock',
  regEx: /^(```)$/,
  regExForAutoFormatting: /^(```)([a-z]*)( )/,
};

const markdownOrderedList: MarkdownCriteria = {
  ...paragraphStartBase,
  export: listExport,
  markdownFormatKind: 'paragraphOrderedList',
  regEx: /^(\s{0,10})(\d+)\.\s/,
  regExForAutoFormatting: /^(\s{0,10})(\d+)\.\s/,
};

const markdownHorizontalRule: MarkdownCriteria = {
  ...paragraphStartBase,
  markdownFormatKind: 'horizontalRule',
  regEx: /^(?:\*\*\*)$/,
  regExForAutoFormatting: /^(?:\*\*\* )/,
};

const markdownHorizontalRuleUsingDashes: MarkdownCriteria = {
  ...paragraphStartBase,
  markdownFormatKind: 'horizontalRule',
  regEx: /^(?:---)$/,
  regExForAutoFormatting: /^(?:--- )/,
};

const markdownInlineCode: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'code',
  exportTag: '`',
  markdownFormatKind: 'code',
  regEx: /(`)(\s*)([^`]*)(\s*)(`)()/,
  regExForAutoFormatting: /(`)(\s*\b)([^`]*)(\b\s*)(`)(\s)$/,
};

const markdownBold: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'bold',
  exportTag: '**',
  markdownFormatKind: 'bold',
  regEx: /(\*\*)(\s*)([^**]*)(\s*)(\*\*)()/,
  regExForAutoFormatting: /(\*\*)(\s*\b)([^**]*)(\b\s*)(\*\*)(\s)$/,
};

const markdownItalic: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'italic',
  exportTag: '*',
  markdownFormatKind: 'italic',
  regEx: /(\*)(\s*)([^*]*)(\s*)(\*)()/,
  regExForAutoFormatting: /(\*)(\s*\b)([^*]*)(\b\s*)(\*)(\s)$/,
};

const markdownBold2: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'bold',
  exportTag: '_',
  markdownFormatKind: 'bold',
  regEx: /(__)(\s*)([^__]*)(\s*)(__)()/,
  regExForAutoFormatting: /(__)(\s*)([^__]*)(\s*)(__)(\s)$/,
};

const markdownItalic2: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'italic',
  exportTag: '_',
  markdownFormatKind: 'italic',
  regEx: /(_)()([^_]*)()(_)()/,
  regExForAutoFormatting: /(_)()([^_]*)()(_)(\s)$/, // Maintain 7 groups.
};

const fakeMarkdownUnderline: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'underline',
  exportTag: '<u>',
  exportTagClose: '</u>',
  markdownFormatKind: 'underline',
  regEx: /(<u>)(\s*)([^<]*)(\s*)(<\/u>)()/,
  regExForAutoFormatting: /(<u>)(\s*\b)([^<]*)(\b\s*)(<\/u>)(\s)$/,
};

const markdownStrikethrough: MarkdownCriteria = {
  ...autoFormatBase,
  exportFormat: 'strikethrough',
  exportTag: '~~',
  markdownFormatKind: 'strikethrough',
  regEx: /(~~)(\s*)([^~~]*)(\s*)(~~)()/,
  regExForAutoFormatting: /(~~)(\s*\b)([^~~]*)(\b\s*)(~~)(\s)$/,
};

const markdownStrikethroughItalicBold: MarkdownCriteria = {
  ...autoFormatBase,
  markdownFormatKind: 'strikethrough_italic_bold',
  regEx: /(~~_\*\*)(\s*\b)([^~~_**][^**_~~]*)(\b\s*)(\*\*_~~)()/,
  regExForAutoFormatting:
    /(~~_\*\*)(\s*\b)([^~~_**][^**_~~]*)(\b\s*)(\*\*_~~)(\s)$/,
};

const markdownItalicbold: MarkdownCriteria = {
  ...autoFormatBase,
  markdownFormatKind: 'italic_bold',
  regEx: /(_\*\*)(\s*\b)([^_**][^**_]*)(\b\s*)(\*\*_)/,
  regExForAutoFormatting: /(_\*\*)(\s*\b)([^_**][^**_]*)(\b\s*)(\*\*_)(\s)$/,
};

const markdownStrikethroughItalic: MarkdownCriteria = {
  ...autoFormatBase,
  markdownFormatKind: 'strikethrough_italic',
  regEx: /(~~_)(\s*)([^~~_][^_~~]*)(\s*)(_~~)/,
  regExForAutoFormatting: /(~~_)(\s*)([^~~_][^_~~]*)(\s*)(_~~)(\s)$/,
};

const markdownStrikethroughBold: MarkdownCriteria = {
  ...autoFormatBase,
  markdownFormatKind: 'strikethrough_bold',
  regEx: /(~~\*\*)(\s*\b)([^~~**][^**~~]*)(\b\s*)(\*\*~~)/,
  regExForAutoFormatting:
    /(~~\*\*)(\s*\b)([^~~**][^**~~]*)(\b\s*)(\*\*~~)(\s)$/,
};

const markdownLink: MarkdownCriteria = {
  ...autoFormatBase,
  markdownFormatKind: 'link',
  regEx: /(\[)([^\]]*)(\]\()([^)]*)(\)*)()/,
  regExForAutoFormatting: /(\[)([^\]]*)(\]\()([^)]*)(\)*)(\s)$/,
};

const allMarkdownCriteriaForTextNodes: MarkdownCriteriaArray = [
  // Place the combination formats ahead of the individual formats.
  // Combos
  markdownStrikethroughItalicBold,
  markdownItalicbold,
  markdownStrikethroughItalic,
  markdownStrikethroughBold, // Individuals
  markdownInlineCode,
  markdownBold,
  markdownItalic, // Must appear after markdownBold
  markdownBold2,
  markdownItalic2, // Must appear after markdownBold2.
  fakeMarkdownUnderline,
  markdownStrikethrough,
  markdownLink,
];

const allMarkdownCriteriaForParagraphs: MarkdownCriteriaArray = [
  markdownHeader1,
  markdownHeader2,
  markdownHeader3,
  markdownHeader4,
  markdownHeader5,
  markdownHeader6,
  markdownBlockQuote,
  markdownUnorderedListDash,
  markdownUnorderedListAsterisk,
  markdownOrderedList,
  markdownCodeBlock,
  markdownHorizontalRule,
  markdownHorizontalRuleUsingDashes,
];

export function getAllMarkdownCriteriaForParagraphs(): MarkdownCriteriaArray {
  return allMarkdownCriteriaForParagraphs;
}

export function getAllMarkdownCriteriaForTextNodes(): MarkdownCriteriaArray {
  return allMarkdownCriteriaForTextNodes;
}

type Block = (
  node: LexicalNode,
  exportChildren: (elementNode: ElementNode) => string
) => string | null;

function createHeadingExport(level: number): Block {
  return (node, exportChildren) => {
    return $isHeadingNode(node) && node.getTag() === 'h' + level
      ? '#'.repeat(level) + ' ' + exportChildren(node)
      : null;
  };
}

function listExport(
  node: LexicalNode,
  exportChildren: (_node: ElementNode) => string
) {
  return $isListNode(node) ? processNestedLists(node, exportChildren, 0) : null;
}

// TODO: should be param
const LIST_INDENT_SIZE = 4;

function processNestedLists(
  listNode: ListNode,
  exportChildren: (node: ElementNode) => string,
  depth: number
): string {
  const output = [];
  const children = listNode.getChildren();
  let index = 0;

  for (const listItemNode of children) {
    if ($isListItemNode(listItemNode)) {
      if (listItemNode.getChildrenSize() === 1) {
        const firstChild = listItemNode.getFirstChild();

        if ($isListNode(firstChild)) {
          output.push(
            processNestedLists(firstChild, exportChildren, depth + 1)
          );
          continue;
        }
      }

      const indent = ' '.repeat(depth * LIST_INDENT_SIZE);
      const prefix =
        listNode.getListType() === 'bullet'
          ? '- '
          : `${listNode.getStart() + index}. `;
      output.push(indent + prefix + exportChildren(listItemNode));
      index++;
    }
  }

  return output.join('\n');
}

function blockQuoteExport(
  node: LexicalNode,
  exportChildren: (_node: ElementNode) => string
) {
  return $isQuoteNode(node) ? '> ' + exportChildren(node) : null;
}

function codeBlockExport(node: LexicalNode) {
  if (!$isCodeNode(node)) {
    return null;
  }

  const textContent = node.getTextContent();
  return (
    '```' +
    (node.getLanguage() || '') +
    (textContent ? '\n' + textContent : '') +
    '\n' +
    '```'
  );
}

export function createMarkdownExport(
  transformers: Array<Transformer>
): (node?: ElementNode) => string {
  const byType = transformersByType(transformers);

  // Export only uses text formats that are responsible for single format
  // e.g. it will filter out *** (bold, italic) and instead use separate ** and *
  const textFormatTransformers = byType.textFormat.filter(
    (transformer) => transformer.format.length === 1
  );

  return (node) => {
    const output = [];
    const children = (node || $getRoot()).getChildren();

    for (const child of children) {
      const result = exportTopLevelElements(
        child,
        byType.element,
        textFormatTransformers,
        byType.textMatch
      );

      if (result != null) {
        output.push(result);
      }
    }

    // NOTE: join with \n instead of \n\n to roundtrip with the original text
    return output.join('\n');
  };
}

function exportTopLevelElements(
  node: LexicalNode,
  elementTransformers: Array<ElementTransformer>,
  textTransformersIndex: Array<TextFormatTransformer>,
  textMatchTransformers: Array<TextMatchTransformer>
): string | null {
  for (const transformer of elementTransformers) {
    const result = transformer.export(node, (_node) =>
      exportChildren(_node, textTransformersIndex, textMatchTransformers)
    );

    if (result != null) {
      return result;
    }
  }

  if ($isElementNode(node)) {
    return exportChildren(node, textTransformersIndex, textMatchTransformers);
  } else if ($isDecoratorNode(node)) {
    return node.getTextContent();
  } else {
    return null;
  }
}

function exportChildren(
  node: ElementNode,
  textTransformersIndex: Array<TextFormatTransformer>,
  textMatchTransformers: Array<TextMatchTransformer>
): string {
  const output = [];
  const children = node.getChildren();

  mainLoop: for (const child of children) {
    for (const transformer of textMatchTransformers) {
      const result = transformer.export(
        child,
        (parentNode) =>
          exportChildren(
            parentNode,
            textTransformersIndex,
            textMatchTransformers
          ),
        (textNode, textContent) =>
          exportTextFormat(textNode, textContent, textTransformersIndex)
      );

      if (result != null) {
        output.push(result);
        continue mainLoop;
      }
    }

    if ($isLineBreakNode(child)) {
      output.push('\n');
    } else if ($isTextNode(child)) {
      output.push(
        exportTextFormat(child, child.getTextContent(), textTransformersIndex)
      );
    } else if ($isElementNode(child)) {
      output.push(
        exportChildren(child, textTransformersIndex, textMatchTransformers)
      );
    } else if ($isDecoratorNode(child)) {
      output.push(child.getTextContent());
    }
  }

  return output.join('');
}

function exportTextFormat(
  node: TextNode,
  textContent: string,
  textTransformers: Array<TextFormatTransformer>
): string {
  // This function handles the case of a string looking like this: "   foo   "
  // Where it would be invalid markdown to generate: "**   foo   **"
  // We instead want to trim the whitespace out, apply formatting, and then
  // bring the whitespace back. So our returned string looks like this: "   **foo**   "
  const frozenString = textContent.trim();
  let output = frozenString;

  const applied = new Set();

  for (const transformer of textTransformers) {
    const format = transformer.format[0];
    const tag = transformer.tag;

    if (hasFormat(node, format) && !applied.has(format)) {
      // Multiple tags might be used for the same format (*, _)
      applied.add(format);
      // Prevent adding opening tag is already opened by the previous sibling
      const previousNode = getTextSibling(node, true);

      if (!hasFormat(previousNode, format)) {
        output = tag + output;
      }

      // Prevent adding closing tag if next sibling will do it
      const nextNode = getTextSibling(node, false);

      if (!hasFormat(nextNode, format)) {
        output += tag;
      }
    }
  }

  // Replace trimmed version of textContent ensuring surrounding whitespace is not modified
  return textContent.replace(frozenString, output);
}

// Get next or previous text sibling a text node, including cases
// when it's a child of inline element (e.g. link)
function getTextSibling(node: TextNode, backward: boolean): TextNode | null {
  let sibling = backward ? node.getPreviousSibling() : node.getNextSibling();

  if (!sibling) {
    const parent = node.getParentOrThrow();

    if (parent.isInline()) {
      sibling = backward
        ? parent.getPreviousSibling()
        : parent.getNextSibling();
    }
  }

  while (sibling) {
    if ($isElementNode(sibling)) {
      if (!sibling.isInline()) {
        break;
      }

      const descendant = backward
        ? sibling.getLastDescendant()
        : sibling.getFirstDescendant();

      if ($isTextNode(descendant)) {
        return descendant;
      } else {
        sibling = backward
          ? sibling.getPreviousSibling()
          : sibling.getNextSibling();
      }
    }

    if ($isTextNode(sibling)) {
      return sibling;
    }

    if (!$isElementNode(sibling)) {
      return null;
    }
  }

  return null;
}

function hasFormat(
  node: LexicalNode | null | undefined,
  format: TextFormatType
): boolean {
  return $isTextNode(node) && node.hasFormat(format);
}

function $convertToMarkdownString(
  transformers: Array<Transformer> = TRANSFORMERS,
  node?: ElementNode
): string {
  const exportMarkdown = createMarkdownExport(transformers);
  return exportMarkdown(node);
}

/* end copypasta */

const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';

const IS_SAFARI: boolean =
  CAN_USE_DOM && /Version\/[\d.]+.*Safari/.test(navigator.userAgent);

const IS_IOS: boolean =
  CAN_USE_DOM &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !window.MSStream;

// Keep these in case we need to use them in the future.
// export const IS_WINDOWS: boolean = CAN_USE_DOM && /Win/.test(navigator.platform);
const IS_CHROME: boolean =
  CAN_USE_DOM && /^(?=.*Chrome).*/i.test(navigator.userAgent);

const IS_APPLE_WEBKIT =
  CAN_USE_DOM && /AppleWebKit\/[\d.]+/.test(navigator.userAgent) && !IS_CHROME;

const PUNCTUATION_OR_SPACE = /[!-/:-@[-`{-~\s]/;

const MARKDOWN_EMPTY_LINE_REG_EXP = /^\s{0,3}$/;

function indexBy<T>(
  list: Array<T>,
  callback: (arg0: T) => string
): Readonly<Record<string, Array<T>>> {
  const index: Record<string, Array<T>> = {};

  for (const item of list) {
    const key = callback(item);

    if (index[key]) {
      index[key].push(item);
    } else {
      index[key] = [item];
    }
  }

  return index;
}

function transformersByType(transformers: Array<Transformer>): Readonly<{
  element: Array<ElementTransformer>;
  textFormat: Array<TextFormatTransformer>;
  textMatch: Array<TextMatchTransformer>;
}> {
  const byType = indexBy(transformers, (t) => t.type);

  return {
    element: (byType.element || []) as Array<ElementTransformer>,
    textFormat: (byType['text-format'] || []) as Array<TextFormatTransformer>,
    textMatch: (byType['text-match'] || []) as Array<TextMatchTransformer>,
  };
}

type TextFormatTransformersIndex = Readonly<{
  fullMatchRegExpByTag: Readonly<Record<string, RegExp>>;
  openTagsRegExp: RegExp;
  transformersByTag: Readonly<Record<string, TextFormatTransformer>>;
}>;

function createMarkdownImportKeepingNewlines(
  transformers: Array<Transformer>
): (markdownString: string, node?: ElementNode) => void {
  const byType = transformersByType(transformers);
  const textFormatTransformersIndex = createTextFormatTransformersIndex(
    byType.textFormat
  );

  return (markdownString, node) => {
    const lines = markdownString.split('\n');
    const linesLength = lines.length;
    const root = node || $getRoot();
    root.clear();

    for (let i = 0; i < linesLength; i++) {
      const lineText = lines[i];
      importBlocks(
        lineText,
        root,
        byType.element,
        textFormatTransformersIndex,
        byType.textMatch
      );
    }

    // NOTE: disable empty paragraph removal
    // this is the only change from the original createImportMarkdown

    /*
    // Removing empty paragraphs as md does not really
    // allow empty lines and uses them as dilimiter
    const children = root.getChildren();
    for (const child of children) {
      if (isEmptyParagraph(child)) {
        child.remove();
      }
    }
     */

    if ($getSelection() !== null) {
      root.selectEnd();
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isEmptyParagraph(node: LexicalNode): boolean {
  if (!$isParagraphNode(node)) {
    return false;
  }

  const firstChild = node.getFirstChild();
  return (
    firstChild == null ||
    (node.getChildrenSize() === 1 &&
      $isTextNode(firstChild) &&
      MARKDOWN_EMPTY_LINE_REG_EXP.test(firstChild.getTextContent()))
  );
}

function importBlocks(
  lineText: string,
  rootNode: ElementNode,
  elementTransformers: Array<ElementTransformer>,
  textFormatTransformersIndex: TextFormatTransformersIndex,
  textMatchTransformers: Array<TextMatchTransformer>
) {
  const lineTextTrimmed = lineText.trim();
  const textNode = $createTextNode(lineTextTrimmed);
  const elementNode = $createParagraphNode();
  elementNode.append(textNode);
  rootNode.append(elementNode);

  for (const { regExp, replace } of elementTransformers) {
    const match = lineText.match(regExp);

    if (match) {
      textNode.setTextContent(lineText.slice(match[0].length));
      replace(elementNode, [textNode], match, true);
      break;
    }
  }

  importTextFormatTransformers(
    textNode,
    textFormatTransformersIndex,
    textMatchTransformers
  );

  // If no transformer found and we left with original paragraph node
  // can check if its content can be appended to the previous node
  // if it's a paragraph, quote or list
  if (elementNode.isAttached() && lineTextTrimmed.length > 0) {
    const previousNode = elementNode.getPreviousSibling();
    if (
      $isParagraphNode(previousNode) ||
      $isQuoteNode(previousNode) ||
      $isListNode(previousNode)
    ) {
      let targetNode: typeof previousNode | ListItemNode | null = previousNode;

      if ($isListNode(previousNode)) {
        const lastDescendant = previousNode.getLastDescendant();
        if (lastDescendant == null) {
          targetNode = null;
        } else {
          targetNode = $findMatchingParent(lastDescendant, $isListItemNode);
        }
      }

      if (targetNode != null && targetNode.getTextContentSize() > 0) {
        targetNode.splice(targetNode.getChildrenSize(), 0, [
          $createLineBreakNode(),
          ...elementNode.getChildren(),
        ]);
        elementNode.remove();
      }
    }
  }
}

// Processing text content and replaces text format tags.
// It takes outermost tag match and its content, creates text node with
// format based on tag and then recursively executed over node's content
//
// E.g. for "*Hello **world**!*" string it will create text node with
// "Hello **world**!" content and italic format and run recursively over
// its content to transform "**world**" part
function importTextFormatTransformers(
  textNode: TextNode,
  textFormatTransformersIndex: TextFormatTransformersIndex,
  textMatchTransformers: Array<TextMatchTransformer>
) {
  const textContent = textNode.getTextContent();
  const match = findOutermostMatch(textContent, textFormatTransformersIndex);

  if (!match) {
    // Once text format processing is done run text match transformers, as it
    // only can span within single text node (unline formats that can cover multiple nodes)
    importTextMatchTransformers(textNode, textMatchTransformers);
    return;
  }

  let currentNode, remainderNode, leadingNode;

  // If matching full content there's no need to run splitText and can reuse existing textNode
  // to update its content and apply format. E.g. for **_Hello_** string after applying bold
  // format (**) it will reuse the same text node to apply italic (_)
  if (match[0] === textContent) {
    currentNode = textNode;
  } else {
    const startIndex = match.index || 0;
    const endIndex = startIndex + match[0].length;

    if (startIndex === 0) {
      [currentNode, remainderNode] = textNode.splitText(endIndex);
    } else {
      [leadingNode, currentNode, remainderNode] = textNode.splitText(
        startIndex,
        endIndex
      );
    }
  }

  currentNode.setTextContent(match[2]);
  const transformer = textFormatTransformersIndex.transformersByTag[match[1]];

  if (transformer) {
    for (const format of transformer.format) {
      if (!currentNode.hasFormat(format)) {
        currentNode.toggleFormat(format);
      }
    }
  }

  // Recursively run over inner text if it's not inline code
  if (!currentNode.hasFormat('code')) {
    importTextFormatTransformers(
      currentNode,
      textFormatTransformersIndex,
      textMatchTransformers
    );
  }

  // Run over leading/remaining text if any
  if (leadingNode) {
    importTextFormatTransformers(
      leadingNode,
      textFormatTransformersIndex,
      textMatchTransformers
    );
  }

  if (remainderNode) {
    importTextFormatTransformers(
      remainderNode,
      textFormatTransformersIndex,
      textMatchTransformers
    );
  }
}

function importTextMatchTransformers(
  textNode_: TextNode,
  textMatchTransformers: Array<TextMatchTransformer>
) {
  let textNode = textNode_;

  mainLoop: while (textNode) {
    for (const transformer of textMatchTransformers) {
      const match = textNode.getTextContent().match(transformer.importRegExp);

      if (!match) {
        continue;
      }

      const startIndex = match.index || 0;
      const endIndex = startIndex + match[0].length;
      let replaceNode, leftTextNode, rightTextNode;

      if (startIndex === 0) {
        [replaceNode, textNode] = textNode.splitText(endIndex);
      } else {
        [leftTextNode, replaceNode, rightTextNode] = textNode.splitText(
          startIndex,
          endIndex
        );
      }
      if (leftTextNode) {
        importTextMatchTransformers(leftTextNode, textMatchTransformers);
      }
      if (rightTextNode) {
        textNode = rightTextNode;
      }
      transformer.replace(replaceNode, match);
      continue mainLoop;
    }

    break;
  }
}

// Finds first "<tag>content<tag>" match that is not nested into another tag
function findOutermostMatch(
  textContent: string,
  textTransformersIndex: TextFormatTransformersIndex
): RegExpMatchArray | null {
  const openTagsMatch = textContent.match(textTransformersIndex.openTagsRegExp);

  if (openTagsMatch == null) {
    return null;
  }

  for (const match of openTagsMatch) {
    // Open tags reg exp might capture leading space so removing it
    // before using match to find transformer
    const tag = match.replace(/^\s/, '');
    const fullMatchRegExp = textTransformersIndex.fullMatchRegExpByTag[tag];
    if (fullMatchRegExp == null) {
      continue;
    }

    const fullMatch = textContent.match(fullMatchRegExp);
    const transformer = textTransformersIndex.transformersByTag[tag];
    if (fullMatch != null && transformer != null) {
      if (transformer.intraword !== false) {
        return fullMatch;
      }

      // For non-intraword transformers checking if it's within a word
      // or surrounded with space/punctuation/newline
      const { index = 0 } = fullMatch;
      const beforeChar = textContent[index - 1];
      const afterChar = textContent[index + fullMatch[0].length];

      if (
        (!beforeChar || PUNCTUATION_OR_SPACE.test(beforeChar)) &&
        (!afterChar || PUNCTUATION_OR_SPACE.test(afterChar))
      ) {
        return fullMatch;
      }
    }
  }

  return null;
}

function createTextFormatTransformersIndex(
  textTransformers: Array<TextFormatTransformer>
): TextFormatTransformersIndex {
  const transformersByTag: Record<string, TextFormatTransformer> = {};
  const fullMatchRegExpByTag: Record<string, RegExp> = {};
  const openTagsRegExp = [];
  const escapeRegExp = `(?<![\\\\])`;

  for (const transformer of textTransformers) {
    const { tag } = transformer;
    transformersByTag[tag] = transformer;
    const tagRegExp = tag.replace(/(\*|\^|\+)/g, '\\$1');
    openTagsRegExp.push(tagRegExp);

    if (IS_SAFARI || IS_IOS || IS_APPLE_WEBKIT) {
      fullMatchRegExpByTag[tag] = new RegExp(
        `(${tagRegExp})(?![${tagRegExp}\\s])(.*?[^${tagRegExp}\\s])${tagRegExp}(?!${tagRegExp})`
      );
    } else {
      fullMatchRegExpByTag[tag] = new RegExp(
        `(?<![\\\\${tagRegExp}])(${tagRegExp})((\\\\${tagRegExp})?.*?[^${tagRegExp}\\s](\\\\${tagRegExp})?)((?<!\\\\)|(?<=\\\\\\\\))(${tagRegExp})(?![\\\\${tagRegExp}])`
      );
    }
  }

  return {
    // Reg exp to find open tag + content + close tag
    fullMatchRegExpByTag,
    // Reg exp to find opening tags
    openTagsRegExp: new RegExp(
      (IS_SAFARI || IS_IOS || IS_APPLE_WEBKIT ? '' : `${escapeRegExp}`) +
        '(' +
        openTagsRegExp.join('|') +
        ')',
      'g'
    ),
    transformersByTag,
  };
}

const TRANSFORMERS = [
  HEADING,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  UNORDERED_LIST,
  ORDERED_LIST,
];

// NOTE: reimplement markdown import to respect newlines. this is non-conformant with some markdown spec
// but it's what we want
const $convertFromMarkdownString = (
  markdown: string,
  transformers: Array<Transformer>,
  node?: ElementNode
): void => {
  const importMarkdown = createMarkdownImportKeepingNewlines(transformers);
  return importMarkdown(markdown, node);
};

function transformState(
  editorState: EditorState,
  onChange: OnChangeMarkdownType,
  transformers: Array<Transformer>
) {
  editorState.read(() => {
    const markdown = $convertToMarkdownString(transformers);
    onChange(markdown);
  });
}

type OnChangeMarkdownType =
  | Dispatch<SetStateAction<string>>
  | ((value: string) => void);

function OnChangeMarkdown({
  onChange,
  transformers,
  __UNSAFE_debounceTime,
}: {
  transformers: Array<Transformer>;
  onChange: OnChangeMarkdownType;
  __UNSAFE_debounceTime?: number;
}) {
  const OnChangeMarkdown = useMemo(() => {
    return debounce(
      (state: EditorState) => transformState(state, onChange, transformers),
      __UNSAFE_debounceTime ?? 200
    );
  }, [onChange, __UNSAFE_debounceTime]);

  return <OnChangePlugin onChange={OnChangeMarkdown} ignoreSelectionChange />;
}

export {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  Transformer,
  TRANSFORMERS,
  MarkdownShortcutPlugin,
  OnChangeMarkdown,
};
