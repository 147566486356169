import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export type LinkProps = ChakraLinkProps & RouterLinkProps;

const Link = (props: LinkProps) => {
  return <ChakraLink as={RouterLink} {...props} />;
};

Link.WithoutRouter = ChakraLink;
Link.Router = RouterLink;

export default Link;
